import CaretRightFilled from "@ant-design/icons/lib/icons/CaretRightFilled";
import { Button, Spin } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import { useCallback, useContext, useMemo, useState } from "react";

import { useGuardedEffect } from "../../../hooks/useGuardedEffect";
import { useFirestoreDoc } from "../../../providers/FirestoreProvider";
import { ErrorDisplay } from "../../Error";
import { useAuthFetch, useHasRole } from "../../Login/hook";
import { Tenant } from "../../Login/util";
import { LimitedUse } from "../Google/LimitedUse";
import { OAuth2IntegrationCard } from "../IntegrationCard";

type GooogleWorkspaceIntegration = {
  state: "installed";
};

const AUTH_EXTRA_QUERY = (
  googleWorkspaceResourceAccess: boolean,
  googleWorkspaceUserAdmin: boolean
) => {
  const BASE_AUTH_SCOPES = [
    "https://www.googleapis.com/auth/admin.directory.group.readonly",
    "https://www.googleapis.com/auth/admin.directory.group.member.readonly",
    "https://www.googleapis.com/auth/admin.directory.domain.readonly",
    "https://www.googleapis.com/auth/admin.directory.user.readonly",
    "https://www.googleapis.com/auth/admin.directory.user.alias.readonly",
  ];
  const AUTH_SCOPES = [
    ...BASE_AUTH_SCOPES,
    !!googleWorkspaceResourceAccess
      ? [
          "https://apps-apis.google.com/a/feeds/groups/",
          "https://www.googleapis.com/auth/cloud-identity.groups.readonly", // Required to list transtitive groups using the Cloud Identity API
        ]
      : [],
    // TODO ENG-2283 installation of user admin scope - for now it's a feature flag
    !!googleWorkspaceUserAdmin
      ? ["https://www.googleapis.com/auth/admin.directory.user"] // Required by SSH integration to GCP instances
      : [],
  ];

  const AUTH_EXTRA = {
    access_type: "offline", // Gets a refresh token
    include_granted_scopets: true, // Just informational, we'll store the scopes that were granted
    prompt: "consent", // Needed so that reinstalling generates a refresh token
    response_type: "code",
    scope: AUTH_SCOPES.join(" "), // These will get URI encoded below
  };
  return Object.entries(AUTH_EXTRA)
    .map(([k, v]) => `&${k}=${encodeURIComponent(v)}`)
    .join("");
};

const ZeroState: React.FC<{ authFetch: ReturnType<typeof useAuthFetch> }> = ({
  authFetch,
}) => {
  const onInstall = useCallback(() => {
    authFetch("integrations/google-workspace/install", { method: "POST" });
  }, [authFetch]);
  return (
    <Button icon={<CaretRightFilled />} onClick={onInstall} type="primary">
      Install
    </Button>
  );
};

export const GoogleWorkspaceIcon =
  "https://fonts.gstatic.com/s/i/productlogos/admin_2020q4/v6/192px.svg";

export const GoogleWorkspace: React.FC<object> = () => {
  const tenantId = useContext(Tenant);
  const flags = useFlags();
  const installation = useFirestoreDoc<GooogleWorkspaceIntegration>(
    `/o/${tenantId}/integrations/workspace`
  );
  const [data, setData] = useState<{ domains: any[]; groups: object[] }>();
  const [error, setError] = useState<any>();
  const authFetch = useAuthFetch(setError);
  const canRefresh = useHasRole("owner");

  useGuardedEffect(
    async (cancellation) => {
      if (!canRefresh) return;
      if (installation.doc === undefined) return;

      const response = await authFetch(
        `integrations/google-workspace/refresh`,
        {
          method: "POST",
        }
      );
      if (!response) return;
      const data = await response.json();
      cancellation.guard(setData)(data);
    },
    setError,
    [installation.doc]
  );

  const primaryDomain = useMemo(
    () => data?.domains?.find((d) => d.isPrimary),
    [data]
  );

  return (
    <>
      <OAuth2IntegrationCard
        logo={
          <img src={GoogleWorkspaceIcon} width={20} alt="Google Workspace" />
        }
        mode="secret"
        integration="workspace"
        integrationDoc={installation.doc}
        authExtra={AUTH_EXTRA_QUERY(
          flags.googleWorkspaceResourceAccess,
          flags.googleWorkspaceUserAdmin
        )}
        title="Google Workspace"
      >
        {data !== undefined ? (
          <div data-testid="google-workspace-groups-loaded">
            Detected {data.groups?.length} user{" "}
            {pluralize("group", data.groups?.length)} in{" "}
            {primaryDomain?.domainName}
          </div>
        ) : error !== undefined ? (
          <ErrorDisplay
            title="Could not detect Workspace groups"
            error={error}
          />
        ) : installation.doc?.data.state === "installed" ? (
          canRefresh ? (
            <Spin />
          ) : (
            <div>Installed</div>
          )
        ) : (
          <ZeroState authFetch={authFetch} />
        )}
      </OAuth2IntegrationCard>
      <LimitedUse />
    </>
  );
};
