import { Typography } from "antd";
import { AppRoutes } from "components/App/routeConstants";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { join } from "lodash";
import { useContext, useMemo } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  ALL_SCOPE_SENTINEL,
  memberTypeToLabel,
} from "shared/assessment/constants";
import { DiscoverMatch } from "shared/graph/discover";
import { DirectedGraph } from "shared/graph/types";
import { awsUrlFor } from "shared/integrations/resources/aws/constants";
import { ItemAssessmentScope, toScope } from "shared/types/assessment";
import {
  AssessmentNodes,
  Principal,
  PrincipalNode,
  PrincipalType,
  TargetNodeType,
} from "shared/types/assessment/data";
import { assertNever } from "utils/assert";

import { GraphTooltip } from "../../../GraphTable/GraphTooltip";
import { ConditionalAnchor } from "../ConditionalAnchor";
import { ReasonsDisplay } from "../ReasonsDisplay";
import { HasAddTerm, ShowHideTerm } from "./ShowHide";

const { Paragraph, Title } = Typography;

const memberTypeToEmoji: Record<PrincipalType, string> = {
  domain: "🌐",
  "logged-in": "🌎",
  user: "👤",
  "iam-group": "👥",
  "iam-user": "👤",
  "federated-identity": "👤",
  group: "👥",
  "service-account": "⚙️",
  public: "🌎",
  unknown: "",
};

export const PrincipalExternalLink: React.FC<{
  data: Pick<Principal, "isProviderManaged" | "principalType">;
  id: string;
}> = ({ data, id }) => {
  const { integration, scopeKey, integrationMeta } = useContext(ScopeContext);
  const idcId = integrationMeta?.idc?.id;
  const principalUrl = useMemo(
    () =>
      integration === "aws" && scopeKey !== ALL_SCOPE_SENTINEL && idcId
        ? awsUrlFor(data.principalType)?.({
            idcId: idcId,
            accountId: toScope(scopeKey).id,
            roleName: id,
          })
        : undefined,
    [data.principalType, id, idcId, integration, scopeKey]
  );
  return (
    <ConditionalAnchor
      wrappedComponent={
        <PrincipalCell principalData={{ ...data, label: id }} />
      }
      url={principalUrl}
    />
  );
};

export const PrincipalLink: React.FC<{
  data: Principal;
  type?: "long" | "short";
  show?: TargetNodeType;
}> = ({ data, show, type }) => {
  const { orgSlug, assessmentId } = useParams();
  const { scopeKey } = useContext(ScopeContext);
  return show === "binding" ? (
    <Link
      to={`/o/${orgSlug}/${
        AppRoutes.IamAssessment
      }/${assessmentId}/explore/principal/${encodeURIComponent(
        data.label
      )}?show=principal&where=${encodeURIComponent(
        `principal="${data.label}"`
      )}&scope=${encodeURIComponent(scopeKey)}`}
    >
      {type === "short" ? "view" : "View this principal in detail"}
    </Link>
  ) : show === "principal" ? (
    <Link
      to={`/o/${orgSlug}/${
        AppRoutes.IamAssessment
      }/${assessmentId}/explore?show=binding&where=${encodeURIComponent(
        `principal:"${data.label}"`
      )}&scope=${encodeURIComponent(scopeKey)}`}
    >
      {type === "short" ? "view" : "Explore all grants for this principal"}
    </Link>
  ) : null;
};

const PrincipalInnerDisplay: React.FC<
  Partial<HasAddTerm> & {
    data: Principal;
    termPrefix?: string;
  }
> = ({ data, onAddTerm, show, terms }) => {
  const { integration } = useContext(ScopeContext);
  const [userName, domain] = data.label.split("@");
  return integration ? (
    <div>
      {data.principalType === "service-account" ? (
        <>
          <Title level={4}>{userName}</Title>
          {domain && (
            <Paragraph style={{ fontSize: "small" }}>@{domain}</Paragraph>
          )}
        </>
      ) : data.principalType === "public" ? (
        <Title level={4}>Public access</Title>
      ) : (
        <Title level={4}>{data.label}</Title>
      )}
      <Paragraph>
        {memberTypeToEmoji[data.principalType]}&nbsp;
        {memberTypeToLabel(integration)[data.principalType]}
      </Paragraph>
      {data.trustedPrincipals?.isConditional && (
        <Paragraph>
          ⚠️ This principal has a conditional trust policy. There may be
          additional constraints on who can access it.
        </Paragraph>
      )}
      {/* This is currently broken when principals come from multiple directories (only a random directory is shown)
       * {data.directory && <Paragraph>{directoryToLabel[data.directory]}</Paragraph>} */}
      {data.isProviderManaged ? (
        integration === "gcloud" ? (
          <Paragraph>
            ⚠️ This is a Google-managed service account. Select &ldquo;Include
            Google-provided role grants&rdquo; in your IAM console to view the
            grant. It is not contained in your project, so access on the project
            does not provide access to this service account.
          </Paragraph>
        ) : integration === "aws" ? (
          <Paragraph>⚠️ This is a service role.</Paragraph>
        ) : (
          assertNever(integration)
        )
      ) : null}
      {data.disabled ? (
        integration === "gcloud" ? (
          <Paragraph>
            ⚠️ This is a disabled service account. It cannot be used unless it
            is first enabled.
          </Paragraph>
        ) : integration === "aws" ? (
          <Paragraph>
            ⚠️ This role is disabled via the AWSDenyAll policy. It cannot be
            used unless this policy is removed.
          </Paragraph>
        ) : (
          assertNever(integration)
        )
      ) : null}
      <Paragraph>
        <PrincipalLink data={data} show={show} />
      </Paragraph>
      {terms !== undefined && onAddTerm && (
        <>
          <ShowHideTerm
            term={`principal="${data.label}"`}
            name="principals"
            terms={terms}
            onAddTerm={onAddTerm}
          />
          <ShowHideTerm
            term={`principal=type:"${
              data.isProviderManaged
                ? "service-agent"
                : integration === "aws" &&
                  data.principalType === "service-account"
                ? "role"
                : data.principalType
            }"`}
            name="principal types"
            terms={terms}
            onAddTerm={onAddTerm}
          />
        </>
      )}
    </div>
  ) : null;
};

export const principalCellValue = (
  principal: Principal,
  integration: ItemAssessmentScope["integration"]
) =>
  principal.principalType === "public"
    ? memberTypeToLabel(integration).public
    : principal.label;

export const PrincipalCell = ({
  principalData,
  onAddTerm,
  show,
  terms,
  termPrefix,
}: Partial<HasAddTerm> & {
  principalData: Principal;
  termPrefix?: string;
}) => {
  const { integration } = useContext(ScopeContext);

  return integration ? (
    <GraphTooltip
      title={
        <PrincipalInnerDisplay
          data={principalData}
          onAddTerm={onAddTerm}
          show={show}
          terms={terms}
          termPrefix={termPrefix}
        />
      }
      width="400px"
    >
      {/* TypeScript can't figure out correct typing :( */}
      {memberTypeToEmoji[principalData.principalType]}
      &nbsp;
      {principalCellValue(principalData, integration)}
    </GraphTooltip>
  ) : null;
};

export const PrincipalMemberDisplay = ({
  item,
  membershipType,
}: {
  item: DiscoverMatch<DirectedGraph<AssessmentNodes>>;
  membershipType: number | string;
}) => {
  const { integration } = useContext(ScopeContext);

  const reasons = useMemo(() => {
    const { paths } = item.matches[0];
    const textPaths = paths
      .filter((p) =>
        membershipType !== "all"
          ? membershipType === "direct"
            ? p.length === 2
            : p.length > 2
          : true
      )
      .map((p) =>
        join(
          p.map((n) => n.key),
          " → "
        )
      )
      .sort();

    return textPaths;
  }, [membershipType, item]);
  const node = item.node as PrincipalNode;
  return integration ? (
    <GraphTooltip title={<ReasonsDisplay reasons={reasons} />} width="400px">
      {/* TypeScript can't figure out correct typing :( */}
      {memberTypeToEmoji[node.data.principalType]}
      &nbsp;
      {node.data.principalType === "public"
        ? memberTypeToLabel(integration).public
        : node.data.label}
    </GraphTooltip>
  ) : null;
};
