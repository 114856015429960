import Link from "antd/lib/typography/Link";
import { privilegeLink } from "shared/assessment/render";
import { ItemAssessmentScope } from "shared/types/assessment";

/** Renders a link to the IAM Risk Catalog for a permission
 *
 * If the permission is remediated by currently selected recommendations,
 * will render struck-through.
 */
export const PermissionLink = ({
  permission,
  integration,
}: {
  permission: string;
  integration: ItemAssessmentScope["integration"];
}) => {
  return (
    <Link href={privilegeLink(integration, permission)} target="catalog">
      {permission}
    </Link>
  );
};
