import { Button, Select, Typography } from "antd";
import { FormInput } from "components/common/forms/FormInput";
import {
  StyledFormItem,
  standardFormItemCols,
} from "components/common/forms/styles";
import { DEFAULT_MANAGER_EMAIL_FIELD } from "shared/types/directory";
import { directoryLabels } from "shared/types/workflow/constants";
import { RequestorProfile } from "shared/types/workflow/types";
import { useInstalledDirectories } from "store/resources/DirectoriesProvider";

type Props = {
  value: RequestorProfile;
  onChange: (value: RequestorProfile) => void;
};

const PROFILE_ATTRIBUTE_LABEL = "Profile attribute that contains manager email";

export const RequestorProfileInput: React.FC<Props> = ({ value, onChange }) => {
  const { availableDirectories } = useInstalledDirectories();

  return availableDirectories.state === "loading" ? (
    <div>Loading directories...</div>
  ) : (
    <>
      <StyledFormItem label="Directory" {...standardFormItemCols}>
        {/* @ENG-2010: Need to allow "azure-ad" when it gets implemented. */}
        <Select
          options={availableDirectories.directories
            .filter((d) => d !== "azure-ad")
            .map((d) => ({
              label: directoryLabels[d],
              value: d,
            }))}
          value={value.directory}
          onChange={(directory) => onChange({ ...value, directory })}
        />
      </StyledFormItem>
      {value.directory === "okta" ? (
        value.profileProperty !== undefined ? (
          <FormInput
            label={PROFILE_ATTRIBUTE_LABEL}
            value={value.profileProperty}
            onChange={(property) =>
              onChange({ ...value, profileProperty: property })
            }
            extra={
              <Button
                type="text"
                size="small"
                disabled={value.profileProperty === DEFAULT_MANAGER_EMAIL_FIELD}
                onClick={() =>
                  onChange({ ...value, profileProperty: undefined })
                }
              >
                Reset to default profile attribute
              </Button>
            }
          />
        ) : (
          <StyledFormItem
            label={PROFILE_ATTRIBUTE_LABEL}
            {...standardFormItemCols}
          >
            <Typography.Text code>
              {DEFAULT_MANAGER_EMAIL_FIELD}
            </Typography.Text>
            <Button
              type="link"
              size="small"
              onClick={() =>
                onChange({
                  ...value,
                  profileProperty: DEFAULT_MANAGER_EMAIL_FIELD,
                })
              }
            >
              Override profile property
            </Button>
          </StyledFormItem>
        )
      ) : null}
    </>
  );
};
