import { distinct, paint } from "../../graph/aggregate";
import { ConnectedNode, Node, isNode } from "../../graph/types";
import { AssessmentNodes } from "../../types/assessment/data";
import {
  AssessmentReducer,
  PermissionAggregate,
} from "../../types/assessment/data/aggregates";

export const bindingAggregate: AssessmentReducer<
  Node<AssessmentNodes, "binding">[]
> = distinct(
  "binding",
  (node) => node,
  (node) => node.key
);

export const permissionAggregate: AssessmentReducer<PermissionAggregate> =
  paint(
    // permissionType key is `{bindingKey}:{permissionType}`; we want `{permissionType}`
    (n) => (isNode("permissionType")(n) ? n.key.split(":").at(-1) : undefined),
    distinct(
      "permission",
      (node) => node as ConnectedNode<AssessmentNodes, "permission">,
      (node) => node.key
    )
  );

export const keyAggregate = <K extends keyof AssessmentNodes>(
  key: K
): AssessmentReducer<(AssessmentNodes[K] & { id: string })[]> =>
  distinct(
    key,
    (node) => ({ id: node.key, ...node.data }),
    (node) => node.id
  );
