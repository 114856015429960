import { BackwardFilled } from "@ant-design/icons";
import { Modal, Typography } from "antd";
import pluralize from "pluralize";

const { confirm } = Modal;

type Props = {
  onBack: () => void;
  isDirty: boolean;
  isAdding: boolean;
  name: string;
};

export const EditorBackButton: React.FC<Props> = ({
  onBack,
  isDirty,
  isAdding,
  name,
}) => (
  <Typography.Paragraph>
    <Typography.Link
      type="secondary"
      onClick={() => {
        if (isDirty) {
          confirm({
            title: isAdding ? `Discard this ${name}?` : "Discard changes?",
            content:
              "You have unsaved changes that will be lost if you go back.",
            onOk() {
              onBack();
            },
            maskClosable: true,
          });
        } else {
          onBack();
        }
      }}
    >
      <BackwardFilled /> Back to all {pluralize(name)}
      {isDirty && " (unsaved changes)"}
    </Typography.Link>
  </Typography.Paragraph>
);
