import {
  DeleteOutlined,
  EditTwoTone,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, List } from "antd";

type Props<T> = {
  items: T[];
  onEditItem: (index: number) => void;
  onDeleteItem: (index: number) => void;
  onAddItem: () => void;
  renderItem: (item: T, index: number) => React.ReactNode;
  addButtonText: string;
  addButtonDisabled?: boolean;
};

export const EditableList = <T,>({
  items,
  onEditItem,
  onDeleteItem,
  onAddItem,
  renderItem,
  addButtonText,
  addButtonDisabled = false,
}: Props<T>) => {
  return (
    <List
      dataSource={items}
      renderItem={(item, index) => (
        <List.Item
          actions={[
            <Button
              key={`${index}-0`}
              type="default"
              onClick={() => onEditItem(index)}
              icon={<EditTwoTone />}
            />,
            <Button
              key={`${index}-1`}
              type="default"
              onClick={() => onDeleteItem(index)}
              icon={<DeleteOutlined />}
            />,
          ]}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flex: "1",
            }}
          >
            {renderItem(item, index)}
          </div>
        </List.Item>
      )}
      header={
        <Button
          type="default"
          onClick={onAddItem}
          icon={<PlusCircleOutlined />}
          disabled={addButtonDisabled}
        >
          {addButtonText}
        </Button>
      }
    />
  );
};
