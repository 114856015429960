import styled from "styled-components";

export const StyledPrefixWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
`;

export const StyledPivotPrefix = styled.span`
  font-weight: 600;
`;
