import {
  ApprovalRule,
  Directory,
  IntegrationResourceRule,
  RequestorRule,
  ResourceRule,
} from "./types";

export const ruleComponents = ["resource", "requestor", "approval"] as const;

export const headingsFromComponentType: Record<
  (typeof ruleComponents)[number],
  string
> = {
  resource: "Resource",
  requestor: "Requestor",
  approval: "Access",
};

export const directoryLabels: Record<Directory, string> = {
  "azure-ad": "Entra ID",
  okta: "Okta",
  workspace: "Google Workspace",
};

export const integrationLabels: Record<
  IntegrationResourceRule["service"],
  string
> = {
  gcloud: "Google Cloud",
  aws: "AWS",
  k8s: "Kubernetes",
  pg: "PostgreSQL",
  snowflake: "Snowflake",
  ssh: "SSH",
  "azure-ad": "Entra ID",
  okta: "Okta",
  workspace: "Google Workspace",
};

// We're having to do this backwards.
// Because ts-schema-generator doesn't allow for generics or derived types,
// we have to create constant mappings from the types we want to use.
// These constants are used to require type exhaustiveness if we add a new
// resource type, requestor type, or access type.
export const resourceTypes: Readonly<Record<ResourceRule["type"], boolean>> = {
  any: true,
  integration: true,
  some: true,
};

export const requestorTypes: Readonly<Record<RequestorRule["type"], boolean>> =
  {
    any: true,
    group: true,
    user: true,
    some: true,
  };

export const accessTypes: Readonly<Record<ApprovalRule["type"], boolean>> = {
  p0: true,
  group: true,
  "requestor-profile": true,
  auto: true,
  escalation: true,
  deny: true,
  persistent: true,
};
