import { Alert, Button, Checkbox, Input, Select, Typography } from "antd";
import { useCallback, useMemo, useState } from "react";
import { Control, Controller, SubmitHandler, useForm } from "react-hook-form";
import { ASSESSMENT_TARGET_REGEX } from "shared/assessment/constants";
import {
  AssessmentScheduleUnits,
  AssessmentScope,
  IamAssessment,
} from "shared/types/assessment";
import styled from "styled-components";

import { useAuthFetch } from "../../Login/hook";
import { TargetInput, TargetSelect } from "./TargetSelect";

export type FrequencyInputs = {
  frequencyUnit: AssessmentScheduleUnits;
  frequencyAmount: number;
};

type CreateFormInputs = FrequencyInputs &
  TargetInput & {
    name: string;
    runImmediately: boolean;
  };

const frequencyUnitOptions: Record<AssessmentScheduleUnits, string> = {
  days: "Days",
  weeks: "Weeks",
  months: "Months",
};

export const NewAssessmentForm: React.FC<{
  setFormOpen: (open: boolean) => void;
}> = ({ setFormOpen }) => {
  const authFetch = useAuthFetch();
  const [submitting, setSubmitting] = useState(false);

  // TODO: Make work with other CSPs
  const integration = "gcloud";

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CreateFormInputs>({
    defaultValues: {
      name: "",
      frequencyUnit: "days",
      frequencyAmount: 1,
      targets: { type: "project", scopes: [] },
      runImmediately: true,
    },
  });

  const runImmediately = watch("runImmediately");

  const onSubmitCreateForm: SubmitHandler<CreateFormInputs> = useCallback(
    async (data) => {
      setSubmitting(true);
      const targets: AssessmentScope[] =
        data.targets.type === "organization"
          ? [{ integration: "gcloud", type: "organization" }]
          : data.targets.scopes;
      const assessment: IamAssessment = {
        name: data.name,
        frequency: {
          unit: data.frequencyUnit,
          amount: data.frequencyAmount,
        },
        targets,
      };
      const response = await authFetch("assessment", {
        method: "POST",
        json: {
          assessment,
          runImmediately: data.runImmediately,
        },
      });
      if (response?.ok) {
        setFormOpen(false);
      }
      setSubmitting(false);
    },
    [authFetch, setFormOpen]
  );

  const onSubmit = useMemo(
    () => handleSubmit(onSubmitCreateForm),
    [handleSubmit, onSubmitCreateForm]
  );

  return (
    <form onSubmit={onSubmit}>
      <Controller
        name="name"
        rules={{
          required: true,
          pattern: ASSESSMENT_TARGET_REGEX,
          minLength: 1,
          maxLength: 100,
        }}
        control={control}
        render={({ field }) => (
          <>
            {" "}
            <Input
              placeholder="Assessment Name"
              {...field}
              aria-invalid={!!errors.name}
            />
            {errors.name && (
              <span style={{ color: "red" }} role="alert">
                Name is required and can contain alphanumeric characters,
                whitespace, -, _, and .
              </span>
            )}{" "}
          </>
        )}
      />
      <FrequencyInputGroup>
        <Controller
          name="frequencyAmount"
          control={control}
          render={({ field: { ref: _ref, ...field } }) => (
            <FrequencyAmount {...field} />
          )}
        />
        <Controller
          name="frequencyUnit"
          control={control}
          render={({ field: { ref: _ref, ...field } }) => (
            <FrequencyUnit {...field} />
          )}
        />
      </FrequencyInputGroup>
      <TargetSelect
        control={control as any as Control<TargetInput>}
        integration={integration}
      />
      <Controller
        name="runImmediately"
        control={control}
        render={({ field: { onChange, value, ...field } }) => (
          <Checkbox
            {...field}
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            Start scheduling assessments immediately
          </Checkbox>
        )}
      />
      {!runImmediately && (
        <Alert
          type="warning"
          message="This assessment will not be automatically run. You can enable runs later or run this assessment manually."
          style={{ marginBottom: "20px" }}
        />
      )}
      <div>
        <Button type="primary" htmlType="submit" loading={submitting}>
          Create Assessment
        </Button>
      </div>
    </form>
  );
};

const StyledFrequencyInputGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 12px;
  input {
    margin-bottom: 0;
  }
`;

export const FrequencyAmount: React.FC<{
  onChange: (...event: any[]) => void;
}> = ({ onChange, ...props }) => {
  return (
    <Input
      type="number"
      placeholder="Amount"
      {...props}
      onChange={(e) => onChange(+e.target.value)}
    />
  );
};

export const FrequencyUnit: React.FC<{
  onChange: (...event: any[]) => void;
}> = ({ onChange, ...props }) => {
  return (
    <Select<AssessmentScheduleUnits> {...props} onChange={(e) => onChange(e)}>
      {Object.keys(frequencyUnitOptions).map((unit) => (
        <Select.Option value={unit} key={unit}>
          {frequencyUnitOptions[unit as AssessmentScheduleUnits]}
        </Select.Option>
      ))}
    </Select>
  );
};

export const FrequencyInputGroup: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <>
    <Typography.Paragraph style={{ marginBottom: "6px" }}>
      How often should this assessment run?
    </Typography.Paragraph>
    <StyledFrequencyInputGroup>{children}</StyledFrequencyInputGroup>
  </>
);
