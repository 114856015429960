import { Radio, RadioChangeEvent, Select, Space, Spin, Typography } from "antd";
import { ErrorDisplay } from "components/Error";
import { useAuthFetch } from "components/Login/hook";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { capitalize, compact, isArray, sortBy } from "lodash";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  UseFormStateReturn,
} from "react-hook-form";
import {
  AssessmentScope,
  AssessmentScopeIntegration,
  isGroupAssessmentScope,
  isItemAssessmentScope,
} from "shared/types/assessment";
import { assertNever } from "utils/assert";

import { scopeLabel } from "./Targets";

export type TargetInput = {
  targets: {
    type: AssessmentScope["type"];
    scopes: AssessmentScope[];
  };
};

export type TargetSelectProps = {
  control: Control<TargetInput>;
  integration: AssessmentScope["integration"];
};

type ScopeOption = { value: string; label?: string };

const toOption = (scope: AssessmentScope): ScopeOption | undefined =>
  scope.type === "organization"
    ? undefined
    : scope.type === "group"
    ? { value: scope.value, label: scope.label }
    : scope.type === "project"
    ? { value: scope.id, label: scope.label }
    : assertNever(scope);

const fromOption =
  (integration: AssessmentScopeIntegration, type: AssessmentScope["type"]) =>
  (option: ScopeOption): AssessmentScope => {
    const base = { integration, label: option.label };
    // TODO: remove GCP-specific hard-coding
    return type === "organization"
      ? { ...base, type }
      : type === "group"
      ? { ...base, type, key: "folder", value: option.value }
      : type === "project"
      ? { ...base, type, id: option.value }
      : assertNever(type);
  };

const TargetInput = ({
  integration,
  field,
  state,
}: {
  integration: AssessmentScope["integration"];
  field: ControllerRenderProps<TargetInput, "targets">;
  state: UseFormStateReturn<TargetInput>;
}) => {
  const [available, setAvailable] = useState<AssessmentScope[]>();
  const [error, setError] = useState<string>();
  const authFetch = useAuthFetch(setError);

  const value = field.value ?? { type: "item", scopes: [] };

  useEffect(() => {
    setAvailable(undefined);
  }, [integration]);

  useGuardedEffect(
    async (cancellation) => {
      const response = await authFetch(
        `assessment/_meta/integrations/${integration}/scopes`,
        { method: "GET" }
      );
      if (!response) return;
      const { scopes } = await response.json();
      if (cancellation.isCancelled) return;
      setAvailable(scopes);
    },
    setError,
    [authFetch, integration]
  );

  const radioOptions = useMemo(() => {
    const options = [{ value: "organization", label: "Organization" }];
    if (available?.find(isGroupAssessmentScope)) {
      options.push({
        value: "group",
        label: pluralize(capitalize(scopeLabel[integration].group)) ?? "Groups",
      });
    }
    if (available?.find(isItemAssessmentScope)) {
      options.push({
        value: "project",
        label:
          pluralize(capitalize(scopeLabel[integration].project)) ?? "Items",
      });
    }
    return options;
  }, [available, integration]);

  const selectOptions = useMemo(
    () =>
      sortBy(
        compact(
          (available ?? []).filter((a) => a.type === value.type).map(toOption)
        ),
        (o) => o.label ?? o.value
      ),
    [available, value.type]
  );

  const selectedValues = useMemo(
    () => compact(value.scopes.map(toOption)).map((s) => s.value),
    [value.scopes]
  );

  const onChangeRadio = useCallback(
    (event: RadioChangeEvent) =>
      field.onChange({ type: event.target.value, scopes: [] }),
    [field]
  );

  const onChangeItems = useCallback(
    (_: string[], options: ScopeOption | ScopeOption[]) => {
      const currentTarget = field.value;
      if (!isArray(options)) return;
      const scopes: AssessmentScope[] = options.map(
        fromOption(integration, value.type)
      );
      field.onChange({ ...currentTarget, scopes });
    },
    [field, integration, value.type]
  );

  return !available ? (
    <Spin />
  ) : (
    <Space
      direction="vertical"
      size="small"
      style={{ width: "100%", marginBottom: "1em" }}
    >
      {error && (
        <ErrorDisplay
          title="Could not load available assessment targets"
          error={error}
        />
      )}
      <Radio.Group
        options={radioOptions}
        optionType="button"
        buttonStyle="solid"
        value={value.type}
        onChange={onChangeRadio}
      />
      {
        // Default to project select to encourage lower-burden assessments
        value.type !== "organization" && (
          <Select<string[], ScopeOption>
            defaultValue={[]}
            mode="multiple"
            onChange={onChangeItems}
            options={selectOptions}
            placeholder={`Select ${pluralize(
              scopeLabel[integration][value.type] ?? capitalize(value.type)
            )}`}
            style={{ width: "100%" }}
            value={selectedValues}
          />
        )
      }
      {state.errors.targets && (
        <span style={{ color: "red" }} role="alert">
          A selection is required.
        </span>
      )}
    </Space>
  );
};

export const TargetSelect = ({
  control,
  integration,
}: TargetSelectProps): React.ReactElement => {
  return (
    <>
      <Typography.Paragraph style={{ marginBottom: "6px" }}>
        What should P0 assess?
      </Typography.Paragraph>
      <Controller
        name="targets"
        rules={{ required: true }}
        control={control}
        render={({ field, formState }) => (
          <TargetInput
            field={field}
            state={formState}
            integration={integration}
          />
        )}
      />
    </>
  );
};
