import { ExtendedIntegrationData } from "../types";

export const numberOfResourceInstalled = (
  extendedData: ExtendedIntegrationData[]
) =>
  extendedData.filter((d) =>
    Object.values(d).some(
      (v) => typeof v === "object" && "installed" in v && v?.installed > 0
    )
  ).length;
