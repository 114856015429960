import { Button, Form, Input, Modal } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { useForm } from "antd/lib/form/Form";
import { compact } from "lodash";
import pluralize from "pluralize";
import { useCallback, useEffect, useState } from "react";
import { AnyNode } from "shared/types/assessment/data";

import { MonitorActionProps } from "../pages/MonitorResults";
import { useFindingsStateUpdates } from "./finding/useFindingsStateUpdates";

/** Bulk ignore / un-ignore of findings */
export const FindingsIgnoreModal: React.FC<MonitorActionProps> = ({
  allNodes,
  findingFor,
  selectedNodes,
  state,
}) => {
  const [toIgnore, setToIgnore] = useState<AnyNode[]>([]);
  const [notesForm] = useForm<{ notes: string }>();

  const onIgnore = useCallback(
    () => setToIgnore(selectedNodes?.length ? selectedNodes : allNodes ?? []),
    [allNodes, selectedNodes]
  );
  const onCancelIgnore = useCallback(() => setToIgnore([]), []);
  const title = state === "open" ? "🔇\u00a0Ignore" : "🔊\u00a0Un-ignore";

  const { ignoreFindings, unignoreFindings } = useFindingsStateUpdates();

  const submitUnignore = useCallback(async () => {
    const findings = compact(toIgnore.map(findingFor));
    await unignoreFindings(findings);
    setToIgnore([]);
  }, [findingFor, unignoreFindings, toIgnore]);

  // Avoid persisting form state between modal openings
  useEffect(() => {
    notesForm.setFieldValue("notes", undefined);
  }, [notesForm, toIgnore]);

  const submitIgnore = useCallback(
    async ({ notes }: { notes: string }) => {
      const findings = compact(toIgnore.map(findingFor));
      await ignoreFindings(findings, notes);
      setToIgnore([]);
    },
    [findingFor, ignoreFindings, toIgnore]
  );

  const confirmSection = (
    <>
      <Form.Item>
        {state === "open" ? "Ignore" : "Un-ignore"} {toIgnore.length}{" "}
        {pluralize("finding", toIgnore.length)}?
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          OK
        </Button>
      </Form.Item>
    </>
  );

  return (
    <>
      <ButtonGroup>
        <Button onClick={onIgnore}>{title}</Button>
      </ButtonGroup>
      <Modal
        footer={false}
        maskClosable={false}
        open={toIgnore.length > 0}
        onCancel={onCancelIgnore}
        style={state === "open" ? { minWidth: "600px" } : {}}
      >
        {state === "open" ? (
          <Form
            form={notesForm}
            onFinish={submitIgnore}
            preserve={false}
            // Lower hard-coded width so close doesn't float over input
            // Note that antd overflow behavior means we can't just use a margin :(
            style={{ width: "530px" }}
          >
            <Form.Item label="Notes" name="notes">
              <Input.TextArea
                placeholder="Enter any notes or justification here..."
                autoSize={{ minRows: 4 }}
              />
            </Form.Item>
            {confirmSection}
          </Form>
        ) : state === "ignored" ? (
          <Form onFinish={submitUnignore}>{confirmSection}</Form>
        ) : (
          (() => {
            throw new Error("Unexpected finding state in ignore modal");
          })()
        )}
      </Modal>
    </>
  );
};
