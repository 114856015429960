import { Reducers } from "../../graph/types";
import { AssessmentNodes } from "../../types/assessment/data";
import { BindingAggregates } from "../../types/assessment/data/binding";
import { keyAggregate, permissionAggregate } from "./shared";

export const bindingReducers: Reducers<AssessmentNodes, BindingAggregates> = {
  permissions: permissionAggregate,
  principals: keyAggregate("principal"),
  risks: keyAggregate("risk"),
};
