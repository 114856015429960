export const CloudProviders = ["aws", "azure", "gcloud"] as const;
export type CloudProvider = (typeof CloudProviders)[number];

const CLOUD_TO_STORAGE_CLASS: { [index in CloudProvider]: string } = {
  aws: "gp2",
  azure: "managed-csi",
  gcloud: "standard-rwo",
};

export const P0_BOUNDARY_CONFIGURATION = (caBundle: string) => ({
  apiVersion: "admissionregistration.k8s.io/v1",
  kind: "ValidatingWebhookConfiguration",
  metadata: {
    name: "p0-admission-controller",
  },
  webhooks: [
    {
      name: "p0-admission-controller.p0-security.svc",
      rules: [
        {
          apiGroups: ["rbac.authorization.k8s.io"],
          apiVersions: ["v1"],
          operations: ["CREATE", "UPDATE"],
          resources: ["clusterroles", "clusterrolebindings", "rolebindings"],
        },
      ],
      clientConfig: {
        service: {
          namespace: "p0-security",
          name: "p0-admission-controller",
          path: "/validate",
        },
        caBundle,
      },
      admissionReviewVersions: ["v1beta1", "v1"],
      sideEffects: "None",
      failurePolicy: "Fail",
      timeoutSeconds: 5,
    },
  ],
});
export const P0_BOUNDARY_DEPLOYMENT = (
  serverKey: string,
  serverCert: string
) => ({
  apiVersion: "apps/v1",
  kind: "Deployment",
  metadata: {
    name: "p0-admission-controller",
    namespace: "p0-security",
  },
  spec: {
    selector: {
      matchLabels: {
        "app.kubernetes.io/name": "p0-admission-controller",
      },
    },
    replicas: 1,
    template: {
      metadata: {
        name: "p0-admission-controller",
        labels: {
          "app.kubernetes.io/name": "p0-admission-controller",
        },
      },
      spec: {
        containers: [
          {
            name: "webhook",
            image: "p0security/p0-k8s-admission-controller:latest",
            imagePullPolicy: "Always",
            args: [
              "/webhook",
              "--tls-cert",
              serverCert,
              "--tls-private-key",
              serverKey,
              "--port",
              "8080",
            ],
            resources: {
              limits: {
                memory: "50Mi",
                cpu: "300m",
              },
              requests: {
                memory: "00Mi",
                cpu: "300m",
              },
            },
          },
        ],
      },
    },
  },
});

export const P0_BOUNDARY_SERVICE = {
  apiVersion: "v1",
  kind: "Service",
  metadata: {
    name: "p0-admission-controller",
    namespace: "p0-security",
    labels: {
      name: "p0-admission-controller",
    },
  },
  spec: {
    ports: [
      {
        name: "webhook",
        port: 443,
        targetPort: 8080,
      },
    ],
    selector: {
      "app.kubernetes.io/name": "p0-admission-controller",
    },
  },
};

export const P0_PROXY_DEPLOYMENT = (
  clientId: string,
  clusterServer: string,
  clusterCertificate: string,
  tunnelHost: string
) => ({
  apiVersion: "apps/v1",
  kind: "Deployment",
  metadata: {
    name: "p0-braekhus-proxy",
    namespace: "p0-security",
  },
  spec: {
    selector: {
      matchLabels: {
        "app.kubernetes.io/name": "p0-braekhus-proxy",
      },
    },
    replicas: 1,
    // We use "Recreate" because the default "Rolling" strategy may schedule the successor pod on a different node.
    // That's a problem because the persistent volume we are using is a "ReadWriteOnce" volume, meaning only one node at
    // a time is allowed to access it. With a "Rolling" strategy the new pod on a new node never comes up because the
    // volume is still in use on the old node, by the old pod. The "Recreate" strategy incurs some downtime in the service however,
    // ReadWriteOnce mode has the advantage of being supported on all cloud providers. Other modes require custom storage plugins
    // and/or creating non-k8s disk resources in the cloud provider.
    // The other option for allowing "Rolling" stategy and "ReadWriteOnce" is if we ensure the new pod comes up on the
    // same node with NodeAffinity setting - however this may result in complete downtime if the node goes down.
    strategy: {
      type: "Recreate",
    },
    template: {
      metadata: {
        name: "p0-braekhus-proxy",
        labels: {
          "app.kubernetes.io/name": "p0-braekhus-proxy",
        },
      },
      spec: {
        containers: [
          {
            name: "braekhus",
            image: "p0security/braekhus:latest",
            imagePullPolicy: "Always",
            args: [
              "start:prod:client",
              "--targetUrl",
              clusterServer,
              "--clientId",
              clientId,
              "--jwkPath",
              "/p0-files",
              "--tunnelHost",
              tunnelHost,
              "--tunnelPort",
              "443",
            ],
            env: [
              {
                name: "NODE_EXTRA_CA_CERTS",
                value: "/p0-files/ca.pem",
              },
            ],
            resources: {
              limits: {
                memory: "2Gi",
                cpu: 1,
              },
              requests: {
                memory: "1Gi",
                cpu: 1,
              },
            },
            volumeMounts: [
              {
                mountPath: "/p0-files",
                name: "p0-files-storage",
              },
            ],
          },
        ],
        initContainers: [
          {
            name: "p0-certs-setup",
            image: "bash",
            args: [
              "-c",
              `echo ${clusterCertificate} | base64 -d | tee /p0-files/ca.pem`,
            ],
            volumeMounts: [
              {
                mountPath: "/p0-files",
                name: "p0-files-storage",
              },
            ],
          },
        ],
        volumes: [
          {
            name: "p0-files-storage",
            persistentVolumeClaim: {
              claimName: "p0-files-volume-claim",
              readOnly: false,
            },
          },
        ],
      },
    },
  },
});

export const P0_PROXY_VOLUME_CLAIM = (cloudProvider: CloudProvider) => ({
  apiVersion: "v1",
  kind: "PersistentVolumeClaim",
  metadata: {
    namespace: "p0-security",
    name: "p0-files-volume-claim",
  },
  spec: {
    accessModes: ["ReadWriteOnce"],
    storageClassName: CLOUD_TO_STORAGE_CLASS[cloudProvider],
    resources: {
      requests: {
        storage: "10Mi",
      },
    },
  },
});
