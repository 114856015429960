import { Button, Input } from "antd";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { LoginInputs } from "../Login";

type LoginFormProps = {
  onLogin: (arg0: LoginInputs) => void;
};

export const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const { control, handleSubmit } = useForm<LoginInputs>();
  const handleLoginForm: SubmitHandler<LoginInputs> = (data) => {
    onLogin(data);
  };

  return (
    <form
      onSubmit={handleSubmit(handleLoginForm)}
      style={{ textAlign: "center" }}
    >
      <Controller
        name="email"
        control={control}
        rules={{
          required: true,
          pattern:
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i,
        }}
        render={({ field }) => (
          <Input {...field} placeholder="Your organization email" />
        )}
      />
      <Button type="primary" htmlType="submit">
        Login
      </Button>
    </form>
  );
};
