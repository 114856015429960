import { bindingPredicate } from "shared/assessment/issues/presets";
import { bindingReducers } from "shared/assessment/reducers/binding";

import { bindingColumns } from "./columns";

export const grantData = {
  predicate: bindingPredicate,
  reducers: bindingReducers,
  columns: bindingColumns,
};
