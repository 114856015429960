import { SINGLETON_ITEM } from "../../../install/constants";
import { BadInstallationError } from "../../../types/error";
import { GcloudIntegration } from "./types";

export const roleName = (roleId: string) =>
  roleId.startsWith("roles/") ? roleId.slice(6) : roleId;

/** Returns the root singleton item for a config
 *
 * Use this instead of indexing via {@link SINGLETON_ITEM}
 */
export const rootConfig = (config: GcloudIntegration) => {
  const root = config.root[SINGLETON_ITEM];
  if (!root) throw new BadInstallationError("gcloud", "Google Cloud");
  return root;
};
