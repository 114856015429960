import { IntegrationStatus } from "../integrations/shared";
import { DevEnv } from "../types/environment";
import { Ban, Collapsed } from "../types/util";

export type InstallContext<D> = Collapsed<
  {
    config: D;
    tenantId: string;
  } & DevEnv
>;

// --- Schema definition ---

/** Construct an installation specification.
 *
 * An installation specification is a mapping of component keys to {@link ItemComponent}
 * specifications.
 */
export const InstallSpec = <I extends InstallSpec>(spec: I): Readonly<I> =>
  spec;
export type InstallSpec = Readonly<Record<string, ItemComponent<EmptySchema>>>;

/** Construct an item configuration component.
 *
 * An item configuration component bundles together multiple configuration elements,
 * determined by this component's `schema`. The resulting configuration is an object
 * whose property names are equal to the schema's keys, and property values determined
 * from the schema's corresponding element values.
 *
 * The item configuration is also an element itself; this element defines how to
 * determine the item's identifier.
 */
export const ItemComponent = <I extends ItemComponent<EmptySchema>>(
  component: I extends ItemComponent<infer S>
    ? // This wonkiness is necessary to get TS to prevent "id" or "label" from appearing in schema
      S extends Ban<S, "id" | "label" | "state">
      ? I
      : // Indicate to user which key(s) are offending
        { schema: Omit<S, "id" | "label" | "state"> }
    : never
): Readonly<I> => component;

export const Component = <C extends Component<EmptySchema>>(
  component: C
): Readonly<C> => component;

/** Construct a configuration element.
 *
 * Configuration elements correspond to a property in the item's configuration.
 * Corresponding configuration values are determined via user input, depending on
 * the type of element.
 */
export const Element = <E extends Element>(element: E): Readonly<E> => element;

/** Construct an integration install option.
 *
 * An option is just a {@link Component}, but can not have a property named
 * "type" in its schema.
 *
 * The option's configuration value is an object with property `type` equal
 * to this key, and remaining properties generated by mapping the schema
 * values according to {@link Element}.
 */
export const Option = <O extends Option<EmptySchema>>(
  option: O extends Option<infer S>
    ? // Prevents user from creating option named "type"
      S extends Ban<S, "type">
      ? O
      : // Provide friendly feedback to user indicating that "type" key is the problem
        { schema: Option<Omit<S, "type">>["schema"] }
    : never
): Readonly<O> => option;

/** Represents an {@link OptionsElement}'s options */
export type Option<S extends EmptySchema> = Readonly<Component<S>>;

/** A configuration element whose value is determined by executing a
 * callback on a passed {@link InstallContext}.
 */
export type DynamicElement = {
  type: "dynamic";
};

/** An element whose value is determined via user text input. */
type StringElement = { type: "string" };

/** An element whose value is determined by the backend. */
type GeneratedElement = { type: "generated" };

/** An Element whose value is determined by user switch input. */
type SwitchElement = { type: "switch" };

/** An element that is hidden from the user.
 *
 * Use a HiddenElement to store data without a UI.
 */
type HiddenElement = { type: "hidden" };

/** Represents an item component with only one install.
 *
 * Use SingletonItem for organization-wide components.
 */
type SingletonItem = { type: "singleton" };

/** A configuration element whose value is determined by user selection from a list
 * of statically known choices.
 */
export type OptionsElement<
  O extends Readonly<Record<string, Option<EmptySchema>>>
> = {
  type: "select";
  default: string;
  options: O;
};

type BaseElement = {
  /** Short display label for this element */
  label: string;
  /** A longer human-readable description to display alongside the label */
  description?: string;
  /** If true, this element is hidden */
  hidden?: true;
};

type StringTypedElement = BaseElement & {
  /** Performs config item form-field validation
   */
  validator?: Validator;
};

export type Element = Readonly<
  | (BaseElement & SwitchElement)
  | (StringTypedElement &
      (
        | DynamicElement
        | GeneratedElement
        | HiddenElement
        | OptionsElement<Record<string, Option<any>>>
        | StringElement
      ))
>;

type EmptySchema = Readonly<Record<never, Element>>;

export type Component<S extends EmptySchema> = Readonly<
  BaseElement & {
    schema: S;
  }
>;

export type ItemComponent<S extends EmptySchema> = Readonly<Component<S>> &
  StringTypedElement &
  (DynamicElement | GeneratedElement | SingletonItem | StringElement) & {
    maxItems?: number;
  };

// --- config generation ---

type ConvertOption<K, O> = O extends Option<infer S>
  ? Collapsed<
      {
        type: K;
      } & {
        [SS in keyof S]: ElementConfigOf<S[SS]>;
      }
    >
  : never;

export type OptionsConfigOf<E> = E extends OptionsElement<infer O>
  ? {
      [K in keyof O]: ConvertOption<K, O[K]>;
    }[keyof O] // Makes a union type of the individual option types
  : never;

export type ElementConfigOf<I> = I extends StringElement
  ? string
  : I extends GeneratedElement
  ? string
  : I extends HiddenElement
  ? string
  : I extends DynamicElement
  ? string
  : I extends SwitchElement
  ? boolean
  : OptionsConfigOf<I>;

export type ComponentConfigOf<C> = C extends Component<infer S>
  ? {
      [K in keyof S]: ElementConfigOf<S[K]>;
    }
  : never;

/** Shared base data for all component installations */
export type BaseComponent = {
  label?: string;
  /** Integration status
   *
   * States are:
   * - (undefined): Installation not started; user is displayed a prompt to
   *   enter a component item identifier
   * - `stage`: User has entered component item identifier; user is displayed
   *   instructions to set up the integration in the 3rd-party system
   * - `configure`: User has set up the integration in the 3rd-party system;
   *   user is show a configuration form
   * - `installed`: P0 backend has fully validated installation and it is
   *   ready to use
   */
  state: Extract<
    IntegrationStatus,
    "configure" | "error" | "installed" | "stage"
  >;
};

/** Transitions between integration states
 *
 * - `assemble`: Transition from no installation to `stage`; assemblers are used
 *   to generate any data needed to present set-up instructions
 * - `verify`: Transition from `stage` to `configure`; verifies that set-up
 *   instructions are run correctly
 * - `configure`: Transition form `configure` to `installed`; validates
 *   configuration settings
 */
export const InstallSteps = {
  assemble: {
    installer: "assembler",
    // Can always start from scratch (undefined means 'all')
    expectedStates: undefined,
    nextState: "stage",
  } as const,
  verify: {
    installer: "verifier",
    // Any defined state
    expectedStates: undefined,
    nextState: "configure",
  } as const,
  configure: {
    installer: "configurer",
    expectedStates: ["configure", "installed"],
    nextState: "installed",
  } as const,
} as const;

export type ItemConfigOf<I> = Collapsed<BaseComponent & ComponentConfigOf<I>>;

/** Creates a configuration data model from an install specification.
 *
 * The data model is constructed according to the rules for {@link ItemComponent}
 * and {@link Element} above.
 */
export type ConfigOf<C> = {
  [K in keyof C]: Record<string, ItemConfigOf<C[K]>>;
};

// --- Installers ---

// Machinery for typing Installers.
//
// Type parameter convention:
//   Domain - defines types that are specific to the install domain (viz., backend or frontend)
//   Root - the installations config option, extends ConfigOf<Install>
//   Item - a specific installed component config with a unique identifier, will extends Root[keyof Root][number]
//   Install - the install metadata specification, extends InstallSpec
//   Field - a specific install field metadatum, is a child of Install

/** Input installation data for an installation domain (e.g. backend or frontend)
 *
 * @param Context - The context type this domain passes to handler functions
 * @param Help - The return type that help functions will return
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type InstallDomain<Context, Help> = {
  context: Context;
};

/** Constructs a list of options presented by a "dynamic" element */
export type DynamicProvider<Domain> = Domain extends InstallDomain<
  infer Context,
  infer _Help
>
  ? (context: Context) => Promise<{ id: string; label?: string }[]>
  : never;

type InstructionCommand<Help> = {
  header?: Help;
  command: string;
};

export type Instructions<Help> = {
  help?: Help;
  commands?: {
    console?: Help;
    shell?: InstructionCommand<Help>[];
    iac?: InstructionCommand<Help>[];
  };
};

/** Provides installation instructions */
export type Instructor<Domain, Item, Field> = Domain extends InstallDomain<
  infer Context,
  infer Help
>
  ? (
      context: Context,
      id: string,
      item: Item,
      field: Field,
      state: Record<string, any>,
      setState: (value: Record<string, any>) => any
    ) => Instructions<Help>
  : never;

export type MetadataProvider<Domain, Item, Field> =
  Domain extends InstallDomain<infer Context, infer _Help>
    ? (
        context: Context,
        id: string,
        item: Item,
        field: Field
      ) => Partial<Record<string, any>>
    : never;

/** Provides friendly labels for config elements */
export type Labeler<Domain, Item> = Domain extends InstallDomain<
  infer Context,
  infer Help
>
  ? (
      context: Context,
      id: string,
      item: Item,
      field: { id: string; label?: string }
    ) => Help
  : never;

/** Returns whether this config meets necessary pre-requisites for installation
 *
 * Truthy values will disable the option and be printed for the user.
 */
export type PrerequisiteMessages<Domain> = Domain extends InstallDomain<
  infer Context,
  infer _Help
>
  ? (context: Context) => Promise<any>
  : never;

/** Validates the config based on this user's input
 *
 * After validation the item will be in "installed" state.
 */
type Validator = (id: string, field: string) => Promise<string | undefined>;

/** Updates the config based on this user's input
 *
 * Used when adding or reconfiguring an item. After assembly the item will be in
 * "configure" state.
 */
export type Assembler<Domain, Item, Field> = Domain extends InstallDomain<
  infer Context,
  infer _Help
>
  ? (
      context: Context,
      id: string,
      item: Item,
      field: Field
    ) => Promise<Partial<Field> | undefined>
  : never;

type BackendInstalls<Domain, Item, Field> = {
  /** Renders step instructions
   */
  metadata?: MetadataProvider<Domain, Item, Field>;
  /** Runs on item creation (zero -> stage)
   */
  assembler?: Assembler<Domain, Item, Field>;
  /** Runs on final install step / update to config (configure -> installed)
   */
  configurer?: Assembler<Domain, Item, Field>;
  /** Runs after user runs setup commands in the integrated environment (stage -> configure)
   */
  verifier?: Assembler<Domain, Item, Field>;
};

type FrontendInstalls<Domain, Item, Field> = {
  /** Renders step instructions
   */
  instructions?: Instructor<Domain, Item, Field>;
  /** Renders config item labels
   */
  labeler?: Labeler<Domain, Item>;

  /** Allows a frontend installer to override the default
   *  error message from the element level validation.
   *  This is useful for enabling the frontend code to
   *  replace the error string from the backend with a
   *  JSX element using links or typography, but any type can
   *  work.
   * */
  errorElement?: any;
};

/** Todo: separate these */
export type Installs<Domain, Item, Field> = BackendInstalls<
  Domain,
  Item,
  Field
> &
  FrontendInstalls<Domain, Item, Field>;

type DynamicElementInstalls<Domain, Element> = Element extends DynamicElement
  ? {
      optionProvider: DynamicProvider<Domain>;
      prerequisiteMessages?: PrerequisiteMessages<Domain>;
    }
  : object;

type OptionsElementInstalls<Domain, Root, Item, Element> =
  Element extends OptionsElement<infer O>
    ? {
        options: {
          [OO in keyof O]?: ComponentInstallerOf<Domain, Root, Item, O[OO]> & {
            prerequisiteMessages?: PrerequisiteMessages<Domain>;
          };
        };
      }
    : object;

export type ElementInstaller<Domain, Root, Item, Element> = Collapsed<
  OptionsElementInstalls<Domain, Root, Item, Element> &
    DynamicElementInstalls<Domain, Element> &
    Installs<Domain, Item, ElementConfigOf<Element>>
>;

export type ComponentSchemaInstalls<Domain, Root, Item, Schema> =
  Schema extends Record<string, never>
    ? object
    : {
        items: {
          [K in keyof Schema]?: ElementInstaller<Domain, Root, Item, Schema[K]>;
        };
      };

export type ComponentInstaller<Domain, Root, Item, This, SchemaEntry> =
  Collapsed<
    Installs<Domain, Item, ItemConfigOf<This>> &
      ComponentSchemaInstalls<Domain, Root, Item, SchemaEntry> &
      DynamicElementInstalls<Domain, This>
  >;

type ComponentInstallerOf<Domain, Root, Item, This> = This extends Component<
  infer S
>
  ? ComponentInstaller<Domain, Root, Item, This, S>
  : never;

/** Defines how a config is installed.
 *
 * Each element of the configuration's {@link InstallSpec} is mapped into this
 * data structure, with each entry defining which instructions to display,
 * any pre-requisites that must be met in order to install, and any validation
 * to run after user configuration.
 */
export type ItemInstallerOf<Domain extends InstallDomain<any, any>, Install> = {
  [K in keyof Install]: ComponentInstallerOf<
    Domain,
    ConfigOf<Install>,
    ConfigOf<Install>[K][string],
    Install[K]
  >;
};
