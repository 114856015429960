import { assertNever } from "../../../../types";
import { DevEnv, Environment } from "../../../../types/environment";
import {
  CREATED_BY_CLOUD_SHELL,
  MANAGED_BY_TERRAFORM,
  P0_TAG_NAME,
} from "../constants";
import { AwsUse } from "../types";

export const ENV_TO_P0_AWS_SUFFIX: { [key in Environment]: string } = {
  dev: "Dev",
  test: "Dev",
  "prod-cna-central": "",
  prod: "",
  stage: "Stage",
};

export const awsSuffix = (context: DevEnv) =>
  "developer" in context
    ? context.developer
        .split("-")
        // can't use lodash since this is imported to FE
        .map((n) => n.slice(0, 1).toUpperCase() + n.slice(1).toLowerCase())
        .join("")
    : "";

export const awsRoleName = (context: DevEnv, use: AwsUse): string =>
  `P0RoleIam${
    use === "iam-assessment"
      ? "Assessor"
      : use === "iam-write"
      ? "Manager"
      : use === "base" || use === "inventory"
      ? (() => {
          throw new Error(`Unexpected AWS role creation for use '${use}'`);
        })()
      : assertNever(use)
  }${
    "developer" in context
      ? `${ENV_TO_P0_AWS_SUFFIX.dev}${awsSuffix(context)}`
      : ENV_TO_P0_AWS_SUFFIX[context.environment]
  }`;

/** Trust policy for all roles assumed by the P0 backend */
export const p0TrustPolicy = (gcloudServiceAccountId: string) => ({
  Version: "2012-10-17",
  Statement: [
    {
      Effect: "Allow",
      Principal: {
        Federated: "accounts.google.com",
      },
      Action: "sts:AssumeRoleWithWebIdentity",
      Condition: {
        StringEquals: {
          "accounts.google.com:aud": gcloudServiceAccountId,
        },
      },
    },
  ],
});

export const p0InstallCommands = (args: {
  gcloudServiceAccountId: string;
  awsAccountId: string;
  policy: object;
  roleName: string;
  use: AwsUse;
}) => {
  const trustPolicy = p0TrustPolicy(args.gcloudServiceAccountId);
  const trustPolicyJson = JSON.stringify(trustPolicy, undefined, 2);
  const commands = `
export CALLER=$(aws sts get-caller-identity --query Arn --output text) && \\
aws iam create-role \\
  --tags Key="${P0_TAG_NAME}",Value="${CREATED_BY_CLOUD_SHELL} by \${CALLER}" \\
  --role-name "${args.roleName}" \\
  --assume-role-policy-document '${trustPolicyJson}' \\
  && \\
aws iam put-role-policy \\
  --role-name "${args.roleName}" \\
  --policy-name "${args.roleName}Policy" \\
  --policy-document '${JSON.stringify(args.policy, undefined, 2)}'
`;
  return commands.trim();
};

export const p0InstallTerraform = (args: {
  gcloudServiceAccountId: string;
  policy: object;
  roleName: string;
  use: AwsUse;
}) => {
  const resourceId = args.use.replace("-", "_");
  const trustPolicy = p0TrustPolicy(args.gcloudServiceAccountId);
  const hcl = `data "aws_caller_identity" "current" {}

locals {
  aws_account_id = data.aws_caller_identity.current.account_id
}

resource "aws_iam_role" "p0_aws_${resourceId}_role" {
  name = "${args.roleName}"

  assume_role_policy = <<EOF
${JSON.stringify(trustPolicy, undefined, 2)}
EOF

  inline_policy {
    name = "${args.roleName}Policy"
    policy = <<EOF
${JSON.stringify(args.policy, undefined, 2)}
EOF
  }

  tags = {
    ${P0_TAG_NAME} = "${MANAGED_BY_TERRAFORM}"
  }
}`;
  return hcl;
};
