import Paragraph from "antd/lib/typography/Paragraph";
import { PROVISION_USER_ACCESS_DOCUMENT } from "shared/integrations/resources/aws/constants";

import { SudoSshInstaller } from "../types";

const SUDO_SSH_YAML_DOCUMENT = `schemaVersion: "2.2"
description: "Grant/revoke password-less sudo access, add/remove an authorized ssh key, or create a user"
parameters:
  UserName:
    type: "String"
    description: "User name"
  Action:
    type: "String"
    description: "'grant' or 'revoke'"
    allowedValues:
    - grant
    - revoke
  RequestId:
    type: "String"
    description: "P0 access request identifier"
  PublicKey:
    type: "String"
    description: "SSH public key"
    default: "N/A"
  Sudo:
    type: "String"
    description: "Whether to grant sudo access"
    allowedValues:
    - "false"
    - "true"
    default: "false"
mainSteps:
- precondition:
    StringEquals:
      - platformType
      - Linux
  action: aws:runShellScript
  name: InvokeLinuxScript
  inputs:
    runCommand:
      - |
        #!/bin/bash
        set -e

        ExitWithFailure() {
          MESSAGE="\\\$1"
          (>&2 "echo" "\\\$MESSAGE")
          exit 1
        }

        EnsureUserExists() {
          local COMMAND CREATE_HOME_ARGUMENT
          local USERNAME="\\\$1"

          if [ -f /usr/sbin/useradd ]; then
            COMMAND='/usr/sbin/useradd'
            CREATE_HOME_ARGUMENT='--create-home'
          elif [ -f /usr/sbin/adduser ]; then
            COMMAND='/usr/sbin/adduser'
            CREATE_HOME_ARGUMENT=''
          else
            ExitWithFailure 'Cannot create user: neither of the required commands adduser or useradd exist.'
          fi

          id "\\\$USERNAME" &>/dev/null || \\\$COMMAND "\\\$USERNAME" "\\\$CREATE_HOME_ARGUMENT" || ExitWithFailure 'Failed to create the specified user.'
        }

        EnsureLineInFile() {
          local LINE="\\\$1"
          local FILE="\\\$2"
      
          if ! grep -qF "\\\$LINE" "\\\$FILE"; then
              echo "\\\$LINE" | sudo tee -a "\\\$FILE" >/dev/null
          fi
        }

        EnsureContentInFile() {
          local CONTENT="\\\$1"
          local REQUEST_ID="\\\$2"
          local FILE_PATH="\\\$3"
          local COMMENT="# RequestID: \\\$REQUEST_ID"
      
          sudo mkdir -p "\\\$(dirname "\\\$FILE_PATH")"

          if [ ! -e "\\\$FILE_PATH" ]; then
            sudo touch "\\\$FILE_PATH"
          fi
      
          if ! (grep -qF "\\\$COMMENT" "\\\$FILE_PATH" && grep -qF "\\\$CONTENT" "\\\$FILE_PATH"); then
              echo "\\\$COMMENT" | sudo tee -a "\\\$FILE_PATH" >/dev/null
              echo "\\\$CONTENT" | sudo tee -a "\\\$FILE_PATH" >/dev/null
          fi
        }

        RemoveContentFromFile() {
          local REQUEST_ID="\\\$1"
          local FILE_PATH="\\\$2"
          local COMMENT="# RequestID: \\\$REQUEST_ID"
        
          if [ -f "\\\$FILE_PATH" ]; then
              sudo sed -i "/^\\\$COMMENT$/,/^$/d" "\\\$FILE_PATH"
          fi
        }
      
        if [ "{{ Action }}" = "grant" ]
        then
          EnsureUserExists '{{ UserName }}'
          if [ -n "{{ Sudo }}" ] && [ "{{ Sudo }}" = "true" ]; then
            EnsureContentInFile "{{ UserName }} ALL=(ALL) NOPASSWD: ALL" "{{ RequestId }}" "/etc/sudoers-p0"
            EnsureLineInFile "#include sudoers-p0" /etc/sudoers
          fi
          if [ -n "{{ PublicKey }}" ] && [ "{{ PublicKey }}" != "N/A" ]; then
            EnsureContentInFile "{{ PublicKey }}" "{{ RequestId }}" "/home/{{ UserName }}/.ssh/authorized_keys"
          fi
        else
          RemoveContentFromFile "{{ RequestId }}" "/etc/sudoers-p0"
          RemoveContentFromFile "{{ RequestId }}" "/home/{{ UserName }}/.ssh/authorized_keys"
        fi
`;

const sudoSshCliFor = () => {
  const fileName = PROVISION_USER_ACCESS_DOCUMENT.documentName;
  return `cat << EOF>$HOME/${fileName}.yaml
${SUDO_SSH_YAML_DOCUMENT}
EOF
export REGIONS_ALL=$( \\
  aws account list-regions --output text \\
  --query 'Regions[?(RegionOptStatus!=\`DISABLED\` && RegionOptStatus!=\`DISABLING\`)].RegionName' \\
)
for region in $REGIONS_ALL; do 
  aws ssm create-document --name "${fileName}"  --document-type "Command" --document-format "YAML" --target-type "/AWS::EC2::Instance" --tags Key=managed-by,Value=terraform Key=used-by,Value=P0Security --content file://$HOME/${fileName}.yaml --region $region
done 
  `;
};

const sudoSshIacFor = () => {
  return `
resource "aws_ssm_document" "p0_manage_sudo_access" {
  name            = "${PROVISION_USER_ACCESS_DOCUMENT.documentName}"
  document_format = "YAML"
  document_type   = "Command"
  target_type     = "/AWS::EC2::Instance"

  content = <<DOC
${SUDO_SSH_YAML_DOCUMENT}
DOC
  tags = {
    managed-by = "terraform"
    used-by    = "P0Security"
  }
}`;
};

const sudoSshInstructions: SudoSshInstaller["instructions"] = (
  _context,
  _id,
  _item,
  field
) => {
  return field
    ? {
        help: (
          <Paragraph>
            Create SSM command document for P0 to provision sudo access to the
            instance. P0 requires the document to be created in all regions for
            the account.
          </Paragraph>
        ),
        commands: {
          shell: [{ command: sudoSshCliFor() }],
          iac: [{ command: sudoSshIacFor() }],
        },
      }
    : {
        help: (
          <Paragraph>
            Sudo document installation is skipped, click next to continue
          </Paragraph>
        ),
      };
};

export const sudoSshInstaller: SudoSshInstaller = {
  instructions: sudoSshInstructions,
};
