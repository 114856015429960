import { endOfDay, startOfDay } from "date-fns";
import { where } from "firebase/firestore";
import { groupBy, noop, partition, sortBy } from "lodash";
import {
  FirestoreDoc,
  useFirestoreCollection,
} from "providers/FirestoreProvider";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router";
import {
  ALL_SCOPE_SENTINEL,
  MonitorRanking,
} from "shared/assessment/constants";
import { isa } from "shared/types/is";
import {
  DateParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import {
  PresetMonitors,
  convertSavedMonitor,
} from "../../../shared/assessment/issues/presets";
import {
  Finding,
  FindingState,
} from "../../../shared/types/assessment/finding";
import {
  Monitor,
  SavedMonitor,
} from "../../../shared/types/assessment/monitor";
import { Tenant } from "../../Login";
import { ScopeContext } from "./ScopeContext";
import { SelectedAssessmentContext } from "./SelectedAssessmentContext";

export type MonitorWithFindings = Monitor & {
  /** All findings for the current findings select */
  scopedFindings: FirestoreDoc<Finding>[];
  /** Findings for the current select, but for all possible scopes */
  findingsByScope: Record<string, FirestoreDoc<Finding>[]>;
  monitorId: string;
  isCustom?: boolean;
  archived?: boolean;
};

type AssessmentFindings = {
  /** Mapping of monitor ID to corresponding findings */
  allMonitors: Record<string, MonitorWithFindings>;
  archivedMonitors: MonitorWithFindings[];
  findingParams: string;
  loading: boolean;
  /** Priority-sorted list of monitors that have findings, filtered by findings select */
  prioritized: MonitorWithFindings[];
  range: DateRange;
  setRange: (range: DateRange) => void;
  setState: (value: string) => void;
  setTrigger: (value: string) => void;
  state: FindingState;
  trigger: string;
};

type DateRange = [Date | undefined, Date | undefined];

export const FindingsContext = createContext<AssessmentFindings>({
  allMonitors: {},
  archivedMonitors: [],
  findingParams: "",
  loading: true,
  prioritized: [],
  range: [undefined, undefined],
  setRange: noop,
  setState: noop,
  setTrigger: noop,
  state: "open",
  trigger: "all",
});

const inScope = (scopeKey: string) => (finding: FirestoreDoc<Finding>) =>
  scopeKey === ALL_SCOPE_SENTINEL || finding.data.scopeKey === scopeKey;

export const convertPresetsToMonitorsWithFindings = (
  findingsMap: Record<string, FirestoreDoc<Finding>[]>,
  scopeKey: string
) => {
  const output: Record<string, MonitorWithFindings> = {};
  for (const monitorId of Object.keys(PresetMonitors)) {
    const findings = findingsMap[monitorId] ?? [];
    output[monitorId] = {
      ...(PresetMonitors[monitorId as keyof typeof PresetMonitors] as Monitor),
      scopedFindings: findings.filter(inScope(scopeKey)),
      findingsByScope: groupBy(findings, (f) => f.data.scopeKey),
      monitorId,
    };
  }
  return output;
};

const isInRange = (
  trigger: Date,
  start: Date | null | undefined,
  end: Date | null | undefined
) =>
  // start & end are day-aligned, so broaden range to day starts and day ends
  (!start || trigger >= startOfDay(start)) &&
  (!end || trigger <= endOfDay(end));

export const FindingsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { last } = useContext(SelectedAssessmentContext);
  const { scopeKey } = useContext(ScopeContext);
  const { assessmentId } = useParams();
  const tenantId = useContext(Tenant);

  const [trigger, setTrigger] = useQueryParam(
    "trigger",
    withDefault(StringParam, "all")
  );
  const [start, setStart] = useQueryParam("start", DateParam);
  const [end, setEnd] = useQueryParam("end", DateParam);
  const [state, setState] = useQueryParam(
    "state",
    withDefault(StringParam, "open")
  );
  const setRange = useCallback(
    ([start, end]: DateRange) => {
      setStart(start);
      setEnd(end);
    },
    [setEnd, setStart]
  );

  const findingParams = `state=${state}&trigger=${trigger}${
    scopeKey !== ALL_SCOPE_SENTINEL ? `&scope=${scopeKey}` : ""
  }`;

  const data = useFirestoreCollection<Finding>(
    `o/${tenantId}/iam-assessments/${assessmentId}/findings`,
    {
      live: true,
      queryConstraints: [
        ...(state === "all" ? [] : [where("state", "==", state)]),
      ],
    }
  );

  const customMonitors = useFirestoreCollection<SavedMonitor>(
    `o/${tenantId}/iam-assessments/${assessmentId}/monitors`,
    {
      live: true,
    }
  );

  const allMonitors = useMemo(() => {
    const inRange =
      data?.filter((f) => isInRange(new Date(f.data.trigger.at), start, end)) ??
      [];
    const matchesTrigger = inRange.filter((f) =>
      trigger === "new" ? f.data.trigger.jobId === last.doc?.data.jobId : true
    );
    const findingsMap = groupBy(matchesTrigger, (f) => f.data.monitorId);

    const monitorsOutput = convertPresetsToMonitorsWithFindings(
      findingsMap,
      scopeKey
    );

    for (const monitor of customMonitors ?? []) {
      const findings = findingsMap[monitor.id] ?? [];
      monitorsOutput[monitor.id] = {
        ...convertSavedMonitor(monitor.data),
        scopedFindings: findings.filter(inScope(scopeKey)),
        findingsByScope: groupBy(findings, (f) => f.data.scopeKey),
        isCustom: true,
        archived: monitor.data.archived,
        monitorId: monitor.id,
      };
    }

    return monitorsOutput;
  }, [
    data,
    scopeKey,
    start,
    end,
    trigger,
    last.doc?.data.jobId,
    customMonitors,
  ]);

  const [prioritized, archivedMonitors] = useMemo(() => {
    const sorted = sortBy(
      Object.values(allMonitors),
      (i) => MonitorRanking[i.priority]
    );
    const withFindings = sorted.filter((s) => !!s.scopedFindings.length);
    return partition(withFindings, (monitor) => !monitor.archived);
  }, [allMonitors]);

  return (
    <FindingsContext.Provider
      value={{
        allMonitors,
        archivedMonitors,
        findingParams,
        loading: data === undefined,
        prioritized,
        range: [start ?? undefined, end ?? undefined],
        setRange,
        setState,
        setTrigger,
        state: isa(FindingState, state) ? state : "open",
        trigger,
      }}
    >
      {children}
    </FindingsContext.Provider>
  );
};
