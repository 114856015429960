import { Spin } from "antd";
import { useState } from "react";

import { useApiFetch } from "../../hooks/useApiFetch";
import { useGuardedEffect } from "../../hooks/useGuardedEffect";
import { Catalog } from "../../shared/types/catalog";
import { ErrorDisplay } from "../Error";
import { CatalogContext } from "./context";

export const CatalogProvider: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const [error, setError] = useState<string>();
  const [value, setValue] = useState<Catalog>();
  const apiFetch = useApiFetch(setError);

  useGuardedEffect(
    async (cancellation) => {
      try {
        const response = await apiFetch("catalog", {
          method: "GET",
        });
        if (!response) return;
        cancellation.guard(setValue)((await response.json()) as Catalog);
      } catch (error: any) {
        return setError(String(error));
      }
    },
    setError,
    []
  );
  return value ? (
    <CatalogContext.Provider value={value}>{children}</CatalogContext.Provider>
  ) : error ? (
    <ErrorDisplay error={error} title="Could not load IAM Risk Catalog" />
  ) : (
    <Spin />
  );
};
