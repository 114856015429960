import { AwsIconUrl } from "components/Integrations/Aws/Aws";
import { GcloudIconUrl } from "components/Integrations/Gcloud/Gcloud";
import { Logo } from "components/Integrations/Logo";
import { assertNever } from "shared/types";
import { AssessmentScopeIntegration } from "shared/types/assessment";

export const targetLogo = (integration: AssessmentScopeIntegration) => {
  switch (integration) {
    case "gcloud":
      return <Logo logo={GcloudIconUrl} title="Google Cloud" />;
    case "aws":
      return <Logo logo={AwsIconUrl} title="AWS" />;
    default:
      assertNever(integration);
  }
};
