import { ErrorDisplay } from "components/Error";
import { useAuthFetch } from "components/Login/hook";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isArray, noop, sortBy } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";
import { ALL_SCOPE_SENTINEL } from "shared/assessment/constants";
import { AssessmentMap } from "shared/assessment/issues/presets";
import { Representation, fromRepresentation } from "shared/graph/marshall";
import { addDigrams, addTypeBoost } from "shared/graph/search";
import { DirectedGraph } from "shared/graph/types";
import { ItemAssessmentScope, toKey, toScope } from "shared/types/assessment";
import { AssessmentNodes } from "shared/types/assessment/data";
import { sleep } from "shared/util/sleep";
import { StringParam, UrlUpdateType, useQueryParam } from "use-query-params";

import { GraphProcessingStep } from "../components/GraphStep";
import { SelectedAssessmentContext } from "./SelectedAssessmentContext";

export const transformGraphForUI = async (
  graph: Representation<AssessmentNodes>,
  setStep: (step: GraphProcessingStep) => void
) => {
  setStep("converting");
  // b.c. the graph processing operations are synchronous, we need to give React
  // an opportunity to render prior to continuing
  await sleep(1);

  const output = {
    nodes: sortBy(fromRepresentation(graph).nodes, (n) => n.key),
  };

  const before = performance.now();
  setStep("boosting");
  await sleep(1);
  addTypeBoost(output);
  addDigrams(output, AssessmentMap);
  // eslint-disable-next-line no-console
  console.log(
    "Time to add search boost",
    (performance.now() - before).toFixed(2),
    "ms"
  );
  setStep("done");
  await sleep(1);

  return output;
};

export const ScopeContext = createContext<{
  graph: DirectedGraph<AssessmentNodes> | undefined;
  integration: ItemAssessmentScope["integration"] | undefined;
  // TODO: Remove AWS hardcoding
  integrationMeta?: { idc?: { id: string } };
  scopeKey: string;
  setScopeKey: (scope: string, updateType?: UrlUpdateType) => void;
  step: GraphProcessingStep;
  validScopeKeys: Set<string>;
}>({
  graph: undefined,
  integration: undefined,
  scopeKey: ALL_SCOPE_SENTINEL,
  setScopeKey: noop,
  step: "loading",
  validScopeKeys: new Set(ALL_SCOPE_SENTINEL),
});

export const ScopeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { assessmentId } = useParams();
  const { last } = useContext(SelectedAssessmentContext);
  const flags = useFlags();
  const [error, setError] = useState<string>();
  const authFetch = useAuthFetch(setError);
  const [graph, setGraph] = useState<DirectedGraph<AssessmentNodes>>();
  const [lastGraphScopeKey, setLastGraphScopeKey] = useState("");
  const [step, setStep] = useState<GraphProcessingStep>("requested");
  const [integrationMeta, setIntegrationMeta] = useState<{
    idc?: { id: string };
  }>();

  const [scopeKey, setScopeKey] = useQueryParam("scope", StringParam);

  const onError = useCallback((error: any) => {
    console.error(error);
    setError(error);
  }, []);

  const validScopeKeys = useMemo(() => {
    const valid = new Set([ALL_SCOPE_SENTINEL]);
    if (!isArray(last.doc?.data.scope)) return valid;
    for (const scope of last.doc?.data.scope ?? []) {
      valid.add(toKey(scope));
    }
    return valid;
  }, [last.doc?.data.scope]);

  useGuardedEffect(
    async (cancellation) => {
      if (!scopeKey) return;
      if (scopeKey === ALL_SCOPE_SENTINEL) return; // TODO: remove eventually when we can derive url for each individual account
      if (toScope(scopeKey).integration !== "aws") return;
      if (!flags.assessmentAwsRoleDeeplink) return;
      setIntegrationMeta(undefined);
      const response = await authFetch(
        `assessment/scope/${scopeKey}/integration-meta`,
        {
          method: "GET",
        }
      );
      if (!response) return;

      const data = (await response.json()) as {
        additionalContext: { idc?: { id: string } };
      };
      if (cancellation.isCancelled) return;
      setIntegrationMeta(data.additionalContext);
    },
    onError,
    [assessmentId, last.doc, scopeKey]
  );

  useGuardedEffect(
    async (cancellation) => {
      if (!assessmentId || !last.doc || !scopeKey) return;
      if (scopeKey === ALL_SCOPE_SENTINEL) return;

      // Performance optimization: this is the last graph we loaded, so just reuse it
      if (scopeKey === lastGraphScopeKey) return;
      setLastGraphScopeKey(scopeKey);
      setGraph(undefined);
      setLastGraphScopeKey(scopeKey);
      setStep("requested");

      const response = await authFetch(
        `assessment/${assessmentId}/job/${
          last.doc.id
        }/scope/${encodeURIComponent(scopeKey)}`,
        {
          method: "GET",
        }
      );

      if (!response) return;
      setStep("loading");

      const data = (await response.json()) as Representation<AssessmentNodes>;

      if (cancellation.isCancelled) return;
      const graph = data
        ? await transformGraphForUI(data, cancellation.guard(setStep))
        : undefined;

      setGraph(graph);
      setStep("done");
    },
    onError,
    [assessmentId, last.doc, scopeKey]
  );

  const scope = useMemo(
    () =>
      typeof scopeKey === "string" && scopeKey !== ALL_SCOPE_SENTINEL
        ? toScope(scopeKey)
        : undefined,
    [scopeKey]
  );

  return (
    <ScopeContext.Provider
      value={{
        graph,
        integration: scope?.integration,
        integrationMeta,
        scopeKey: scopeKey || ALL_SCOPE_SENTINEL,
        setScopeKey,
        step,
        validScopeKeys,
      }}
    >
      {error && (
        <ErrorDisplay title="Error loading assessment" error={String(error)} />
      )}
      {children}
    </ScopeContext.Provider>
  );
};
