import { DevEnv } from "../../../../types/environment";
import { awsRoleName, p0InstallCommands, p0InstallTerraform } from "./shared";

export const iamAssessorPolicy = {
  Version: "2012-10-17",
  Statement: [
    {
      Sid: "P0CanReadPoliciesAndListResources",
      Effect: "Allow",
      Action: [
        "access-analyzer:GetFinding",
        "access-analyzer:ListFindings",
        "account:ListRegions",
        "ce:GetDimensionValues",
        "iam:GetGroup",
        "iam:GetGroupPolicy",
        "iam:GetPolicy",
        "iam:GetPolicyVersion",
        "iam:GetRole",
        "iam:GetRolePolicy",
        "iam:GetSAMLProvider",
        "iam:GetUser",
        "iam:GetUserPolicy",
        "iam:ListAccountAliases",
        "iam:ListAttachedGroupPolicies",
        "iam:ListAttachedRolePolicies",
        "iam:ListAttachedUserPolicies",
        "iam:ListGroupPolicies",
        "iam:ListGroups",
        "iam:ListGroupsForUser",
        "iam:ListPolicies",
        "iam:ListPolicyTags",
        "iam:ListPolicyVersions",
        "iam:ListRolePolicies",
        "iam:ListRoles",
        "iam:ListUserPolicies",
        "iam:ListUsers",
        "iam:ListUserTags",
        "resource-explorer-2:ListIndexes",
        "resource-explorer-2:Search",
        "sso:ListInstances",
      ],
      Resource: "*",
    },
  ],
};

export const accessAnalyzerName = "P0AccessAnalyzer";

const accessAnalyzerCommand = `aws accessanalyzer create-analyzer --type ACCOUNT_UNUSED_ACCESS \\
--analyzer-name ${accessAnalyzerName} \\
--configuration '{"unusedAccess" : {"unusedAccessAge" : 90}}'`;

const accessAnalyzerTerraform = `resource "aws_accessanalyzer_analyzer" "p0_aws_access_analyzer" {
  analyzer_name = "${accessAnalyzerName}"
  type          = "ACCOUNT_UNUSED_ACCESS"
  configuration {
    unused_access {
      unused_access_age = 90
    }
  }
}`;

export const iamAssessmentInstallCommands = (
  context: DevEnv,
  args: {
    gcloudServiceAccountId: string;
    awsAccountId: string;
  }
) => {
  const use = "iam-assessment";
  const roleName = awsRoleName(context, use);
  const policy = iamAssessorPolicy;
  const roleSetupCommands = p0InstallCommands({
    ...args,
    policy,
    roleName,
    use,
  });

  return `${roleSetupCommands} \\
  && \\ 
${accessAnalyzerCommand}`;
};

// TODO: Test terraform prior to making this install public
export const iamAssessmentTerraform = (
  gcloudServiceAccountId: string,
  context: DevEnv
) => {
  const use = "iam-assessment";
  const roleName = awsRoleName(context, use);
  const roleSetupTerraform = p0InstallTerraform({
    gcloudServiceAccountId,
    policy: iamAssessorPolicy,
    roleName,
    use,
  });

  return `${roleSetupTerraform}

${accessAnalyzerTerraform}`;
};
