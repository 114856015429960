import { Element, InstallSpec, ItemComponent } from "../../../install/types";
import { AWS_TAG_KEY_REGEX } from "./types";

export const SshComponents = InstallSpec({
  "iam-write": ItemComponent({
    type: "dynamic",
    label: "SSH Management",
    description: "Allows P0 to provide ssh access to compute Instances",
    schema: {
      groupKey: Element({
        label: "Grouping tag (optional; AWS only)",
        description:
          "If present, AWS instances will be grouped by the value of this tag. Access can be requested, in one request, to all instances with a shared tag value.",
        type: "string",
        validator: async (_id, field) => {
          if (!field.match(AWS_TAG_KEY_REGEX)) {
            return "Invalid AWS TAG Key. Expected a string of 1 to 128 characters";
          }
        },
      }),
      isSudoEnabled: Element({
        label: "Enable requesting sudo access (AWS only)",
        description:
          "If enabled the integration will provide sudo access to the instances",
        type: "switch",
      }),
    },
  }),
});
