import Link from "antd/lib/typography/Link";
import { FrontendInstallContext } from "install/types";
import { ReactNode } from "react";
import { Instructions } from "shared/install/types";
import { GCLOUD_COMPONENT_SETUP } from "shared/integrations/resources/gcloud/constants";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";
import { rootConfig } from "shared/integrations/resources/gcloud/util";

import { installIamCommands, installIamTerraform } from "./iam";

export const orgWidePolicyInstructor = (
  context: FrontendInstallContext<GcloudIntegration>
): Instructions<ReactNode> => ({
  help: (
    <>
      Provision P0&apos;s access using{" "}
      <Link
        href={`https://console.cloud.google.com/cloudshelleditor?organizationId=${
          rootConfig(context.config).organizationId
        }&cloudshell=true`}
        target="_blank"
        rel="noopener"
      >
        Cloud Shell
      </Link>{" "}
      or Terraform:
    </>
  ),
  commands: {
    shell: [
      {
        command: installIamCommands(GCLOUD_COMPONENT_SETUP["org-wide-policy"], {
          ...rootConfig(context.config),
          type: "organization",
        }),
      },
    ],
    iac: [
      {
        command: installIamTerraform(
          GCLOUD_COMPONENT_SETUP["org-wide-policy"],
          { ...rootConfig(context.config), type: "organization" }
        ),
      },
    ],
  },
});
