import { useEffect } from "react";

import { Cancellation } from "../utils/cancel";

/** useEffect, but handles cancellations within asynchronous code
 *
 * Usage:
 * ```
 * const [error, setError] = useState<any>();
 *
 * useGuardedEffect(
 *   async (cancellation) => {
 *     const data = await ...;
 *     cancellation.guard(stateMutation)(data);
 *   }),
 *   setError,
 *   [...dependencies]
 * }
 * ```
 */
export const useGuardedEffect = (
  effect: (cancellation: Cancellation) => Promise<void>,
  onError: (error: any) => void,
  dependencies: any[]
) => {
  useEffect(() => {
    const cancellation = new Cancellation();
    effect(cancellation).catch(cancellation.guard(onError));
    return cancellation.cancel;
    // can't validate dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, onError]);
};
