import { Spin, Typography } from "antd";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { FrontendInstallContext } from "install/types";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { SINGLETON_ITEM } from "shared/install/constants";
import {
  ConfigOf,
  InstallSpec,
  Instructions as InstructionsType,
  ItemComponent,
  ItemConfigOf,
} from "shared/install/types";

import { ComponentProps } from "./Component";
import { ConfigForm } from "./ConfigForm";
import { DiffInstructions } from "./DiffInstructions";
import { Instructions } from "./Instructions";

export type ConfigProps<T extends InstallSpec> = ComponentProps<T> & {
  component: ItemComponent<any>;
  context: FrontendInstallContext<ConfigOf<T>>;
  id: string;
  item: ItemConfigOf<T[string]>;
};

export const configPath = (props: {
  integration: string;
  componentKey?: string;
  id?: string;
}) => {
  const { id, integration, componentKey } = props;
  return `integrations/${integration}/config${
    componentKey ? `/${componentKey}` : ""
  }${id ? `/${id}` : ""}`;
};

export const Config = <T extends InstallSpec>(props: ConfigProps<T>) => {
  const {
    authFetch,
    canEdit,
    component,
    componentKey,
    context,
    id,
    installer,
    itemKind,
    item,
  } = props;
  const navigate = useNavigate();
  const [last, setLast] = useState<any>();
  const [staged, setStaged] = useState<any>();
  const [instructionState, setInstructionState] = useState<Record<string, any>>(
    {}
  );

  const title = useMemo(
    () => (item.label ? `${item.label} (${id})` : id),
    [id, item]
  );

  const instructions = useMemo(
    () =>
      installer[componentKey]?.instructions?.(
        context,
        id,
        item as any,
        item,
        instructionState,
        setInstructionState
      ),
    [componentKey, context, id, installer, instructionState, item]
  );

  const stageConfig = useCallback(
    async (config: any) => {
      setLast(config);
      setStaged(config);
    },
    [setStaged]
  );

  const submit = useCallback(
    async (config: any, step: "configure" | "verify", stayOnItem?: boolean) => {
      const response = await authFetch(`${configPath(props)}/${step}`, {
        method: "POST",
        json: config,
      });
      if (!stayOnItem && response?.ok) navigate("..", { relative: "path" });
    },
    [authFetch, props, navigate]
  );

  const verify = useCallback(
    async () => await submit(item, "verify", true),
    [item, submit]
  );
  const logError = useCallback((error: any) => console.error(error), []);

  // If in stage and there are no set-up instructions, automatically advance to configure step
  useGuardedEffect(
    async () => {
      if (item.state === "stage" && !instructions) verify();
    },
    logError,
    [item.state, instructions]
  );

  const finish = useCallback(async () => {
    await submit(staged, "configure", false);
    setStaged(null);
  }, [staged, submit]);

  return (
    <>
      <Typography.Title level={5}>
        Configuring{id === SINGLETON_ITEM ? "" : ` ${itemKind} ${title}`}
      </Typography.Title>
      {item.state === "stage" ? (
        instructions ? (
          <Instructions
            instructions={instructions as InstructionsType<ReactNode>}
            isFetching={props.isFetching}
            onNext={verify}
          />
        ) : (
          <Spin />
        )
      ) : staged ? (
        <DiffInstructions
          {...props}
          before={item}
          after={staged}
          installer={installer[componentKey] as any}
          onFinish={finish}
        />
      ) : (
        <ConfigForm
          config={last ?? (item as any)}
          context={context}
          disabled={!canEdit}
          // TODO: Fix typing
          installer={installer[componentKey] as any}
          isFetching={props.isFetching}
          id={id}
          schema={component.schema}
          setConfig={stageConfig}
        />
      )}
    </>
  );
};
