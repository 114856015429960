import {
  CheckCircleFilled,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Empty,
  Grid,
  List,
  Modal,
  Select,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import { Heading } from "components/Heading";
import { Tenant } from "components/Login";
import { useAuthFetch } from "components/Login/hook";
import { format, formatDistance } from "date-fns";
import { FirestoreDoc, useFirestoreDoc } from "providers/FirestoreProvider";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  DEFAULT_WORKFLOW_CONFIG,
  WORKFLOW_CONFIGURATION_COLLECTION,
} from "shared/configuration/constant";
import { WorkflowConfiguration } from "shared/configuration/types";
import { sortApprovalOptions } from "shared/permission-requests/util";
import { PermissionRequest } from "shared/types/permission";
import {
  ActiveRequestStatuses,
  PendingRequestStatuses,
} from "shared/types/request-status";
import styled, { css } from "styled-components";

import { RequestPreview } from "../components/RequestPreview";
import { useRequests } from "../hooks/useRequests";
import { requestDescription } from "../requestUtils";

export const StyledListItem = styled(List.Item)<{ $isDesktop?: boolean }>`
  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      & .ant-list-item-action {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-left: 0;
        margin-top: 12px;
        max-width: 100%;

        > li {
          padding: 0;
        }
      }

      & .ant-list-item-action-split {
        display: none;
      }
    `}
`;

export const ActiveRequests: React.FC<{ hideHeader?: boolean }> = ({
  hideHeader,
}) => {
  // TODO: Filter these requests for what is relevant to the logged-in user.
  // Ie, show only the requests they made/they are able to approve.
  const activeRequests = useRequests(ActiveRequestStatuses);

  const pendingRequests = useRequests(PendingRequestStatuses);

  const { sm } = Grid.useBreakpoint();

  const [requestProcessing, setRequestProcessing] = useState(false);
  const [requestInModal, setRequestInModal] =
    useState<FirestoreDoc<PermissionRequest>>();
  const [approvalLength, setApprovalLength] = useState<string>();
  const authFetch = useAuthFetch(undefined, setRequestProcessing);

  const tenantId = useContext(Tenant);
  const optionsDoc = useFirestoreDoc<WorkflowConfiguration>(
    `o/${tenantId}/${WORKFLOW_CONFIGURATION_COLLECTION}/latest`,
    {
      live: true,
    }
  );
  const options = useMemo(() => {
    return (
      optionsDoc.doc?.data.options.sort(sortApprovalOptions) ||
      DEFAULT_WORKFLOW_CONFIG.options
    ).map((option) => ({
      value: `${option.time}${option.unit}`,
      label: option.value,
    }));
  }, [optionsDoc]);

  const handleApprove = useCallback(
    async (requestId: string) => {
      const response = await authFetch(
        `permission-requests/${requestId}/approve`,
        {
          method: "POST",
          json: {
            expirationLength: approvalLength || options[0].value,
          },
        }
      );

      if (response?.ok) {
        setRequestInModal(undefined);
        message.success("Request approved");
      } else {
        message.error("Failed to approve request");
      }
    },
    [approvalLength, options, authFetch]
  );

  const handleDeny = useCallback(
    async (requestId: string) => {
      const response = await authFetch(
        `permission-requests/${requestId}/deny`,
        {
          method: "POST",
        }
      );

      if (response?.ok) {
        message.success("Request denied");
      } else {
        message.error("Failed to deny request");
      }
    },
    [authFetch]
  );

  const handleRevoke = useCallback(
    async (requestId: string) => {
      const response = await authFetch(
        `permission-requests/${requestId}/revoke`,
        {
          method: "POST",
        }
      );

      if (response?.ok) {
        message.success("Access revoked");
      } else {
        message.error("Failed to revoke access");
      }
    },
    [authFetch]
  );

  return (
    <>
      {!hideHeader && <Heading title="Access Requests" />}
      <div style={{ maxWidth: 1000 }}>
        <Typography.Title level={4}>Active</Typography.Title>
        {activeRequests.loading ? (
          <Spin />
        ) : activeRequests.requests.length > 0 ? (
          <List
            dataSource={activeRequests.requests}
            renderItem={(item) => {
              return (
                <StyledListItem
                  $isDesktop={sm}
                  actions={[
                    item.data.expiryTimestamp && (
                      <Tooltip
                        key="expiry"
                        title={format(item.data.expiryTimestamp, "PPpp")}
                      >
                        <Typography.Text type="secondary">
                          Expires in{" "}
                          {formatDistance(
                            item.data.expiryTimestamp,
                            Date.now()
                          )}
                        </Typography.Text>
                      </Tooltip>
                    ),
                    <Tooltip
                      key="revoke"
                      title={
                        item.data.status !== "DONE_NOTIFIED" &&
                        "Request is currently being processed and cannot be revoked."
                      }
                    >
                      <Button
                        key="revoke"
                        type="default"
                        onClick={() => handleRevoke(item.id)}
                        loading={requestProcessing}
                        disabled={item.data.status !== "DONE_NOTIFIED"}
                      >
                        <MinusCircleOutlined /> Revoke
                      </Button>
                    </Tooltip>,
                  ]}
                >
                  <RequestPreview request={item.data} />
                </StyledListItem>
              );
            }}
            pagination={{
              pageSize: 10,
              size: "small",
              hideOnSinglePage: true,
            }}
          />
        ) : (
          <Empty description="No active requests" />
        )}
        <Typography.Title level={4}>Pending</Typography.Title>
        {pendingRequests.loading ? (
          <Spin />
        ) : pendingRequests.requests.length > 0 ? (
          <List
            dataSource={pendingRequests.requests}
            renderItem={(item) => {
              return (
                <StyledListItem
                  $isDesktop={sm}
                  actions={[
                    <Button
                      key="approve"
                      type="primary"
                      onClick={() => {
                        setRequestInModal(item);
                      }}
                    >
                      <CheckCircleFilled /> Approve
                    </Button>,
                    <Button
                      key="deny"
                      type="default"
                      onClick={() => handleDeny(item.id)}
                    >
                      <CloseCircleOutlined /> Deny
                    </Button>,
                  ]}
                >
                  <RequestPreview request={item.data} />
                </StyledListItem>
              );
            }}
            pagination={{
              pageSize: 10,
              size: "small",
              hideOnSinglePage: true,
            }}
          />
        ) : (
          <Empty description="No pending requests" />
        )}
      </div>

      <Modal
        title="Approve request"
        open={requestInModal !== undefined}
        onCancel={() => setRequestInModal(undefined)}
        footer={
          requestInModal && [
            <Button
              key="submit"
              type="primary"
              onClick={() => handleApprove(requestInModal.id)}
              loading={requestProcessing}
            >
              Approve
            </Button>,
          ]
        }
      >
        {requestInModal ? (
          <>
            <Typography.Paragraph>
              For how long would you like to grant{" "}
              <Typography.Text underline>
                {requestDescription(requestInModal.data)}
              </Typography.Text>{" "}
              to{" "}
              <Typography.Text code>
                {requestInModal.data.principal}
              </Typography.Text>
              ?
            </Typography.Paragraph>
            <Select
              style={{ width: "100%" }}
              defaultValue={options[0].value}
              options={options}
              onChange={(value) => setApprovalLength(value)}
            />
          </>
        ) : (
          <Typography.Paragraph>No request selected</Typography.Paragraph>
        )}
      </Modal>
    </>
  );
};
