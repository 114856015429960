import {
  Edge as FlowEdge,
  Node as FlowNode,
  ReactFlow,
  ReactFlowInstance,
} from "@xyflow/react";
import { Position } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Grid } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { ClipDiv, VerticalSpacedDiv } from "components/divs";
import { capitalize, uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Node } from "shared/graph/types";
import { AssessmentScopeIntegration } from "shared/types/assessment";
import {
  AnyNode,
  AssessmentNodes,
  NodeFor,
  TargetNodeTypes,
} from "shared/types/assessment/data";

import { NodeLabel, NodeText } from "./node/NodeText";

const BaseDimensions = {
  node: {
    defaultHeight: 60,
    width: 250, // pixels
  },
  spacing: {
    // Spacing between nodes
    x: 350,
    y: 80,
    // Additional spacing for node type clusters
    offsetX: 30,
    offsetY: 20,
  },
};

const toId = (node: Node<AssessmentNodes, keyof AssessmentNodes>) =>
  `${node.type}:${node.key}`;

const handles: NonNullable<FlowNode["handles"]> = [
  { x: 0, y: 0, position: Position.Right, type: "source" },
  { x: 0, y: 0, position: Position.Left, type: "target" },
];

const parentHandles = handles.filter((h) => h.type === "source");
const childHandles = handles.filter((h) => h.type === "target");

// SHORT TERM HACK:
// Link "authentication" nodes to either "credential" or "principal" depending on direction
// TODO: unify authentication + credential nodes
const linkableTypes: readonly string[] = ["authentication", ...TargetNodeTypes];
const linkItem = (current: AnyNode, node: NodeFor<keyof AssessmentNodes>) => {
  let type = node.type;
  let key = node.key;
  if (type === "authentication") {
    if (current.type === "principal") {
      type = "credential";
      key = node.parents[0].key;
    } else {
      type = "principal";
      key = node.children[0].key;
    }
  }
  return `${type}/${encodeURIComponent(key)}`;
};

const FlowNodeLabel = <K extends keyof AssessmentNodes>({
  current,
  node,
  integration,
  renderer,
}: {
  current: AnyNode;
  node: NodeFor<K>;
  integration: AssessmentScopeIntegration;
  renderer: ReturnType<typeof NodeText>;
}) => {
  const location = useLocation();
  const [search] = useSearchParams();

  const title = capitalize(NodeLabel(node, integration));

  const isCurrent = node.key === current.key && node.type === current.type;

  const to = useMemo(() => {
    const linkSearch = new URLSearchParams(search);
    linkSearch.set("show", node.type);
    const isMonitorPath = location.pathname.split("/")[5] === "monitors";
    // If this is a monitor, then navigation should direct to an explore page, as only
    // this current node can be rendered on this monitor finding page
    return `${isMonitorPath ? "../../../../explore" : "../.."}/${linkItem(
      current,
      node
    )}?${linkSearch.toString()}`;
  }, [search, location, current, node]);

  return (
    <VerticalSpacedDiv
      style={{
        justifyContent: "space-between",
        gap: "0",
        minHeight: "42px",
      }}
    >
      <div>
        {linkableTypes.includes(node.type) && !isCurrent ? (
          <Link to={to} relative="path">
            {title}
          </Link>
        ) : (
          title
        )}
      </div>
      <ClipDiv maxWidth={`${BaseDimensions.node.width}px`}>
        {renderer[node.type]?.(node) ?? (
          <GraphTooltip title={node.key}>{node.key}</GraphTooltip>
        )}
      </ClipDiv>
    </VerticalSpacedDiv>
  );
};

/** Visualizes a graph fragment for a node
 *
 * Renders only the local graph composed of the node and its nearest neighbors.
 *
 * The user can navigate to update the visualization to a neighbor by clicking on
 * the neighbor title link, in the case that neighbor is renderable (viz., if its
 * `type` appears in `TargetNodeTypes`).
 */
export const GraphVisualization = (props: {
  node: AnyNode;
  integration: AssessmentScopeIntegration;
}) => {
  const { node, integration } = props;
  const { md, lg } = Grid.useBreakpoint();
  const [flowInstance, setFlowInstance] = useState<ReactFlowInstance>();

  const dimensions = useMemo(
    () => ({
      ...BaseDimensions,
      view: {
        // Use the full limits of the viewport unless it is particularly small
        height: "max(500px, calc(100vh - 240px))",
        // Aligns width as sidebar + padding change
        width: `calc(100vw - ${lg ? "250px" : md ? "130px" : "110px"})`,
      },
      zoom: lg ? 1 : 0.75,
    }),
    [md, lg]
  );

  const nodes: FlowNode[] = useMemo(() => {
    const renderer = NodeText(node, integration, {
      detailed: true,
      hideThis: true,
    });
    const labelProps = { integration, renderer };
    const uniqueParentTypes = uniq(node.parents.map((p) => p.type));
    const uniqueChildTypes = uniq(node.children.map((c) => c.type));
    const commonProps = {
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      width: dimensions.node.width,
    };
    return [
      {
        id: toId(node),
        data: {
          label: <FlowNodeLabel current={node} node={node} {...labelProps} />,
        },
        position: { x: 0, y: 0 },
        ...commonProps,
      },
      ...node.parents.map<FlowNode>((p, ix) => {
        const typeIndex = uniqueParentTypes.indexOf(p.type);
        return {
          id: toId(p),
          data: {
            label: <FlowNodeLabel current={node} node={p} {...labelProps} />,
          },
          handles: parentHandles,
          position: {
            x: -dimensions.spacing.x + typeIndex * dimensions.spacing.offsetX,
            y:
              (ix - (node.parents.length - 1) / 2) * dimensions.spacing.y +
              typeIndex * dimensions.spacing.offsetY,
          },
          ...commonProps,
          targetPosition: Position.Right,
        };
      }),
      ...node.children.map<FlowNode>((c, ix) => {
        const typeIndex = uniqueChildTypes.indexOf(c.type);
        return {
          id: toId(c),
          data: {
            label: <FlowNodeLabel current={node} node={c} {...labelProps} />,
          },
          handles: childHandles,
          position: {
            x: dimensions.spacing.x + typeIndex * dimensions.spacing.offsetX,
            y:
              (ix - (node.children.length - 1) / 2) * dimensions.spacing.y +
              typeIndex * dimensions.spacing.offsetY,
          },
          ...commonProps,
          sourcePosition: Position.Left,
        };
      }),
    ];
  }, [dimensions, integration, node]);

  const edges: FlowEdge[] = [
    ...node.parents.map<FlowEdge>((p) => ({
      id: `${toId(p)}-${toId(node)}`,
      source: toId(p),
      target: toId(node),
    })),
    ...node.children.map<FlowEdge>((c) => ({
      id: `${toId(node)}-${toId(c)}`,
      target: toId(c),
      source: toId(node),
    })),
  ];

  // Ensure visualization is centered on focus node after graph changes (e.g. by navigation)
  useEffect(() => {
    flowInstance?.setCenter(
      dimensions.node.width / 2,
      dimensions.node.defaultHeight / 2,
      { zoom: dimensions.zoom }
    );
  }, [dimensions, flowInstance, nodes]);

  return (
    <div
      style={{
        height: dimensions.view.height,
        width: dimensions.view.width,
        backgroundColor: "rgb(250, 252, 255)",
        backgroundImage:
          // TODO: move to a stable location
          "radial-gradient(ellipse, transparent, #fafcff 70%), linear-gradient(130deg, transparent 75%, rgba(0, 135, 68, .26) 54%, rgba(0, 135, 68, .26) 60%, transparent 70%), linear-gradient(112deg, transparent 35%, rgba(145, 235, 0, .15) 43%, rgba(145, 235, 0, .15) 0, transparent 65%)",
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodesConnectable={false}
        onInit={setFlowInstance}
      />
    </div>
  );
};
