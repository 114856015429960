import { Reducers } from "../../graph/types";
import { AssessmentNodes } from "../../types/assessment/data";
import { CredentialAggregates } from "../../types/assessment/data/credential";
import { bindingAggregate, keyAggregate, permissionAggregate } from "./shared";

export const credentialReducers: Reducers<
  AssessmentNodes,
  CredentialAggregates
> = {
  permissions: permissionAggregate,
  authn: keyAggregate("authentication"),
  bindings: bindingAggregate,
  principal: keyAggregate("principal"),
  risks: keyAggregate("risk"),
};
