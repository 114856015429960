import { Button, Empty, Modal, Typography } from "antd";
import Spin from "antd/lib/spin";
import { Export } from "components/Export";
import { format } from "date-fns";
import React, { useCallback, useContext } from "react";
import { PermissionRequest } from "shared/types/permission";
import { RequestStatuses } from "shared/types/request-status";

import { PERMISSION_REQUESTS_COLLECTION } from "../../../firestore/constants";
import {
  FirestoreDoc,
  getFirestoreCollection,
} from "../../../providers/FirestoreProvider";
import { Heading } from "../../Heading";
import { useUser } from "../../Login/hook";
import { Tenant } from "../../Login/util";
import { useSandbox } from "../../Sandbox/hooks";
import { Requests } from "../components/Requests";
import { useRequests } from "../hooks/useRequests";
import {
  extractApproverName,
  getRequestEvents,
  requestDescription,
  statusDescriptions,
} from "../requestUtils";

const EXPORT_COLUMNS = [
  "id",
  "type",
  "description",
  "requestedBy",
  "approvedBy",
  "requestedTime",
  "approvedTime",
  "grantedTime",
  "expiryTime",
  "status",
  "status description",
  "reason",
];

const DETAILED_EXPORT_COLUMNS = [
  "id",
  "type",
  "description",
  "requestedBy",
  "approvedBy",
  "lastUpdatedTime",
  "status",
  "status description",
  "reason",
];

const createRow = (result: string, data: PermissionRequest, id: string) => {
  result += `${id}\t`;
  result += `${data.type}\t`;
  result += `"${requestDescription(data)}"\t`;
  const timestamp =
    data.approvalDetails?.approvedTimestamp ??
    // For legacy pre-approvalDetails requests
    (data.notifications?.slack as any)?.approvedTimestamp;
  result += [
    data.principal,
    extractApproverName(data),
    formatDateTimeForCsv(data.requestedTimestamp),
    timestamp ? formatDateTimeForCsv(timestamp) : "",
    data.grantTimestamp ? formatDateTimeForCsv(data.grantTimestamp) : "",
    data.expiryTimestamp ? formatDateTimeForCsv(data.expiryTimestamp) : "",
    data.status,
    `"${statusDescriptions[data.status](data)}"`,
    data.reason ?? "",
  ].join("\t");
  return result;
};

const formatDateTimeForCsv = (timestamp: number) => {
  const dateBase = new Date(timestamp);
  return format(dateBase, "yyyy-MM-dd HH:mm:ss");
};

const toTsv = async (
  requests: FirestoreDoc<PermissionRequest>[]
): Promise<string> => {
  let result = EXPORT_COLUMNS.join("\t") + "\n";
  requests.forEach(({ id, data }) => {
    result = createRow(result, data, id);
    result += "\n";
  });
  return result;
};

const toDetailedTsv =
  (tenantId: string) =>
  async (requests: FirestoreDoc<PermissionRequest>[]): Promise<string> => {
    let result = DETAILED_EXPORT_COLUMNS.join("\t") + "\n";
    const promises = [];
    for (const { id, data } of requests) {
      promises.push(
        (async () => {
          const logs = await getFirestoreCollection<PermissionRequest>(
            `o/${tenantId}/${PERMISSION_REQUESTS_COLLECTION}/${id}/logs`
          );

          const { events } = getRequestEvents(data, logs ?? []);
          if (logs.length === 0) {
            result = createRow(result, data, id);
            result += "\n";
          } else
            for (const { updates: logData, initialStatus } of events) {
              result += `${id}\t`;
              result += `${data.type}\t`;
              result += `"${requestDescription(data)}"\t`;
              result += [
                data.principal,
                extractApproverName(data),
                formatDateTimeForCsv(logData?.lastUpdatedTimestamp ?? 0),
                initialStatus,
                `"${statusDescriptions[initialStatus]({
                  ...data,
                  ...logData,
                })}"`,
                data.reason ?? "",
              ].join("\t");
              result += "\n";
            }
        })()
      );
    }
    await Promise.all(promises);
    return result;
  };

export const RequestHistory: React.FC = () => {
  const { tenant } = useUser();
  const { requestsVisited, visitRequests } = useSandbox(
    tenant.state === "found" && tenant.isSandbox
  );
  const tenantId = useContext(Tenant);

  const requestHistory = useRequests(RequestStatuses);

  const makeDetailedTsv = useCallback(
    (data: FirestoreDoc<PermissionRequest>[]) => toDetailedTsv(tenantId)(data),
    [tenantId]
  );

  return (
    <>
      <Heading
        title="Requests"
        cta={
          requestHistory.requests && (
            <Export
              data={requestHistory.requests}
              filename="p0-requests"
              primary={{
                label: "Export all requests",
                blob: toTsv,
                extension: "tsv",
              }}
              options={{
                detailed: {
                  label: "Detailed history",
                  blob: makeDetailedTsv,
                  extension: "tsv",
                },
              }}
            />
          )
        }
      />
      {requestHistory.loading ? (
        <Spin />
      ) : requestHistory.requests.length ? (
        <Requests requests={requestHistory.requests} />
      ) : (
        <Empty description="You don't have any requests yet." />
      )}
      <Modal
        open={requestsVisited === false}
        closable={false}
        footer={[
          <Button key="submit" type="primary" onClick={visitRequests}>
            Got it!
          </Button>,
        ]}
      >
        <Typography.Title level={3}>
          Review your request history
        </Typography.Title>
        <Typography.Paragraph>
          This page contains all the requests that your team has made using P0.
          You can review requests, see their individual process history, and
          export them to tsv files.
        </Typography.Paragraph>
        <Typography.Paragraph italic>
          Hint: Try clicking into a request details to see how it was handled.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Both requesting and responding to (approving or denying) requests
          happen 100% within Slack to keep your team in their normal tools.{" "}
          <em>
            Your team won&apos;t need to sign into this web app to handle their
            requests.
          </em>
        </Typography.Paragraph>
      </Modal>
    </>
  );
};
