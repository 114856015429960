import { Form, Input } from "antd";
import * as React from "react";

import { useCreateAccountPageStore } from "../../../store/createAccountStore";
import { CreateAccountButton, StyledOrgPointerText } from "./styles";

type Props = {
  handleCreateAccount: (orgSlug: string) => void;
};
const OrgInfoForm: React.FC<Props> = ({ handleCreateAccount }) => {
  const { isSubmittingInfo } = useCreateAccountPageStore();

  const handleFormSubmit = async ({ orgSlug }: { orgSlug: string }) => {
    handleCreateAccount(orgSlug);
  };

  return (
    <Form onFinish={handleFormSubmit} layout="vertical">
      <Form.Item
        name="orgSlug"
        rules={[
          {
            required: true,
            message: "Please enter your organization name",
          },
          {
            pattern: /^[a-zA-Z][\da-zA-Z-]{3,19}$/,
            message:
              "Org name should start with a letter, contain 4-20 chars, and can include letters, digits, or hyphens.",
          },
        ]}
        extra={
          <StyledOrgPointerText>
            This name will serve as the identifier for the P0 login app. Please
            select a name that your team can easily recognize.
          </StyledOrgPointerText>
        }
      >
        <Input placeholder="Enter your org name" size="large" />
      </Form.Item>
      <CreateAccountButton
        htmlType="submit"
        size="large"
        loading={isSubmittingInfo}
      >
        Create Account
      </CreateAccountButton>
    </Form>
  );
};

export default OrgInfoForm;
