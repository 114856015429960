import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Segmented,
  Tooltip,
  Typography,
  message,
} from "antd";
import { AppRoutes } from "components/App/routeConstants";
import { AuthFetch, useAuthFetch } from "components/Login/hook";
import { AuthzButton } from "components/common/AuthzButton";
import { deleteField, serverTimestamp, updateDoc } from "firebase/firestore";
import { compact } from "lodash";
import pluralize from "pluralize";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { AssessmentScope } from "shared/types/assessment";

import { Heading } from "../../Heading";
import { MonitorList } from "../components/MonitorList";
import {
  FrequencyAmount,
  FrequencyInputGroup,
  FrequencyInputs,
  FrequencyUnit,
} from "../components/NewAssessmentForm";
import { TargetsList } from "../components/Targets";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";
import { AssessmentSettingsSection } from "../styles";

const { Title, Paragraph } = Typography;

type AssessmentEditProps = {
  authFetch: AuthFetch;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  submitting: boolean;
  updateAssessment: (data: object) => Promise<void>;
};

export const Settings: React.FC = () => {
  const authFetch = useAuthFetch();
  const { assessment } = useContext(SelectedAssessmentContext);
  const assessmentData = assessment.doc?.data;
  const [submitting, setSubmitting] = useState(false);

  const updateAssessment = useCallback(
    async (data: object) => {
      if (!assessment.doc?.ref) return;

      setSubmitting(true);
      await updateDoc(assessment.doc?.ref, {
        ...data,
      });
      setSubmitting(false);
    },
    [assessment]
  );

  const editProps = {
    authFetch,
    setSubmitting,
    submitting,
    updateAssessment,
  };

  return (
    <>
      <Heading title="IAM Assessment Settings" />
      <EditNameSection {...editProps} />
      <EditSchedulingSection {...editProps} />
      <EditMonitorsSection />
      <EditAssignmentSection {...editProps} />
      {assessmentData?.targets && assessmentData?.targets.length > 0 ? (
        <EditTargetsSection targets={assessmentData.targets} />
      ) : (
        <Empty description="No targets configured" />
      )}
      <DangerZone />
    </>
  );
};

const EditNameSection: React.FC<AssessmentEditProps> = (props) => {
  const { assessment } = useContext(SelectedAssessmentContext);
  const { submitting, updateAssessment } = props;
  const [editing, setEditing] = useState(false);
  const [nameForm] = Form.useForm<{ name: string; editing: boolean }>();

  const toggleEdit = useCallback(() => setEditing(!editing), [editing]);

  const saveName = useCallback(async () => {
    await updateAssessment({ name: nameForm.getFieldValue("name") });
    setEditing(false);
  }, [nameForm, updateAssessment]);

  const name = assessment.doc?.data.name;

  return (
    <Form
      form={nameForm}
      initialValues={{ name }}
      style={{ marginBottom: "2em" }}
    >
      <Form.Item
        name={editing ? "name" : undefined}
        label="Name"
        style={{ marginBottom: "0.5em" }}
      >
        {editing ? <Input /> : name}
      </Form.Item>
      <Button.Group>
        {editing ? (
          <AuthzButton
            roles={["owner", "iamOwner"]}
            type="primary"
            onClick={saveName}
            loading={submitting}
          >
            Update
          </AuthzButton>
        ) : null}
        <AuthzButton
          roles={["owner", "iamOwner"]}
          type="default"
          onClick={toggleEdit}
        >
          {editing ? "Cancel" : "Edit"}
        </AuthzButton>
      </Button.Group>
    </Form>
  );
};

const EditSchedulingSection: React.FC<AssessmentEditProps> = (props) => {
  const { assessment } = useContext(SelectedAssessmentContext);
  const { authFetch, setSubmitting, submitting, updateAssessment } = props;

  const [editingSchedule, setEditingSchedule] = useState(false);

  const scheduleForm = useForm<FrequencyInputs>({
    defaultValues: {
      frequencyUnit: "days",
      frequencyAmount: 1,
    },
  });

  const onSubmitSchedulingForm: SubmitHandler<FrequencyInputs> = useCallback(
    async (data: FrequencyInputs) => {
      if (!assessment.doc) return;
      setSubmitting(true);
      const response = await authFetch(
        `assessment/${assessment.doc.id}/reschedule`,
        {
          method: "POST",
          json: {
            unit: data.frequencyUnit,
            amount: data.frequencyAmount,
          },
        }
      );
      if (response?.ok) {
        setEditingSchedule(false);
      }
      setSubmitting(false);
    },
    [assessment.doc, authFetch, setSubmitting]
  );

  const handleEditScheduling = useCallback(() => {
    if (editingSchedule) {
      scheduleForm.handleSubmit(onSubmitSchedulingForm)();
    } else {
      if (!editingSchedule && assessment.doc?.data.frequency) {
        scheduleForm.setValue(
          "frequencyUnit",
          assessment.doc.data.frequency.unit
        );
        scheduleForm.setValue(
          "frequencyAmount",
          assessment.doc.data.frequency.amount
        );
      }
      setEditingSchedule(true);
    }
  }, [editingSchedule, scheduleForm, onSubmitSchedulingForm, assessment.doc]);

  const enableScheduledRuns = useCallback(() => {
    if (!assessment.hasSchedule) {
      return;
    }
    updateAssessment({
      "frequency.disabled": deleteField(),
    });
  }, [assessment.hasSchedule, updateAssessment]);

  const disableScheduledRuns = useCallback(() => {
    if (!assessment.hasSchedule) {
      return;
    }
    // TODO: fix with migration
    const legacyEnableUpdates = assessment.doc?.data.frequency?.anchorDate
      ? {}
      : { "frequency.anchorDate": serverTimestamp() };
    updateAssessment({
      ...legacyEnableUpdates,
      "frequency.disabled": true,
      "frequency.runCount": 0,
    });
  }, [assessment.hasSchedule, assessment.doc, updateAssessment]);

  const scheduleText = useMemo(() => {
    const frequency = assessment.doc?.data.frequency;
    return frequency
      ? assessment.isScheduled
        ? `Runs every ${frequency.amount} ${pluralize(
            frequency.unit,
            frequency.amount
          )}.`
        : `Configured to run every ${frequency.amount} ${pluralize(
            frequency.unit,
            frequency.amount
          )}. Not currently running.`
      : "This assessment is not scheduled.";
  }, [assessment]);

  return (
    <AssessmentSettingsSection>
      <Title level={3}>Scheduling</Title>
      {editingSchedule ? (
        <form
          onSubmit={scheduleForm.handleSubmit(onSubmitSchedulingForm)}
          style={{ maxWidth: "500px" }}
        >
          <FrequencyInputGroup>
            <Controller
              name="frequencyAmount"
              control={scheduleForm.control}
              render={({ field: { ref: _ref, ...field } }) => (
                <FrequencyAmount {...field} />
              )}
            />
            <Controller
              name="frequencyUnit"
              control={scheduleForm.control}
              render={({ field: { ref: _ref, ...field } }) => (
                <FrequencyUnit {...field} />
              )}
            />
          </FrequencyInputGroup>
        </form>
      ) : (
        <Paragraph>{scheduleText}</Paragraph>
      )}
      <Paragraph>
        <Button.Group>
          <AuthzButton
            roles={["owner", "iamOwner"]}
            type="default"
            onClick={handleEditScheduling}
            loading={submitting}
          >
            {editingSchedule ? "Update" : "Edit"}
          </AuthzButton>
          {editingSchedule && (
            <Button onClick={() => setEditingSchedule(false)}>Cancel</Button>
          )}
          {!editingSchedule && !assessment.isScheduled && (
            <AuthzButton
              roles={["owner", "iamOwner"]}
              type="primary"
              onClick={enableScheduledRuns}
            >
              Enable
            </AuthzButton>
          )}
          {!editingSchedule && assessment.isScheduled && (
            <Paragraph>
              <AuthzButton
                roles={["owner", "iamOwner"]}
                type="default"
                danger
                onClick={disableScheduledRuns}
              >
                Disable
              </AuthzButton>
            </Paragraph>
          )}
        </Button.Group>
      </Paragraph>
    </AssessmentSettingsSection>
  );
};

const EditMonitorsSection: React.FC = () => {
  const [showArchived, setShowArchived] = useState(false);
  const actionTypes = useMemo(() => {
    return showArchived ? ["edit", "unarchive"] : ["edit", "archive"];
  }, [showArchived]);

  const handleArchiveToggle = useCallback((value: number | string) => {
    setShowArchived(value === "archived");
  }, []);

  return (
    <AssessmentSettingsSection>
      <Title level={3}>Monitors</Title>
      <Segmented
        options={[
          { label: "Active", value: "active" },
          { label: "Archived", value: "archived" },
        ]}
        onChange={handleArchiveToggle}
      />
      <MonitorList
        actionTypes={actionTypes}
        showFindings={false}
        customOnly
        showArchived={showArchived}
      />
    </AssessmentSettingsSection>
  );
};

const EditAssignmentSection: React.FC<AssessmentEditProps> = (props) => {
  const { updateAssessment } = props;
  const { assessment } = useContext(SelectedAssessmentContext);

  const contactInfoTitle = useMemo(
    () =>
      compact([
        assessment.doc?.data.targets.find((t) => t.integration === "gcloud") &&
          "For GCP, this is the project's technical contact.",
        assessment.doc?.data.targets.find((t) => t.integration === "aws") &&
          "For AWS, this is the account's operations contact.",
      ]).join("\n"),
    [assessment.doc]
  );

  const saveAssignmentValue = useCallback(
    async (event: RadioChangeEvent) => {
      const { value } = event.target;
      if (value === "contact" || value === "manual") {
        await updateAssessment({ assignBy: { type: value } });
      }
    },
    [updateAssessment]
  );

  return assessment.doc ? (
    <AssessmentSettingsSection>
      <Title level={3}>Assignment</Title>
      <Paragraph>
        Determines how issue owners are determined when you assign findings.
      </Paragraph>
      <Radio.Group
        defaultValue={assessment.doc.data.assignBy?.type ?? "manual"}
        onChange={saveAssignmentValue}
        style={{ display: "flex", flexDirection: "column", gap: "12px" }}
      >
        {/* Explicitly leaving out VCS and tag strategies for now as those are experimental */}
        <Radio value="manual">Manually</Radio>
        <Radio value="contact">
          Resource contact{" "}
          <Tooltip title={contactInfoTitle}>
            <InfoCircleOutlined />
          </Tooltip>
        </Radio>
      </Radio.Group>
    </AssessmentSettingsSection>
  ) : null;
};

const EditTargetsSection: React.FC<{
  targets: AssessmentScope[];
}> = ({ targets }) => (
  <AssessmentSettingsSection>
    <Title level={3}>Scope</Title>
    <Paragraph>This assessment will run on the following:</Paragraph>
    <TargetsList targets={targets} />
  </AssessmentSettingsSection>
);

const DangerZone: React.FC = () => {
  const authFetch = useAuthFetch();
  const navigate = useNavigate();
  const { orgSlug } = useParams();
  const { assessment } = useContext(SelectedAssessmentContext);
  const [deleting, setDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    const response = await authFetch(`assessment/${assessment.doc?.id}`, {
      method: "DELETE",
    });
    if (response?.ok) {
      message.success("Assessment deleted");
      navigate(`/o/${orgSlug}/${AppRoutes.IamAssessment}`);
    } else {
      message.error("Failed to delete assessment");
    }
    setDeleting(false);
  }, [assessment.doc?.id, authFetch, navigate, orgSlug]);

  return (
    <AssessmentSettingsSection>
      <Title level={3}>Danger Zone</Title>
      <Paragraph>
        <AuthzButton
          roles={["owner", "iamOwner"]}
          type="default"
          danger
          onClick={() => setShowModal(true)}
        >
          Delete Assessment
        </AuthzButton>
      </Paragraph>
      <Modal
        open={showModal}
        footer={
          <>
            <Button type="default" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              type="primary"
              danger
              onClick={handleDelete}
              loading={deleting}
            >
              Delete Assessment
            </Button>
          </>
        }
      >
        <Title level={4}>Delete Assessment</Title>
        <Paragraph>
          Are you sure you want to delete this assessment? This action cannot be
          undone.
        </Paragraph>
      </Modal>
    </AssessmentSettingsSection>
  );
};
