import { GraphColumnType } from "components/GraphTable/GraphTable";
import { ClipDiv } from "components/divs";
import { PrincipalNode } from "shared/types/assessment/data";

import {
  StaleCredentialAggregate,
  UsedCredentialAggregate,
} from "../../cells/Credential";
import { CredentialTitle } from "../../cells/CredentialTitle";
import { LastAuthenticated } from "../../cells/LastAuthenticated";
import { MfaCell, MfaTitle } from "../../cells/Mfa";
import { PrincipalCell, principalCellValue } from "../../cells/Principal";
import { RiskAggregate, riskSorter } from "../../cells/RiskAggregate";
import { genericExport } from "../../export";
import { numberSorter, stringSorter } from "../../sort";
import { AssessmentColumnProps } from "../columns";
import { credentialExportFromPrincipal } from "./PrincipalNode";

const replaceNaN = (value: number, fallback: number) =>
  isNaN(value) ? fallback : value;

export const principalColumns = (
  props: AssessmentColumnProps
): GraphColumnType<PrincipalNode>[] => [
  {
    key: "principal",
    title: "Principal",
    sorter: (left, right) => stringSorter(left.key, right.key),
    render: (_, node) => (
      <ClipDiv>
        <PrincipalCell principalData={node.data} {...props} />
      </ClipDiv>
    ),
    export: genericExport((node) =>
      principalCellValue(node.data, props.integration)
    ),
  },
  {
    key: "lastAuthnTime",
    title: "Last Used",
    sorter: (left, right) =>
      // Ordering is reversed because it looks back in time
      numberSorter(
        replaceNaN(right.aggregates.lastAuthEvent, 0),
        replaceNaN(left.aggregates.lastAuthEvent, 0)
      ),
    render: (_, node) => (
      <ClipDiv>
        <LastAuthenticated
          principal={node.data}
          last={node.aggregates.lastAuthEvent}
        />
      </ClipDiv>
    ),
    export: genericExport((node) => node.aggregates.lastAuthEvent),
  },

  {
    key: "credentials",
    title: <CredentialTitle />,
    render: (_, node) => (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "1em",
        }}
      >
        <UsedCredentialAggregate
          credentials={node.aggregates.usedCredentials}
          {...props}
        />
        <StaleCredentialAggregate
          credentials={node.aggregates.staleCredentials}
          {...props}
        />
      </div>
    ),
    export: genericExport(credentialExportFromPrincipal),
  },
  {
    key: "mfa",
    title: <MfaTitle />,
    sorter: (left, right) =>
      stringSorter(left.data.mfa ?? "", right.data.mfa ?? ""),
    render: (_, node) => <MfaCell node={node} {...props} />,
    export: genericExport((node) => node.data.mfa),
  },
  {
    key: "grant",
    title: "Grants",
    sorter: (left, right) =>
      numberSorter(
        left.aggregates.bindings.length,
        right.aggregates.bindings.length
      ),
    render: (_, node) => node.aggregates.bindings.length,
    export: genericExport((node) => node.aggregates.bindings),
  },
  {
    key: "risk",
    title: "Risks",
    sorter: (left, right) =>
      riskSorter(left.aggregates.risks, right.aggregates.risks),
    render: (_, node) => <RiskAggregate risks={node.aggregates.risks} />,
    export: genericExport((node) => node.aggregates.risks),
  },
];
