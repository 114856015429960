import { Element, InstallSpec, ItemComponent } from "../../../install/types";

const iamAssessmentDescription =
  "Allows P0 to analyze your IAM configuration for security issues.";

const GCLOUD_PROJECT_PATTERN = /^[A-Za-z0-9-]+$/;
const GCLOUD_ORGANIZATION_PATTERN = /^[0-9]+$/;

const gcloudProjectValidator = async (id: string, _field: string) => {
  if (!id.match(GCLOUD_PROJECT_PATTERN))
    return "Google Cloud project identifiers must consist of alphanumeric characters and hyphens only";
};

const gcloudOrganizationValidator = async (_id: string, field: string) => {
  if (!field.match(GCLOUD_ORGANIZATION_PATTERN))
    return "Google Cloud organization identifiers must consist of numerals only";
};

export const GcloudComponents = InstallSpec({
  "access-logs": ItemComponent({
    label: "Access logging",
    description:
      "Enhance IAM assessments with continuous usage data from your projects.",
    type: "dynamic",
    schema: {
      "pre-20240729-pubsub-ingest": Element({
        type: "hidden",
        label: "Legacy Pub/Sub ingest table name",
      }),
    },
  }),
  "iam-assessment": ItemComponent({
    label: "IAM assessment",
    description: iamAssessmentDescription,
    type: "string",
    schema: {},
    validator: gcloudProjectValidator,
  }),
  "iam-write": ItemComponent({
    label: "IAM management",
    description:
      "Allows P0 to grant and revoke Google Cloud access for your users.",
    type: "string",
    schema: {},
    validator: gcloudProjectValidator,
  }),
  "org-wide-policy": ItemComponent({
    label: "Organization policies",
    description:
      "Allows P0 to analyze your organization and folder IAM policies for security issues.",
    type: "singleton",
    schema: {},
  }),
  "sharing-restriction": ItemComponent({
    label: "Domain-restricted sharing",
    description: "Prevent privilege escalation via P0's integration.",
    type: "dynamic",
    schema: {},
  }),
  "org-access-logs": ItemComponent({
    label: "Organization access logging",
    description:
      "Enhance IAM assessments with continuous usage data from your entire organization.",
    type: "singleton",
    schema: {
      "pre-20240729-pubsub-ingest": Element({
        type: "hidden",
        label: "Legacy Pub/Sub ingest table name",
      }),
    },
  }),
  "org-iam-assessment": ItemComponent({
    label: "Organization IAM assessment",
    description: `${iamAssessmentDescription} Installed for every project in your organization.`,
    type: "singleton",
    schema: {},
  }),
  root: ItemComponent({
    label: "Google Cloud organization",
    type: "singleton",
    schema: {
      organizationId: Element({
        label: "Organization ID",
        type: "string",
        validator: gcloudOrganizationValidator,
      }),
      serviceAccountEmail: Element({
        label: "Service account",
        type: "hidden",
      }),
      impersonateViaEmail: Element({
        label: "Intermediate service account",
        description:
          "If present, service account to impersonate prior to impersonating serviceAccountEmail",
        type: "hidden",
      }),
    },
  }),
});
