import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Divider, Tabs, Typography } from "antd";
import { isEqual } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  headingsFromComponentType,
  ruleComponents,
} from "shared/types/workflow/constants";
import { RoutingRule } from "shared/types/workflow/types";

import { RoutingRuleEditorContext } from "../store/RoutingRuleEditorContext";
import { AccessEditor } from "./editors/AccessEditor";
import { RequestorEditor } from "./editors/RequestorEditor";
import { ResourceEditor } from "./editors/ResourceEditor";

type Props = {
  initialRule?: RoutingRule;
  handleSubmitRule: (rule: RoutingRule) => void;
};

export const RuleEditor: React.FC<Props> = ({
  initialRule,
  handleSubmitRule,
}) => {
  const {
    resource,
    requestor,
    approval,
    setResource,
    setRequestor,
    setApproval,
    isComponentValid,
    isValidRule,
  } = useContext(RoutingRuleEditorContext);

  useEffect(() => {
    if (initialRule) {
      setResource(initialRule.resource);
      setRequestor(initialRule.requestor);
      setApproval(initialRule.approval);
    }
  }, [initialRule, setResource, setRequestor, setApproval]);

  const isNew = useMemo(() => initialRule === undefined, [initialRule]);

  const isDirty = useMemo(
    () =>
      !isEqual(initialRule, {
        requestor,
        resource,
        approval,
      }),
    [requestor, resource, approval, initialRule]
  );

  const enableSaveButton = useMemo(
    () => isValidRule && (isNew || isDirty),
    [isNew, isValidRule, isDirty]
  );

  const submitRule = useCallback(() => {
    // Technically "isValidRule" also does the "!" check, but TS doesn't know that in this file, so we need to do it again.
    if (!isValidRule || !resource || !requestor) {
      return;
    }
    handleSubmitRule({
      requestor,
      resource,
      approval,
    });
  }, [requestor, handleSubmitRule, resource, approval, isValidRule]);

  const createRuleComponentLabel = useCallback(
    (component: (typeof ruleComponents)[number]) => {
      return (
        <span>
          {isComponentValid(component) ? (
            <CheckCircleFilled />
          ) : (
            <ExclamationCircleOutlined />
          )}
          {headingsFromComponentType[component]}
        </span>
      );
    },
    [isComponentValid]
  );

  return (
    <>
      <Tabs
        tabPosition="left"
        items={ruleComponents.map((component) => ({
          key: component,
          children:
            component === "resource" ? (
              <ResourceEditor />
            ) : component === "requestor" ? (
              <RequestorEditor />
            ) : (
              <AccessEditor />
            ),
          label: createRuleComponentLabel(component),
        }))}
      />
      <Divider />
      <div style={{ textAlign: "right" }}>
        {isDirty && (
          <Typography.Text type="secondary" style={{ marginRight: 10 }}>
            Unsaved changes
          </Typography.Text>
        )}
        <Button
          type="primary"
          onClick={submitRule}
          disabled={!enableSaveButton}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
