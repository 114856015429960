import { Segmented, Typography } from "antd";
import { uniq } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { isNode } from "shared/graph/types";
import { AssessmentScopeBase } from "shared/types/assessment";
import { BindingAggregates, Usage } from "shared/types/assessment/data";
import { RiskScore } from "shared/types/catalog";

import { Cascade, CascadeNode } from "../../../Cascade";
import { CatalogContext } from "../../../Catalog/context";
import { SearchInput } from "../../../antd";
import { CountAggregate } from "./Aggregate";
import { PermissionLink } from "./PermissionLink";
import { RiskLink } from "./RiskLink";
import { HasAddTerm } from "./ShowHide";

const USAGES = ["used", "unused", "unknown"] as const;
const NO_KNOWN_RISK = "no-risk";

export const RiskPriority: Record<RiskScore, number> = {
  CRITICAL: 0,
  HIGH: 1,
  MEDIUM: 2,
  BOOST: 3,
  EVASION: 4,
  LOW: 5,
};

export const PermissionAggregate = ({
  permissions,
  onAddTerm,
  terms,
}: { permissions: BindingAggregates["permissions"] } & HasAddTerm) =>
  CountAggregate({
    inputMap: permissions,
    typeOptions: USAGES,
    onAddTerm,
    terms,
    termName: "permissions",
    getSearchTerm: (val) => `${val}:`,
  });

export const RiskGroupedPermissionList: React.FC<{
  permissions: BindingAggregates["permissions"];
  integration: AssessmentScopeBase["integration"];
  initialSelected?: Usage;
}> = ({ permissions, initialSelected, integration }) => {
  const [selected, setSelected] = useState<number | string>(
    initialSelected ?? "unused"
  );
  const { risks } = useContext(CatalogContext);
  const [where, setWhere] = useState<string>();

  const onWhere = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setWhere(event.target.value),
    []
  );

  const shown = useMemo(
    () =>
      [...(permissions[selected as Usage] ?? [])].filter((k) =>
        k.key.includes(where ?? "")
      ),
    [permissions, selected, where]
  );

  const shownRisks = useMemo(() => {
    const risks: Record<string, string[]> = {};
    for (const s of shown) {
      const theseRisks = s.children.filter(isNode("risk"));
      if (theseRisks.length) {
        for (const riskNode of theseRisks) {
          risks[riskNode.key] ||= [];
          risks[riskNode.key].push(s.key);
        }
      } else {
        risks[NO_KNOWN_RISK] ||= [];
        risks[NO_KNOWN_RISK].push(s.key);
      }
    }
    return risks;
  }, [shown]);

  const options = useMemo(
    () => [
      {
        label: `Unused (${permissions.unused?.length ?? 0})`,
        value: "unused",
      } as const,
      {
        label: `Known used (${permissions.used?.length ?? 0})`,
        value: "used",
      } as const,
      {
        label: `Potentially used (${permissions.unknown?.length ?? 0})`,
        value: "unknown",
      } as const,
    ],
    [permissions]
  );

  const tree: CascadeNode[] = useMemo(
    () =>
      Object.entries(shownRisks).map(([r, perms]) => {
        const risk = risks[r];
        return {
          label:
            r === NO_KNOWN_RISK ? (
              <Typography.Text type="secondary">
                (no known risks)
              </Typography.Text>
            ) : (
              <RiskLink risk={risk} />
            ),
          key: r,
          sortValue:
            r === NO_KNOWN_RISK
              ? "7"
              : `${RiskPriority[risk.score] ?? 6}:${risk.name}`,
          children: uniq(perms).map((p) => ({
            label: <PermissionLink permission={p} integration={integration} />,
            key: p,
            children: [],
            sortValue: p,
          })),
        };
      }),
    [risks, shownRisks, integration]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.7em",
        alignItems: "flex-start",
      }}
    >
      <Segmented options={options} value={selected} onChange={setSelected} />
      <SearchInput
        prefix="🔎"
        placeholder="Search permissions"
        onChange={onWhere}
      />
      <Cascade tree={tree} height={228} />
    </div>
  );
};
