import { WarningTwoTone } from "@ant-design/icons";
import { Alert, Button, Tabs, TabsProps, message } from "antd";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";

import { Heading } from "../Heading";
import { useAuthFetch } from "../Login/hook";
import { RunHistory } from "./RunHistory";
import { SnowflakeRestStateConfigEditor } from "./SnowflakeRestStateConfigEditor";
import { CONFIG_URL } from "./config";

const DriftCheckButton = styled(Button)`
  float: right;
`;
const items: TabsProps["items"] = [
  {
    key: "config",
    label: `Configuration`,
    children: <SnowflakeRestStateConfigEditor />,
  },
  {
    key: "history",
    label: `Run History`,
    children: <RunHistory />,
  },
];

export const SnowflakeRestState: React.FC<object> = () => {
  const navigate = useNavigate();
  const { source } = useParams();
  const [_errors, setErrors] = useState<string[]>([]);
  const setSingleError = useCallback(
    (error: string | undefined) => setErrors(error ? [error] : []),
    [setErrors]
  );
  const authFetch = useAuthFetch(setSingleError);
  const handleDriftCheck = useCallback(async () => {
    const response = await authFetch(`${CONFIG_URL}/diff`, {
      method: "POST",
    });
    if (!response) return;
    message.success("Your drift check is now running.");
    navigate(`../history`);
  }, [authFetch, navigate]);
  return (
    <>
      <Alert
        type="warning"
        message="This feature is currently only available for Snowflake. Additional integrations coming later."
        style={{ marginBottom: "20px" }}
        icon={<WarningTwoTone twoToneColor="#faad14" />}
        showIcon
      />
      <Heading
        title="Snowflake IAM"
        cta={
          <DriftCheckButton type="primary" onClick={handleDriftCheck}>
            Run Drift Check
          </DriftCheckButton>
        }
      />
      <Tabs
        activeKey={source}
        onChange={(path) => {
          navigate(`../${path}`);
        }}
        items={items}
      />
    </>
  );
};
