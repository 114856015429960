import { Button, Select, Spin, Typography } from "antd";
import { ErrorDisplay } from "components/Error";
import { Tenant } from "components/Login";
import { AuthFetch, useAuthFetch, useHasRole } from "components/Login/hook";
import { VerticalSpacedDiv } from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { jwtDecode } from "jwt-decode";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  JiraIntegration,
  JiraProject,
} from "shared/integrations/trackers/jira/types";
import { staticError } from "utils/console";

import * as firestoreProvider from "../../../providers/FirestoreProvider";
import { IntegrationCard } from "../IntegrationCard";
import { Logo } from "../Logo";
import { OwnerAlert } from "../OwnerAlert";

const { Link } = Typography;

// See usage restrictions at https://www.atlassian.com/legal/trademark#partners-and-vendors
export const JiraIconUrl =
  "https://wac-cdn.atlassian.com/dam/jcr:fa01756d-6dcc-45d1-83ab-696fbfeb074f/Jira-icon-blue.svg?cdnVersion=1571";

export const JiraIcon = <Logo logo={JiraIconUrl} title="Jira" size={14} />;

const JiraProjectSelector: React.FC<{
  config: JiraIntegration;
  authFetch: AuthFetch;
  isFetching: boolean;
}> = ({ authFetch, config, isFetching }) => {
  const [projects, setProjects] = useState<JiraProject[]>();
  const canRefresh = useHasRole("owner");

  useGuardedEffect(
    async (cancellation) => {
      if (!canRefresh) return;
      const response = await authFetch("integrations/jira/projects", {
        method: "GET",
      });
      if (!response) return;
      const { projects } = await response.json();
      cancellation.guard(setProjects)(projects);
    },
    staticError,
    [authFetch]
  );

  const options = useMemo(
    () => projects?.map((p) => ({ value: p.id, label: p.name })),
    [projects]
  );

  const onSelect = useCallback(
    async (id: string) => {
      const project = projects?.find((p) => p.id === id);
      if (!project) return;
      await authFetch("integrations/jira/config/project", {
        method: "PUT",
        json: project,
      });
    },
    [authFetch, projects]
  );

  return projects && !isFetching ? (
    !projects.length ? (
      <>Please create a project in your Jira instance to continue</>
    ) : (
      <>
        <div>Select the project where P0 will create issues:</div>
        <div>
          <Select
            disabled={!canRefresh}
            onChange={onSelect}
            options={options}
            placeholder="Select a project"
            style={{ minWidth: "20em" }}
            value={config.project?.id}
          />
        </div>
      </>
    )
  ) : (
    <Spin />
  );
};

export const Jira: React.FC<object> = () => {
  const tenantId = useContext(Tenant);
  const integrationData = firestoreProvider.useFirestoreDoc<JiraIntegration>(
    `o/${tenantId}/integrations/jira`,
    { live: true }
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<any>();
  const authFetch = useAuthFetch(setError, setIsFetching);
  const canInstall = useHasRole("owner");

  const jiraUrl = searchParams.get("jiraUrl");

  // Note that a phisher could presumably spoof a jwt here that wasn't signed by
  // Atlassian. However, we also validate the jwt against the Jira
  // installation's private key, so this would just lead to a backend API error.
  const jwt = searchParams.get("jwt");
  const decoded = useMemo(() => {
    if (!jwt) return undefined;
    return jwtDecode(jwt);
  }, [jwt]);

  // If we've successfully installed, remove the input query params from Jira,
  // these are no longer needed
  useEffect(() => {
    if (integrationData.doc && (jwt || jiraUrl)) {
      setSearchParams(new URLSearchParams());
    }
  }, [integrationData.doc, jiraUrl, jwt, setSearchParams]);

  const installJira = useCallback(async () => {
    await authFetch(`integrations/jira/install`, {
      method: "POST",
      json: { jwt, jiraUrl },
    });
  }, [authFetch, jiraUrl, jwt]);

  const removeIntegration = useCallback(async () => {
    const response = await authFetch("integrations/jira/config", {
      method: "DELETE",
    });
    if (!response) return;
  }, [authFetch]);

  return (
    <IntegrationCard
      integration="jira"
      logo={JiraIconUrl}
      onRemove={removeIntegration}
    >
      {error && <ErrorDisplay title="Error in Jira update" error={error} />}
      {integrationData.doc ? (
        <VerticalSpacedDiv>
          <div>Installed on {integrationData.doc.data.jira.displayUrl}</div>
          <JiraProjectSelector
            config={integrationData.doc.data}
            authFetch={authFetch}
            isFetching={isFetching}
          />
        </VerticalSpacedDiv>
      ) : decoded && jiraUrl ? (
        <VerticalSpacedDiv>
          <div>
            Connecting P0 to{" "}
            <Link href={jiraUrl} target="_blank" rel="noopener">
              {jiraUrl}
            </Link>
          </div>
          {canInstall ? (
            <div>
              <Button type="primary" onClick={installJira} loading={isFetching}>
                Finish installation
              </Button>
            </div>
          ) : (
            <OwnerAlert />
          )}
        </VerticalSpacedDiv>
      ) : (
        <>Please install P0 using the Atlassian Marketplace</>
      )}
    </IntegrationCard>
  );
};
