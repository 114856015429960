import { DatabaseFilled } from "@ant-design/icons";
import { Alert, Descriptions, Divider, Spin, message } from "antd";
import { Expando } from "components/Assessment/components/Expando";
import { FirstPlus } from "components/FirstPlus";
import { Timestamp } from "firebase/firestore";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { identity } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Cancellation } from "utils/cancel";

import { useFirestoreDoc } from "../../providers/FirestoreProvider";
import { LogoBreadcrumb } from "../Breadcrumb";
import { Logo } from "../Integrations/Logo";
import { SnowflakeIconUrl } from "../Integrations/Snowflake/Snowflake";
import { Tenant } from "../Login";
import { useAuthFetch } from "../Login/hook";
import Loader from "../common/Loader";
import { DriftCheckVisualizer } from "./components/DriftCheckVisualizer";
import { DriftEnforcementVisualizer } from "./components/DriftEnforcementVisualizer";
import { RunSummary } from "./components/RunSummary";
import { CONFIG_URL } from "./config";
import { SnowflakeAPIRunDocument } from "./types";

const runBreadCrumbs = (slug: string, runId: string, time: Timestamp) => {
  return [
    {
      label: "Snowflake",
      to: `/o/${slug}/rest-state/snowflake/history`,
      icon: <DatabaseFilled style={{ color: "#1890ff" }} />,
    },
    {
      label: `Drift ${time.toDate().toLocaleString()}`,
      icon: <Logo logo={SnowflakeIconUrl} title="Snowflake Anti-Entropy" />,
      to: `/o/${slug}/rest-state/run/${runId}`,
    },
  ];
};

export const RunDetails: React.FC = () => {
  const tenantId = useContext(Tenant);

  const { orgSlug, runId } = useParams<{ orgSlug: string; runId: string }>();
  const [doc, setDoc] = useState<SnowflakeAPIRunDocument | undefined>(
    undefined
  );
  const [_errors, setErrors] = useState<string[]>([]);
  const setSingleError = useCallback(
    (error: string | undefined) => setErrors(error ? [error] : []),
    [setErrors]
  );
  const authFetch = useAuthFetch(setSingleError);
  // read the status doc from firebase to listen for changes on status
  // this is used to refresh the page when the status changes as the document
  // is now fetched using api(data in cloud storage)
  const statusDoc = useFirestoreDoc<SnowflakeAPIRunDocument>(
    `/o/${tenantId}/rest-state/integrations/snowflake/config/diffs/${runId}`,
    {
      live: true,
    }
  );
  const { status, type, createdTimestamp } = useMemo(() => {
    setDoc(undefined);
    return statusDoc.doc?.data || {};
  }, [statusDoc.doc?.data]);
  useGuardedEffect(
    async (cancellation: Cancellation) => {
      if (doc) return;
      const response = await authFetch(`${CONFIG_URL}/diff/${runId}`, {
        method: "GET",
        onNotOk(response) {
          if (response.status === 403) message.error("Unauthorized");
          else message.error("Something went wrong");
        },
      });
      if (!response) return;
      const data = await response.json();
      cancellation.guard(setDoc)(data);
    },
    setSingleError,
    [authFetch, runId, tenantId, status, type]
  );

  const handleDriftEnforce = useCallback(
    async (runId: string) => {
      const response = await authFetch(`${CONFIG_URL}/diff/${runId}/enforce`, {
        method: "POST",
        onNotOk(response) {
          if (response.status === 403) message.error("Unauthorized");
          else message.error("Something went wrong");
        },
      });
      if (!response) return;
      message.success("Your drift enforcement is now running.");
    },
    [authFetch]
  );
  const warnings = useMemo(
    () =>
      [
        ...(doc?.check?.actual?.warnings ?? []),
        ...(doc?.check?.desired?.warnings ?? []),
      ].map((warning: string, id: number) => (
        <Alert
          type="warning"
          message={warning}
          key={`drift-check-warning-${id}`}
          style={{ marginBottom: "10px" }}
        />
      )),
    [doc]
  );
  return (
    <div>
      {orgSlug && runId && doc ? (
        <>
          <LogoBreadcrumb
            items={runBreadCrumbs(orgSlug, runId, createdTimestamp)}
          />
          <Divider />
          <RunSummary doc={doc} createdTimestamp={createdTimestamp} />

          {warnings.length > 0 && (
            <>
              <Divider />
              <Descriptions title="Warnings">
                <Descriptions.Item label="Drift Check">
                  <Expando
                    items={[...warnings]}
                    header={<FirstPlus items={warnings} first={identity} />}
                  />
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
          <Divider />
          {doc.type === "CHECK" &&
            doc.status !== "PROCESSING" &&
            doc.status !== "ERRORED" &&
            doc.status !== "ERRORED_ERRORED" &&
            !!doc.check && (
              <DriftCheckVisualizer
                runId={runId}
                handler={handleDriftEnforce}
                doc={doc.check}
              />
            )}
          {doc.type === "ENFORCE" &&
            doc.status !== "PROCESSING" &&
            doc.status !== "ERRORED" &&
            doc.status !== "ERRORED_ERRORED" &&
            !!doc.enforce && <DriftEnforcementVisualizer doc={doc.enforce} />}
          {doc.status === "PROCESSING" && <Spin />}
          {doc.status === "ERRORED" ||
            (doc.status === "ERRORED_ERRORED" && (
              <>
                P0 failed to run Drift {doc.type.toLocaleLowerCase()}, please
                contact p0 with reference {runId}
              </>
            ))}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
