import {
  CaretLeftFilled,
  CaretRightFilled,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { Button, Form, Input, Select, Space, Spin, Tooltip } from "antd";
import { RuleObject } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import yaml from "js-yaml";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import { useGuardedEffect } from "../../../hooks/useGuardedEffect";
import { useFirestoreDoc } from "../../../providers/FirestoreProvider";
import { AwsIntegration } from "../../../shared/integrations/resources/aws/types";
import {
  P0_CLUSTER_ROLE,
  P0_CLUSTER_ROLE_BINDING,
} from "../../../shared/integrations/resources/kubernetes/constants";
import {
  Boundary,
  KubernetesComponentConfig,
  KubernetesIntegration,
} from "../../../shared/integrations/resources/kubernetes/types";
import {
  clusterCertificateValidator,
  clusterIdValidator,
  clusterServerValidator,
} from "../../../shared/integrations/resources/kubernetes/validator";
import { getEnvironment } from "../../../utils/environment";
import { ErrorDisplay } from "../../Error";
import { useAuthFetch } from "../../Login/hook";
import { Tenant } from "../../Login/util";
import { CommandDisplay } from "../CommandDisplay";
import { IntegrationCard } from "../IntegrationCard";
import { InstallMore, InstallStateHeader } from "../components/Install";
import { ClusterConfigState } from "./ClusterConfigState";
import {
  P0_BOUNDARY_CONFIGURATION,
  P0_BOUNDARY_DEPLOYMENT,
  P0_BOUNDARY_SERVICE,
} from "./constants";

const DEFAULT_USER_EMAIL = "Slack";
const MISSING_PKI_ERROR = "Missing PKI data";

// Use stateError for code paths taht never happen but Typescript is not able to deduce that.
export const stateError = (reason: string) =>
  `Unexpected state, please start over. (${reason})`;

export const K8sIconUrl =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Kubernetes_logo_without_workmark.svg/247px-Kubernetes_logo_without_workmark.svg.png";

export const k8sCommand = (
  serverCert: string,
  serverKey: string,
  caBundle: string
) => `kubectl apply -f - <<EOF
apiVersion: v1
kind: Namespace
metadata:
  name: p0-security

---

apiVersion: v1
kind: ServiceAccount
metadata:
  name: p0-service-account
  namespace: p0-security

---

apiVersion: v1
kind: Secret
metadata:
  name: p0-service-account-secret
  namespace: p0-security
  annotations:
    kubernetes.io/service-account.name: p0-service-account
type: kubernetes.io/service-account-token

---

${yaml.dump(P0_CLUSTER_ROLE, { flowLevel: 3 })}
---

${yaml.dump(P0_CLUSTER_ROLE_BINDING, { flowLevel: 3 })}
---

${yaml.dump(P0_BOUNDARY_DEPLOYMENT(serverKey, serverCert), { flowLevel: 8 })}
---

${yaml.dump(P0_BOUNDARY_SERVICE, { flowLevel: 4 })}
---

${yaml.dump(P0_BOUNDARY_CONFIGURATION(caBundle), { flowLevel: 5 })}

EOF`;

/** Makes the validator function conform to the signature expected by the antd FormItem validation, in the Validator type,
 * by ignoring the rule argument:
 * type Validator = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void) => Promise<void | any> | void;
 */
const validatorFn =
  (validator: (value: any) => Promise<void>) =>
  (_rule: RuleObject, value: any) =>
    validator(value);

const awsAccountIdToValue = (awsAccountId: string) =>
  `AWS account (${awsAccountId})`;

const valueToAwsAccountId = (userEmail: string) => {
  let awsAccountId;
  const match = userEmail.match(/AWS account \(([^)]+)\)/);
  if (match !== null && match[1]) {
    awsAccountId = match[1];
  }
  return awsAccountId;
};

export const SubmitFooter: React.FC<{
  onBack: () => void;
  hiddenNext?: boolean;
}> = ({ onBack, hiddenNext }) => {
  return (
    <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button icon={<CaretLeftFilled />} onClick={onBack} htmlType="button">
          Return to previous step
        </Button>
        {hiddenNext ? null : (
          <Button icon={<CaretRightFilled />} type="primary" htmlType="submit">
            Go to next step
          </Button>
        )}
      </div>
    </Form.Item>
  );
};

const UserEmailInput: React.FC<{
  tenantId: string;
  value?: string;
  onChange?: (value: string) => void;
}> = ({ tenantId, value, onChange }) => {
  const [selected, setSelected] = useState<string>(DEFAULT_USER_EMAIL);

  const awsEksPath = useMemo(
    () => `o/${tenantId}/integrations/aws`,
    [tenantId]
  );
  const awsEksDoc = useFirestoreDoc<AwsIntegration>(awsEksPath, { live: true });

  const awsAccountIds = Object.keys(awsEksDoc.doc?.data?.base ?? {});

  const awsOptions = useMemo(() => {
    return awsAccountIds
      ? [
          {
            label: "AWS account",
            options: awsAccountIds.map((accountId) => ({
              label: awsAccountIdToValue(accountId),
              value: awsAccountIdToValue(accountId),
            })),
          },
        ]
      : [];
  }, [awsAccountIds]);

  const onChangeSelected = useCallback(
    (changedValue: string) => {
      setSelected(changedValue);
      onChange?.(changedValue);
    },
    [onChange]
  );

  return (
    <Select
      value={value || selected}
      onChange={onChangeSelected}
      options={[
        ...awsOptions,
        {
          label: "Manual",
          options: [
            {
              label: "Email in user name",
              value: DEFAULT_USER_EMAIL,
            },
          ],
        },
      ]}
    />
  );
};

/** Shown when new cluster is registered: enter cluster ID */
const ZeroState: React.FC<{
  tenantId: string;
  config?: Partial<KubernetesComponentConfig>;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ tenantId, config, onNext, onBack }) => {
  return (
    <>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onNext}
        labelAlign="right"
      >
        <Form.Item
          label={
            <span>
              User provisioning&nbsp;
              <Tooltip title="Select how users are provisioned in this Kubernetes cluster. P0 will use the user names from the selected system to assign access.">
                <InfoCircleTwoTone />
              </Tooltip>
            </span>
          }
          name="userEmail"
          initialValue={
            config?.awsAccountId
              ? awsAccountIdToValue(config?.awsAccountId)
              : DEFAULT_USER_EMAIL
          }
          rules={[
            {
              required: true,
              message: "Choose how users are provisioned",
            },
          ]}
        >
          <UserEmailInput tenantId={tenantId} />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Cluster name&nbsp;
              <Tooltip title="A unique name for the cluster within your organization. Approvers should recognize the cluster based on this name.">
                <InfoCircleTwoTone />
              </Tooltip>
            </span>
          }
          name="clusterId"
          initialValue={config?.clusterId}
          rules={[
            {
              required: true,
              message:
                "Valid characters: numbers, letters, dash (-), and underscore (_)",
              validator: validatorFn(clusterIdValidator),
            },
          ]}
        >
          <Input placeholder="A recognizable identifier" />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Server API endpoint&nbsp;
              <Tooltip
                title={
                  <ul>
                    <li>
                      AWS EKS:
                      <br />
                      Server API endpoint
                    </li>
                    <li>
                      Azure AKS:
                      <br />
                      API server address
                    </li>
                    <li>
                      GCloud GKE:
                      <br />
                      External or Internal endpoint
                    </li>
                  </ul>
                }
              >
                <InfoCircleTwoTone />
              </Tooltip>
            </span>
          }
          name="clusterServer"
          initialValue={config?.clusterServer}
          rules={[
            {
              required: true,
              message: "HTTPS server IP address or host name",
              validator: validatorFn(clusterServerValidator),
            },
          ]}
        >
          <Input placeholder="https://cluster-host" />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Certificate Authority&nbsp;
              <Tooltip title="Find in the 'clusters' section of an existing client kube config file">
                <InfoCircleTwoTone />
              </Tooltip>
            </span>
          }
          name="clusterCertificate"
          initialValue={config?.clusterCertificate}
          rules={[
            {
              required: true,
              message: "Certificate in raw pem or base64-encoded pem format",
              validator: validatorFn(clusterCertificateValidator),
            },
          ]}
        >
          <TextArea
            autoSize={{ maxRows: 15 }}
            placeholder="Cluster certificate in raw pem or base64-encoded pem format"
          />
        </Form.Item>
        <SubmitFooter onBack={onBack} />
      </Form>
    </>
  );
};

const JwkConfigState: React.FC<{
  command: string;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ command, onNext, onBack }) => {
  return (
    <>
      <div style={{ marginBottom: 8 }}>
        Run the following Kubernetes commands to set up a service account for P0
        and grant permissions to read resources and modify roles and
        rolebindings.
        <CommandDisplay commands={command} />
        Retrieve the base64-encoded token of the p0-service-account-secret:
        <CommandDisplay
          commands={
            "kubectl get secret p0-service-account-secret -o json -n p0-security | jq -r '.data.token'"
          }
          minRows={2}
          maxRows={30}
        />
      </div>

      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onNext}
        labelAlign="right"
      >
        <Form.Item
          label="Service account token"
          name="token"
          rules={[
            {
              required: true,
              message:
                "Enter the service account token in base64-encoded format",
            },
          ]}
        >
          <TextArea
            autoSize={{ maxRows: 15 }}
            placeholder="Base64-encoded service account token"
          />
        </Form.Item>
        <SubmitFooter onBack={onBack} />
      </Form>
    </>
  );
};

const renderCluster = (item: KubernetesComponentConfig) => item.clusterId;

/** Shows UI for Kubernetes integration, depending on state:
 * - No data:  {@link ZeroState}
 * - User has entered cluster ID and server address: {@link ClusterConfigState}
 * - User has entered public JWK: {@link JwkConfigState}
 * - User has entered secret token and install verified
 */
export const K8s: React.FC = () => {
  const environment = getEnvironment();
  const [config, setConfig] = useState<Partial<KubernetesComponentConfig>>();
  const [boundary, setBoundary] = useState<Boundary>();
  const [currentState, setCurrentState] = useState<
    "cluster-config" | "installed" | "jwk-config" | "zero"
  >();
  const [error, setError] = useState<string>();
  const [isEditing, setIsEditing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const authFetch = useAuthFetch(setError, setIsFetching);
  const tenantId = useContext(Tenant);
  const { orgSlug } = useParams();

  const integrationDoc = useFirestoreDoc<KubernetesIntegration>(
    "integrations/k8s",
    { live: true, tenantAware: true }
  );

  useGuardedEffect(
    async (cancellation) => {
      const response = await authFetch(`integrations/k8s/boundary`, {
        method: "GET",
      });
      const responseJson = await response?.json();
      if (responseJson?.boundary) {
        cancellation.guard(setBoundary)(responseJson.boundary as Boundary);
      } else {
        cancellation.guard(setError)(MISSING_PKI_ERROR);
      }
    },
    setError,
    [authFetch]
  );

  const onRemove = useCallback(async () => {
    await authFetch(`integrations/k8s/config`, { method: "DELETE" });
  }, [authFetch]);

  const onZeroStateNext = (values: any) => {
    const { userEmail, clusterId, clusterServer, clusterCertificate } = values;
    const awsAccountId = valueToAwsAccountId(userEmail);
    setConfig({
      awsAccountId,
      clusterId,
      clusterServer,
      clusterCertificate,
    });
    setCurrentState("cluster-config");
  };

  const onZeroStateBack = () => setIsEditing(false);

  const onClusterConfigNext = useCallback(
    (values: any) => {
      const { publicJwk } = values;
      if (publicJwk) {
        const newConfig = {
          ...config,
          isProxy: true,
          publicJwk,
        };
        setConfig(newConfig);
      }
      setCurrentState("jwk-config");
    },
    [config]
  );

  const onClusterConfigBack = () => setCurrentState("zero");

  const onJwkConfigNext = useCallback(
    async (values: any) => {
      const { token } = values;
      await authFetch(`integrations/k8s/verify-install`, {
        method: "POST",
        json: {
          ...config,
          token,
        },
      });
      setConfig(undefined);
      setIsEditing(false);
    },
    [config, authFetch]
  );

  const onJwkConfigBack = () => setCurrentState("cluster-config");

  const installed = useMemo(
    () =>
      integrationDoc.doc?.data?.workflows?.items?.filter(
        (item) => item.state === "installed"
      ) ?? [],
    [integrationDoc.doc?.data]
  );

  // Only one cluster can be installed at a time
  const inProgressIds = useMemo(
    () => (config?.clusterId ? [config?.clusterId] : []),
    [config]
  );

  useEffect(() => {
    if (isEditing || installed.length === 0) {
      setCurrentState("zero");
    } else if (installed.length > 0) {
      setCurrentState("installed");
    }
  }, [isEditing, installed.length]);

  const onClickEdit = () => {
    setConfig(undefined);
    setIsEditing(true);
  };

  const stepIndex =
    currentState === "zero" ? 0 : currentState === "cluster-config" ? 1 : 2;
  const stepLabels = useMemo(
    () => [
      { label: "Choose cluster" },
      { label: "Configure cluster" },
      { label: "Grant P0 permissions" },
    ],
    []
  );

  return (
    <IntegrationCard
      canRemove={!!integrationDoc.doc}
      onRemove={onRemove}
      integration="k8s"
      integrationDoc={integrationDoc.doc}
      logo={K8sIconUrl}
    >
      <Space direction="vertical" style={{ width: "100%" }} size={20}>
        <InstallStateHeader
          label="cluster"
          installed={installed}
          inProgressIds={inProgressIds}
          render={renderCluster}
          state={currentState}
          step={stepIndex}
          steps={stepLabels}
        />
        {isFetching || integrationDoc.loading ? (
          <Spin />
        ) : (
          <>
            {!!error && <ErrorDisplay title="Error" error={error} />}
            {currentState === "zero" ? (
              <ZeroState
                tenantId={tenantId}
                config={config}
                onNext={onZeroStateNext}
                onBack={onZeroStateBack}
              />
            ) : currentState === "cluster-config" ? (
              orgSlug && config ? (
                <ClusterConfigState
                  environment={environment}
                  orgSlug={orgSlug}
                  config={config}
                  onNext={onClusterConfigNext}
                  onBack={onClusterConfigBack}
                />
              ) : (
                <ErrorDisplay
                  title="Error"
                  error={stateError("Missing org slug or config")}
                />
              )
            ) : currentState === "jwk-config" ? (
              boundary !== undefined ? (
                <JwkConfigState
                  command={k8sCommand(
                    boundary.serverCert,
                    boundary.serverKey,
                    boundary.caBundle
                  )}
                  onNext={onJwkConfigNext}
                  onBack={onJwkConfigBack}
                />
              ) : (
                <ErrorDisplay
                  title="Error"
                  error={stateError("Missing boundary data")}
                />
              )
            ) : (
              <InstallMore label="cluster" onClick={onClickEdit} />
            )}
          </>
        )}
      </Space>
    </IntegrationCard>
  );
};
