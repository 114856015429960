import { Button, Modal, Typography } from "antd";
import { CommandDisplay } from "components/Integrations/CommandDisplay";
import { ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { AnyNode } from "shared/types/assessment/data";

import { toScope } from "../../../shared/types/assessment";
import { ScopeContext } from "../contexts/ScopeContext";
import { MonitorActionProps } from "../pages/MonitorResults";
import { fixScope } from "../scope";

/** Displays commands used to fix findings / revert fixes */
export const FindingsFixModal: React.FC<MonitorActionProps> = ({
  monitor,
  state,
  allNodes,
  selectedNodes,
}) => {
  const [fixContent, setFixContent] = useState<ReactNode>();
  const { integration, scopeKey } = useContext(ScopeContext);

  const showFix = useCallback(
    (nodes?: AnyNode[]) => () => {
      if (!integration) return;
      const fix =
        state === "open"
          ? monitor?.fix?.[fixScope(integration)]
          : monitor?.revert?.[fixScope(integration)];
      if (!fix || !nodes) return;
      setFixContent(
        <div>
          <Typography.Title level={3}>{monitor.label}</Typography.Title>
          <Typography.Paragraph>{fix.description}</Typography.Paragraph>
          <CommandDisplay
            commands={nodes
              .map(
                (n) =>
                  fix
                    .generate(n as any, { scope: toScope(scopeKey) })
                    ?.trim() ??
                  `# Finding with key ${n.key} does not have a fix available`
              )
              .join("\n")}
            maxRows={25}
          />
        </div>
      );
    },
    [integration, state, monitor, scopeKey]
  );

  const onFix = useMemo(
    () => showFix(selectedNodes?.length ? selectedNodes : allNodes),
    [allNodes, selectedNodes, showFix]
  );
  const onCancelFix = useCallback(() => setFixContent(undefined), []);

  const label = state === "open" ? "🔧\u00a0Fix" : "↩️\u00a0Revert";

  return (
    <>
      <Button onClick={onFix}>{label}</Button>
      <Modal
        maskClosable={false}
        open={!!fixContent}
        onCancel={onCancelFix}
        style={{ minWidth: "60em" }} // Similar to a terminal window
        footer={false}
      >
        {fixContent}
      </Modal>
    </>
  );
};
