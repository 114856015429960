import { RoutingEditAlert } from "..";
import { useRoutingRulesStore } from "../../../store/routingRulesStore";
import CodeEditor from "../CodeEditor";
import { DEFAULT_DOCUMENT, EXAMPLE_DOCUMENT } from "../utils";

export const RulesView: React.FC<{
  canSubmit: boolean;
  handleSubmitWorkflow: (value: string) => Promise<void>;
}> = ({ canSubmit, handleSubmitWorkflow }) => {
  const { yamlRules, setYamlRules } = useRoutingRulesStore();

  return (
    <>
      {canSubmit && <RoutingEditAlert />}
      <CodeEditor
        rule={yamlRules}
        setDefaultRule={() => setYamlRules(DEFAULT_DOCUMENT)}
        setSampleExample={() => setYamlRules(EXAMPLE_DOCUMENT)}
        handleSubmitWorkflow={canSubmit ? handleSubmitWorkflow : undefined}
      />
    </>
  );
};
