import { Button, Empty } from "antd";
import { Heading } from "components/Heading";
import { useNavigate } from "react-router";

export const NotFound: React.FC<{
  title: string;
  backButton: {
    label: string;
    navigateTo: string;
  };
}> = ({ title, backButton: { label, navigateTo } }) => {
  const navigate = useNavigate();
  return (
    <>
      <Heading title={title} />
      <Empty
        description={
          <>
            <Button type="primary" onClick={() => navigate(navigateTo)}>
              {label}
            </Button>
          </>
        }
      />
    </>
  );
};
