import { mapWith } from "../util/collections";
import { InstallContext, InstallSpec, ItemConfigOf } from "./types";

/** Returns all visible components */
export const visibleComponentEntries = (spec: InstallSpec) =>
  Object.entries(spec).filter(
    ([key, { hidden, type }]) =>
      !hidden && type !== "generated" && key !== "root"
  );

/** Returns all visible component keys */
export const visibleComponentKeys = (spec: InstallSpec) =>
  visibleComponentEntries(spec).map(([key]) => key);

/** Returns all installed items of a component, for an install context */
export const otherInstalled =
  <R extends Record<string, Record<string, ItemConfigOf<any>>>>(
    component: keyof R
  ) =>
  (context: InstallContext<R>) =>
    context.config
      ? mapWith(
          Object.entries(context.config[component]),
          function* ([id, { state, label }]) {
            if (state === "installed") yield { id, label };
          }
        )
      : [];
