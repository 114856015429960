import { Segmented, Select, Spin, Typography } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { CatalogContext } from "components/Catalog/context";
import { GraphSearch } from "components/GraphTable/GraphSearch";
import { Heading } from "components/Heading";
import { SpaceBetweenDiv } from "components/divs";
import stringify from "json-stable-stringify";
import { capitalize, noop, uniqBy } from "lodash";
import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { IamShowOptions } from "shared/assessment/constants";
import { assessmentParse } from "shared/assessment/issues/presets";
import { bindingNodeToPermissionSet } from "shared/assessment/render";
import { aggregate } from "shared/graph/aggregate";
import { discoverPaths } from "shared/graph/discover";
import { keyOf } from "shared/graph/graph";
import { NodePredicate } from "shared/graph/search";
import { ConnectedNode, NodeOf, Reducers } from "shared/graph/types";
import { AssessmentScopeBase } from "shared/types/assessment";
import {
  AnyAggregates,
  AssessmentNodes,
  TargetNodeType,
  isTargetNodeType,
} from "shared/types/assessment/data";
import { NodeFor } from "shared/types/assessment/data";
import styled from "styled-components";
import { assertNever } from "utils/assert";
import { join } from "utils/join";

import { PermissionLink } from "../components/cells/PermissionLink";
import { RiskLink } from "../components/cells/RiskLink";
import { NodeDisplay } from "../components/node/NodeDisplay";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";
import { GraphVisualization } from "./GraphVisualization";
import { ReasonsDisplay } from "./ReasonsDisplay";
import { CredentialDisplay } from "./cells/Credential";
import { PrincipalCell } from "./cells/Principal";
import { Resource } from "./cells/Resource";
import { nodeDataFromShow } from "./node";
import { NodeLabel } from "./node/NodeText";

const HydratedRiskLink: React.FC<{ id: string }> = ({ id }) => {
  const { risks } = useContext(CatalogContext);
  return id in risks ? <RiskLink risk={risks[id]} /> : <>{id}</>;
};

const DetailSpan = styled.span`
  display: inline;
  white-space: nowrap;
`;

const DelimSpan = styled.span`
  margin-left: 0.3em;
  margin-right: 0.3em;
`;

const NodeText = (
  current: NodeOf<AssessmentNodes>,
  integration: AssessmentScopeBase["integration"]
): Record<string, (node: NodeOf<AssessmentNodes>) => ReactNode> =>
  ({
    authentication: (n: NodeFor<"authentication">) =>
      `${capitalize(n.data.type)} credentials`,
    credential: (n: NodeFor<"credential">) => (
      <CredentialDisplay credential={n.data} id={n.key} />
    ),
    binding: (node: NodeFor<"binding">) =>
      current.type === "binding" && current.key === node.key
        ? "This grant"
        : bindingNodeToPermissionSet(node),
    permissionType: (node: NodeFor<"permissionType">) =>
      `${
        node.data.permissionType === "unknown"
          ? "Potentially used"
          : capitalize(node.data.permissionType)
      } permissions`,
    permission: (node: NodeFor<"permission">) => (
      <PermissionLink
        key={node.key}
        permission={node.key}
        integration={integration}
      />
    ),
    principal: (node: NodeFor<"principal">) => (
      <PrincipalCell principalData={node.data} />
    ),
    resource: (node: NodeFor<"resource">) => (
      <Resource resource={node.key} shortPath />
    ),
    risk: (node: NodeFor<"risk">) => (
      <HydratedRiskLink key={node.key} id={node.key} />
    ),
  }) as any;

export const ResultDetail: React.FC<{
  terms: string[];
  node: { key: string; type: string };
  show: TargetNodeType;
}> = ({ terms, node: { key, type }, show }): JSX.Element => {
  const { predicate, reducers } = nodeDataFromShow[show];
  return (
    <InnerResultDetail
      from={predicate}
      terms={terms}
      node={{ key, type }}
      reducers={reducers as Reducers<AssessmentNodes, AnyAggregates>}
      show={show}
    />
  );
};

const InnerResultDetail = <A extends AnyAggregates>({
  from,
  terms,
  node: { key, type },
  reducers,
  show,
}: {
  from: NodePredicate<AssessmentNodes>;
  terms: string[];
  node: { key: string; type: string };
  reducers: Reducers<AssessmentNodes, A>;
  show: TargetNodeType;
}): ReactElement => {
  const {
    last: { loading },
  } = useContext(SelectedAssessmentContext);
  const [selectedTermIndex, setSelectedTermIndex] = useState(0);
  const { graph, integration } = useContext(ScopeContext);
  const [search, setSearch] = useSearchParams();

  // Potentially this could be merged with `useControl`, but I didn't want to entangle with other
  // existing control values from graph search
  const displayMode = useMemo(
    () => (search.get("view") === "graph" ? "graph" : "summary"),
    [search]
  );
  const setDisplayMode = useCallback(
    (value: string) => {
      const s = new URLSearchParams(search);
      s.set("view", value);
      setSearch(s);
    },
    [search, setSearch]
  );

  const node = useMemo(() => {
    const node = graph?.nodes.find((n) => n.type === type && n.key === key);
    if (!node) return undefined;
    return aggregate({ nodes: [node] }, reducers).nodes[0];
  }, [graph, key, type, reducers]);

  const detailTitle =
    node && integration ? NodeLabel(node, integration) : "Not found";

  const termOptions = useMemo(() => {
    return terms.map((t, ix) => ({ value: ix, label: t })) ?? [];
  }, [terms]);

  const reason = useMemo(() => {
    if (!node || !integration || selectedTermIndex === undefined)
      return undefined;
    // Only ever one node
    const graph = {
      nodes: [node as ConnectedNode<AssessmentNodes, keyof AssessmentNodes>],
    };
    const results = discoverPaths(
      graph,
      from,
      assessmentParse(terms.join(" "))
    )[0]?.matches?.[selectedTermIndex];
    if (!results) return [];
    const { paths } = results;
    const uniqPaths = uniqBy(paths, (p) => stringify(p.map(keyOf)));
    const renderer = NodeText(node, integration);
    const textPaths = uniqPaths
      .map((p) =>
        join(
          p.map((n, ix) => (
            <DetailSpan key={ix}>{renderer[n.type]?.(n) ?? n.key}</DetailSpan>
          )),
          (ix) => <DelimSpan key={`delim-${ix}`}>→</DelimSpan>
        )
      )
      .sort();
    return textPaths;
  }, [node, selectedTermIndex, from, terms, integration]);

  if (loading) return <Spin />;
  if (!graph || !node || !isTargetNodeType(node))
    return (
      <div>
        <Heading title="Not Found" />
      </div>
    );
  return (
    <div>
      <SpaceBetweenDiv>
        <Typography.Title level={4}>This {detailTitle}</Typography.Title>
        <Segmented
          options={["summary", "graph"]}
          value={displayMode}
          onChange={setDisplayMode as Dispatch<SetStateAction<SegmentedValue>>}
        />
      </SpaceBetweenDiv>
      {displayMode === "summary" ? (
        <div style={{ maxWidth: "800px" }}>
          <NodeDisplay node={node} />
        </div>
      ) : displayMode === "graph" ? (
        integration && (
          <GraphVisualization node={node} integration={integration} />
        )
      ) : (
        assertNever(displayMode)
      )}
      {!!reason?.length && (
        <>
          <Typography.Title level={4} style={{ marginTop: "1em" }}>
            Explanation
          </Typography.Title>
          <Typography.Paragraph>
            This {detailTitle} was the result of searching for:
          </Typography.Paragraph>
          <div style={{ margin: "1em", marginBottom: "2em" }}>
            <GraphSearch
              showOptions={IamShowOptions}
              frozen={{ terms: terms.join(" "), show }}
              controls={{
                terms: "",
                show,
                onChangeTerms: noop,
                onChangeShow: noop,
              }}
              isSearching={false}
            />
          </div>
          {terms.length > 1 && (
            <>
              <Typography.Paragraph>
                To see why this grant matches this search, select an individual
                term below:
              </Typography.Paragraph>
              <Select
                options={termOptions}
                value={selectedTermIndex}
                onChange={setSelectedTermIndex}
                placeholder="Select a search term"
              >
                {selectedTermIndex && terms[selectedTermIndex]}
              </Select>
            </>
          )}
          <ReasonsDisplay reasons={reason} />
        </>
      )}
    </div>
  );
};
