import { grantData } from "./binding";
import { credentialData } from "./credential";
import { principalData } from "./principal";
import { resourceData } from "./resource";

export const nodeDataFromShow = {
  binding: grantData,
  credential: credentialData,
  principal: principalData,
  resource: resourceData,
};
