import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";

import { App } from "./components/App/App";
import { ErrorFallback } from "./components/common/ErrorFallback";
import "./index.less";
import PostHogProviderWrapper from "./postHogProviderWrapper";
import reportWebVitals from "./reportWebVitals";
import { SentryMonitor } from "./sentry";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    // TODO ENG-2130 do not leak configuration - load LD entirely from backend
    clientSideID:
      window?.location.hostname === "p0.app"
        ? "644178eaf2d05912f9c32810"
        : window?.location.hostname === "cna.p0.app" // prod-cna-central environment
        ? "664249465ed4bd0f93c426d5"
        : "644178eaf2d05912f9c3280f",
    options: {
      streaming: true,
    },
  });
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <SentryMonitor>
          <LDProvider>
            <PostHogProviderWrapper>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <App />
              </ErrorBoundary>
            </PostHogProviderWrapper>
          </LDProvider>
        </SentryMonitor>
      </BrowserRouter>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
