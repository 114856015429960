import { Col, Image, Layout, Row } from "antd";
import React, { CSSProperties } from "react";
import styled from "styled-components";

import { Footer } from "./App/Footer";

const StyledLayout = styled(Layout)`
  width: 100%;
  padding: 20px 0;
  height: 100%;
  overflow-y: auto;
`;

const StyledContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Splash: React.FC<
  React.PropsWithChildren<{
    overrideColumn?: boolean;
    showLogo?: boolean;
    rowStyleOverride?: CSSProperties;
  }>
> = ({ children, overrideColumn, rowStyleOverride, showLogo = true }) => {
  return (
    <StyledLayout>
      <StyledContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {showLogo && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
              }}
            >
              <Image
                preview={false}
                src={"/p0-logomark-dark.svg"}
                alt="P0"
                width={150}
              />
            </div>
          )}
          <Row
            justify="center"
            align="top"
            style={{
              flex: 1,
              ...rowStyleOverride,
              overflow: "auto",
              width: "100%",
            }}
            gutter={[24, 24]}
          >
            {overrideColumn ? (
              children
            ) : (
              <Col xs={20} md={12} lg={10} xl={8}>
                {children}
              </Col>
            )}
          </Row>
        </div>
      </StyledContent>
      <Footer />
    </StyledLayout>
  );
};
