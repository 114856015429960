import {
  CheckCircleOutlined,
  EditFilled,
  StopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { AllIntegrationLogos } from "components/Integrations/IntegrationLogo";
import { Logo } from "components/Integrations/Logo";
import {
  RoutingRulePopover,
  StyledRoutingRulePreview,
} from "components/Routing/styles";
import { TenantAwareLink } from "components/common/TenantAwareLink";
import { useFirestoreCollection } from "providers/FirestoreProvider";
import { useCallback, useMemo } from "react";
import { ApprovalRule } from "shared/types/workflow/types";

import { GenericIdpGroupPreview, IdpGroupPreview } from "./IdpGroupPreview";

export type ApprovalRulePreview =
  | ApprovalRule
  | {
      type: "genericEscalation";
    }
  | {
      type: "genericGroup";
    };

type Props = {
  approval: ApprovalRulePreview;
};

export const ApprovalPreview: React.FC<Props> = ({ approval }) => {
  const p0Approvers = useFirestoreCollection<{ email: string }>(
    "roles/manager/bindings",
    {
      tenantAware: true,
    }
  );

  const p0ApproversList = useMemo(() => {
    return (
      <>
        <div style={{ marginBottom: 10 }}>
          {p0Approvers?.map((doc, index) => (
            <Typography.Paragraph key={index}>
              {doc.data.email}
            </Typography.Paragraph>
          ))}
        </div>
        <TenantAwareLink to="settings">
          Edit this group <EditFilled />
        </TenantAwareLink>
      </>
    );
  }, [p0Approvers]);

  const genericList = useCallback((ids?: string[]) => {
    return (
      <>
        <div style={{ marginBottom: 10 }}>
          {ids?.map((id, index) => (
            <Typography.Paragraph key={index}>{id}</Typography.Paragraph>
          ))}
        </div>
      </>
    );
  }, []);

  return approval.type === "genericGroup" ? (
    <GenericIdpGroupPreview />
  ) : approval.type === "group" ? (
    <IdpGroupPreview idpGroup={approval} isApproval />
  ) : approval.type === "auto" ? (
    <StyledRoutingRulePreview>
      {AllIntegrationLogos.pagerduty}
      <span>Automatically approved for on-call</span>
    </StyledRoutingRulePreview>
  ) : approval.type === "p0" ? (
    <RoutingRulePopover
      text="Approval by users defined in P0"
      icon={<Logo logo="/p0 dark logomark.png" title="P0 Logomark" />}
      popoverContent={p0ApproversList}
    />
  ) : approval.type === "persistent" ? (
    <StyledRoutingRulePreview>
      <CheckCircleOutlined style={{ color: "#52c41a" }} />
      <span>Always allowed</span>
    </StyledRoutingRulePreview>
  ) : approval.type === "escalation" ||
    approval.type === "genericEscalation" ? (
    <RoutingRulePopover
      text="Approval by users defined in escalation service"
      icon={AllIntegrationLogos.pagerduty}
      popoverContent={
        <Typography.Paragraph style={{ marginBottom: 0 }}>
          {approval.type === "escalation" ? (
            <>{genericList(approval.services)}</>
          ) : (
            "Allow escalating the request for approval by on-call members of a PagerDuty service."
          )}
        </Typography.Paragraph>
      }
    />
  ) : approval.type === "requestor-profile" ? (
    <RoutingRulePopover
      text="Approval by the Requestor's Manager (via an Identity Provider)"
      icon={<UserOutlined />}
      popoverContent={
        <>
          <Typography.Paragraph italic>
            Requires an installed Okta or Google Workspace integration.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Requests may be approved by the requestor&apos;s manager, as defined
            by the presence of the manager&apos;s email in the user&apos;s
            Identity Provider profile.
          </Typography.Paragraph>
        </>
      }
    />
  ) : (
    <StyledRoutingRulePreview>
      <StopOutlined style={{ color: "#f5222d" }} />
      <span>Deny all access</span>
    </StyledRoutingRulePreview>
  );
};
