import {
  Button,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Spin,
  Switch,
  Typography,
} from "antd";
import { BaseOptionType } from "antd/lib/select";
import { VerticalSpacedDiv } from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import {
  FrontendComponentInstaller,
  FrontendInstallContext,
  FrontendInstallDomain,
} from "install/types";
import { cloneDeep, isEmpty, pick } from "lodash";
import {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ElementInstaller, Option, OptionsElement } from "shared/install/types";
import styled from "styled-components";
import { staticError } from "utils/console";

import { NEW_SENTINEL } from "./Component";

// TODO: Type properly
type FormProps = {
  config: any;
  context: FrontendInstallContext<any>;
  disabled?: boolean;
  id: string;
  installer?: FrontendComponentInstaller<any>;
  isFetching: boolean;
  schema: Record<string, any>;
  setConfig: ((value: any) => void) | undefined;
};

type HelpProps = Pick<FormProps, "config" | "context" | "id"> & {
  element?: any;
  installer?: ElementInstaller<FrontendInstallDomain<any>, any, any, any>;
  item: any;
};

type StringElementProps = Omit<ItemFormProps, "configKey" | "element">;

type SwitchElementProps = Omit<ItemFormProps, "configKey" | "element">;

type DynamicElementProps = Omit<ItemFormProps, "configKey">;

type GeneratedElementProps = Omit<
  ItemFormProps,
  "configKey" | "element" | "setConfig"
>;

type OptionElementProps = Omit<FormProps, "installer" | "schema"> & {
  element: OptionsElement<Record<string, Option<any>>>;
  installer?: ElementInstaller<FrontendInstallDomain<any>, any, any, any>;
};

type ComponentFormProps = FormProps;

type ItemFormProps = Omit<FormProps, "installer" | "schema"> & {
  configKey: string;
  element: any;
  installer?: ElementInstaller<FrontendInstallDomain<any>, any, any, any>;
  item: any;
};

const OptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
  min-height: 32px;
  max-width: 35em;

  .ant-form-item {
    margin-bottom: 5px;
    .ant-input:not(:last-child) {
      margin-bottom: 5px;
    }
    .ant-select:last-child {
      margin-bottom: 15px;
    }
  }

  .p0-description {
    width: 30em;
    .ant-typography code {
      white-space: nowrap;
    }
    margin-bottom: 8px;
  }

  .p0-string-element {
    gap: 0.2em;
    width: 30em;
  }

  .p0-switch-element {
    margin-bottom: 15px;
  }
`;

const Description = (props: HelpProps) => {
  const { config, context, element, id, installer, item } = props;
  const description = useMemo(
    () =>
      installer?.labeler?.(context, id, item, config) ?? element.description,
    [installer, context, id, item, config, element.description]
  );
  return description ? (
    <div className="p0-description">
      <Typography.Text type="secondary">{description}</Typography.Text>
    </div>
  ) : null;
};

const StringElement = (props: StringElementProps) => {
  const { config, disabled, setConfig } = props;
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setConfig?.(event.target.value);
    },
    [setConfig]
  );
  return (
    <VerticalSpacedDiv className="p0-string-element">
      <Input value={config} onChange={onChange} disabled={disabled} />
    </VerticalSpacedDiv>
  );
};

const DynamicElement = (props: DynamicElementProps) => {
  const { config, context, disabled, installer, setConfig } = props;
  const [messages, setMessages] = useState<string>();
  const [options, setOptions] = useState<BaseOptionType[]>();

  // Hack to prevent auto-close of antd select after click
  const onClickSelect = useCallback((event: MouseEvent) => {
    event.preventDefault();
  }, []);

  const onSelect = useCallback(
    (value: string) => {
      setConfig?.(value);
    },
    [setConfig]
  );

  useGuardedEffect(
    async (cancellation) => {
      if (installer && "optionProvider" in installer) {
        const items = await installer.optionProvider(context);
        if (cancellation.isCancelled) return;
        const options = items.map(({ id, label }) => ({
          value: id,
          label: label ?? id,
        }));
        setOptions(options);
      } else setOptions([]);
    },
    staticError,
    [context, installer]
  );

  useGuardedEffect(
    async (cancellation) => {
      if (!installer || !("prerequisiteMessages" in installer)) return;
      const messages = await installer.prerequisiteMessages?.(context);
      cancellation.guard(setMessages)(messages);
    },
    staticError,
    [context, installer]
  );

  useEffect(() => {
    if (!config && options?.[0]) setConfig?.(options?.[0]?.value);
  }, [config, options, setConfig]);

  // filter should be done based on description as
  // default search behavior searches for ids only
  const filterOption = (input: string, option?: BaseOptionType) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      {messages}
      {options ? (
        <Select
          showSearch
          options={options}
          value={config}
          onChange={onSelect}
          onClick={onClickSelect}
          disabled={disabled || !!messages}
          filterOption={filterOption}
        />
      ) : (
        <Spin />
      )}
    </>
  );
};

const GeneratedElement = ({ config }: GeneratedElementProps) => (
  <VerticalSpacedDiv className="p0-string-element">
    <Input value={config} disabled />
  </VerticalSpacedDiv>
);

const SwitchElement = (props: SwitchElementProps) => {
  const { config, disabled, setConfig } = props;
  const onChange = useCallback(
    (checked: boolean) => {
      setConfig?.(checked);
    },
    [setConfig]
  );
  return (
    <div className="p0-switch-element">
      <Switch checked={config} disabled={disabled} onChange={onChange} />
    </div>
  );
};

const OptionElement = (props: OptionElementProps) => {
  const { element, config, context, disabled, installer, setConfig } = props;
  const [disables, setDisables] = useState<Record<string, ReactNode>>();

  const setOption = useCallback(
    (type: string) =>
      setConfig?.({
        ...pick(config, Object.keys(element.options[type].schema)),
        type,
      }),
    [config, element.options, setConfig]
  );

  const onClickRadio = useCallback(
    (event: RadioChangeEvent) => {
      setOption(event.target.value);
    },
    [setOption]
  );

  // Prevent rendering loop by assigning console.error to a static value
  const logError = useCallback((message: string) => console.error(message), []);

  useEffect(() => {
    if (!config?.type) setOption(element.default);
  }, [config?.type, element.default, setOption]);

  useGuardedEffect(
    async (cancellation) => {
      const promises = Object.keys(element.options).map(async (key) => {
        const optionInstaller =
          installer && "options" in installer
            ? installer.options[key]
            : undefined;
        const message = await optionInstaller?.prerequisiteMessages?.(context);
        return [key, message];
      });
      const results = await Promise.all(promises);
      cancellation.guard(setDisables)(Object.fromEntries(results));
    },
    logError,
    [element, installer]
  );

  return disables ? (
    <>
      <Radio.Group
        disabled={disabled}
        value={config?.type}
        onChange={onClickRadio}
      >
        <VerticalSpacedDiv style={{ gap: "0.0em" }}>
          {Object.entries(element.options)
            .filter(([_key, option]) => !option.hidden)
            .map(([key, option]) => (
              <Radio key={key} value={key} disabled={!!disables[key]}>
                <OptionDiv>
                  <div>{option.label}</div>
                  {option.description && (
                    <div className="p0-description">
                      <Typography.Text type="secondary">
                        {option.description}
                      </Typography.Text>
                    </div>
                  )}
                </OptionDiv>
                {disables[key]}
                {config?.type === key && (
                  <ComponentForm
                    {...props}
                    installer={
                      installer && "options" in installer
                        ? (installer.options[key] as any)
                        : undefined
                    }
                    schema={option.schema}
                  />
                )}
              </Radio>
            ))}
        </VerticalSpacedDiv>
      </Radio.Group>
    </>
  ) : (
    <Spin />
  );
};

const ItemForm = (props: ItemFormProps) => {
  const { config, configKey: key, element, id, installer, setConfig } = props;
  const [error, setError] = useState<ReactNode>();
  const setItem = useCallback(
    async (value: any) => {
      setConfig?.({ ...config, [key]: value });
      const elementMessage = (await element?.validator?.(
        id === NEW_SENTINEL ? value : id,
        value
      )) as string;

      const elementError = elementMessage
        ? installer?.errorElement ?? elementMessage
        : undefined;

      setError(elementError);
    },
    [config, element, id, installer, key, setConfig]
  );

  return element.type === "hidden" ? null : (
    <OptionDiv key={key}>
      <Form.Item
        label={element.label}
        validateStatus={error ? "error" : undefined}
        extra={error}
      >
        <Description {...props} />
        {element.type === "select" ? (
          <OptionElement
            {...props}
            config={config?.[key]}
            setConfig={setItem}
          />
        ) : element.type === "string" ? (
          <StringElement
            {...props}
            config={config?.[key]}
            setConfig={setItem}
          />
        ) : element.type === "dynamic" ? (
          <DynamicElement
            {...props}
            config={config?.[key]}
            setConfig={setItem}
          />
        ) : element.type === "generated" ? (
          <GeneratedElement {...props} config={config?.[key]} />
        ) : element.type === "switch" ? (
          <SwitchElement
            {...props}
            config={config?.[key]}
            setConfig={setItem}
          />
        ) : null}
      </Form.Item>
    </OptionDiv>
  );
};

const ComponentForm = (props: ComponentFormProps) => {
  const { installer, schema, config } = props;
  return (
    <>
      {Object.entries(schema).map(([key, element]) => (
        <ItemForm
          {...props}
          config={config}
          configKey={key}
          element={element}
          installer={
            installer && "items" in installer ? installer.items[key] : {}
          }
          item={config}
          key={key}
        />
      ))}
    </>
  );
};

/** Recursively render the configuration UI for an install component. */
export const ConfigForm = (props: FormProps) => {
  const { config, disabled, schema, setConfig } = props;
  const [built, setBuilt] = useState(cloneDeep(config));
  const onClickNext = useCallback(() => setConfig?.(built), [built, setConfig]);
  return (
    <Form layout="vertical" preserve>
      {
        // By pushing the isFetching spinner to within the ConfigForm, we ensure
        // that we do not reset the form during fetches
        props.isFetching ? (
          <Spin />
        ) : (
          <>
            {isEmpty(schema) ? (
              <div style={{ marginBottom: "12px" }}>
                No configuration options exist for this item
              </div>
            ) : (
              <ComponentForm {...props} config={built} setConfig={setBuilt} />
            )}
            {!disabled &&
              !(config.state === "installed" && isEmpty(schema)) && (
                <Form.Item key="submit">
                  <Button
                    type="primary"
                    onClick={onClickNext}
                    // TODO: make work with initial defaults
                    disabled={isEmpty(built) && !isEmpty(schema)}
                  >
                    {config.state === "configure"
                      ? "Finish"
                      : config.state === "installed"
                      ? "Update"
                      : "Next"}
                  </Button>
                </Form.Item>
              )}
          </>
        )
      }
    </Form>
  );
};
