import { Button, Result } from "antd";
import React from "react";
import { FallbackProps } from "react-error-boundary";
import { useNavigate } from "react-router";
import styled from "styled-components";

import ContactSupport from "../Integrations/components/ContactSupport";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ErrorFallback: React.FC<FallbackProps> = ({
  resetErrorBoundary,
}) => {
  const navigate = useNavigate();

  const handleBackHome = () => {
    resetErrorBoundary();
    navigate(`/`);
    window.location.reload();
  };

  return (
    <StyledWrapper>
      <Result
        status="500"
        title="500"
        subTitle={
          <>
            Sorry, something went wrong. If the problem persists, please
            <ContactSupport subject={"Error Page - Issue Description"} />.
          </>
        }
        extra={
          <Button type="primary" onClick={handleBackHome}>
            Back Home
          </Button>
        }
      />
    </StyledWrapper>
  );
};
