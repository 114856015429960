import { AuthenticatedP0User, Shutdownable } from ".";
import { MfaStatus } from "./assessment/data";

export type Group = {
  id: string;
  label?: string;
};

export const IdentityTypes = ["group", "user"] as const;
export type IdentityType = (typeof IdentityTypes)[number];

export const GroupTypes = ["group", "federated-identity"] as const;
export type GroupType = (typeof GroupTypes)[number];

export type DirectoryUser = {
  type: "user";
  directory: string;
  id: string;
  label: string;
  lastLogin: string | undefined;
  mfa: MfaStatus;
  aliases: string[];
};

export type DirectoryGroupMember = {
  id: string;
  label: string;
  type: IdentityType;
};

export type DirectoryGroupMemberUser = DirectoryGroupMember & {
  type: "user";
  aliases: string[];
};

export type DirectoryGroup = Group & {
  type: GroupType;
  directory: string;
  label: string;
  members: DirectoryGroupMember[];
};

export type UsersForGroupOptions = {
  includeTransitive?: boolean;
};

export type DirectoryIdentity = DirectoryGroup | DirectoryUser;

export const DEFAULT_MANAGER_EMAIL_FIELD = "manager";

export interface Directory extends Shutdownable {
  /** Gets a user's groups */
  groupsForUser(user: AuthenticatedP0User): AsyncGenerator<Group>;

  /** Adds a user to a group */
  addToGroup(user: AuthenticatedP0User, group: Group): Promise<void>;

  /** Removes a user from a group */
  removeFromGroup(user: AuthenticatedP0User, group: Group): Promise<void>;

  /** Returns all users for a group
   *
   *  Defaults to returning only direct members, unless includeTransitive is provided in options */
  users(
    group: Group,
    options?: UsersForGroupOptions
  ): AsyncGenerator<DirectoryGroupMemberUser>;

  /**
   * Get a user's manager's email as defined in the directory.
   */
  managerEmailForUserEmail(email: string): Promise<string | undefined>;

  /** List grantable groups */
  grantableGroups(): AsyncGenerator<Group>;

  /** Get a group's details from its ID */
  group(id: string): Promise<Group>;

  /** List all groups */
  allGroups(): AsyncGenerator<DirectoryGroup>;

  /** List all users */
  allUsers(): AsyncGenerator<DirectoryUser>;

  /** List all members of groups. Defaults to returning only direct members, unless includeTransitive is provided in options */
  members(
    group: Group,
    options?: UsersForGroupOptions
  ): AsyncGenerator<DirectoryGroupMember>;
}

export type DirectoryProcessor = {
  /** Process Users from directory integration specific api contract*/
  users(users: DirectoryUser[]): {
    users: DirectoryUser[];
    aliases: Record<string, string>;
  };
  /** Process group members from directory integration specific api contract */
  groupMembers(
    members: DirectoryGroupMember[],
    aliases: Record<string, string>
  ): DirectoryGroupMember[];
};
