import { SshComponents } from "shared/integrations/resources/ssh/components";

import { Install, InstallProps } from "../Install/Install";
import { sshInstaller } from "./install/install";

export const API_PATH = "integrations/ssh/config";
// This image is in the public domain See https://commons.wikimedia.org/wiki/File:Terminalicon2.png
export const SshIconUrl =
  "https://upload.wikimedia.org/wikipedia/commons/b/b3/Terminalicon2.png";
export const Ssh = (
  props: Pick<InstallProps<typeof SshComponents>, "cardOverride" | "onInstall">
) => (
  <Install<typeof SshComponents>
    components={SshComponents}
    installer={sshInstaller}
    integration="ssh"
    itemKind="account"
    logo={SshIconUrl}
    title={"SSH"}
    {...props}
  />
);
