import { Select, Spin } from "antd";
import {
  StyledFormItem,
  standardFormItemCols,
} from "components/common/forms/styles";
import { useCallback, useMemo } from "react";
import { IdpGroup } from "shared/types/workflow/types";
import { useInstalledDirectories } from "store/resources/DirectoriesProvider";

import { buildIdpGroupId, parseIdpGroupId } from "../utils";
import { IdpGroupPreview } from "./previews/IdpGroupPreview";

type Props = {
  value: IdpGroup;
  onChange: (value: IdpGroup) => void;
};

export const IdpGroupInput: React.FC<Props> = ({ value, onChange }) => {
  const { allAvailableGroups, availableGroups, isLoadingGroups } =
    useInstalledDirectories();

  const handleGroupChange = useCallback(
    (groupId: string) => {
      const group = parseIdpGroupId(groupId);
      onChange({
        ...group,
        label: availableGroups[group.directory].groups[groupId].label,
      });
    },
    [onChange, availableGroups]
  );

  const groupValue = useMemo(
    () => (value.id === "" ? undefined : buildIdpGroupId(value)),
    [value]
  );

  const groupOptions = useMemo(
    () =>
      allAvailableGroups.map((ag) => ({
        label: <IdpGroupPreview idpGroup={ag} showPopover={false} />,
        group: ag,
        value: buildIdpGroupId(ag),
      })),
    [allAvailableGroups]
  );

  const groupSearch = useCallback(
    (term: string, option: (typeof groupOptions)[number] | undefined) =>
      term.length >= 3 &&
      !!option?.group.label.toLowerCase().includes(term.toLowerCase()),
    []
  );

  return isLoadingGroups ? (
    <div>
      <Spin tip="Loading groups..." />
    </div>
  ) : (
    <StyledFormItem label="Group" {...standardFormItemCols}>
      <Select
        options={groupOptions}
        value={groupValue}
        onChange={handleGroupChange}
        showSearch
        filterOption={groupSearch}
        placeholder="Select a group"
      />
    </StyledFormItem>
  );
};
