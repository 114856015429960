import { Select } from "antd";
import Typography from "antd/lib/typography";
import { TagInput } from "components/TagInput";
import { doc, getDoc } from "firebase/firestore";
import { FrontendInstallContext, FrontendInstaller } from "install/types";
import stringify from "json-stable-stringify";
import { sortBy } from "lodash";
import { DB } from "providers/FirestoreProvider";
import { Link } from "react-router-dom";
import { installedItems } from "shared/install/installed";
import { AwsIntegration } from "shared/integrations/resources/aws/types";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";
import { SshComponents } from "shared/integrations/resources/ssh/components";

import { sudoSshInstaller } from "./sudoInstall";
import { SshInstaller } from "./types";

const { Text, Paragraph } = Typography;

const VALID_AWS_SSM_REGIONS = [
  "us-east-2",
  "us-east-1",
  "us-west-1",
  "us-west-2",
  "af-south-1",
  "ap-east-1",
  "ap-south-2",
  "ap-southeast-3",
  "ap-southeast-4",
  "ap-south-1",
  "ap-northeast-3",
  "ap-northeast-2",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-northeast-1",
  "ca-central-1",
  "ca-west-1",
  "eu-central-1",
  "eu-west-1",
  "eu-west-2",
  "eu-south-1",
  "eu-west-3",
  "eu-south-2",
  "eu-north-1",
  "eu-central-2",
  "il-central-1",
  "me-south-1",
  "me-central-1",
  "sa-east-1",
  "us-gov-east-1",
  "us-gov-west-1",
];

async function getConfig<T>(path: string) {
  const integrationDoc = await getDoc(doc(DB, path));
  return integrationDoc?.data() as T;
}

const listingInstalls = async (context: FrontendInstallContext<any>) => {
  const { tenantId, config } = context;
  const existingWrite = config ? installedItems("iam-write", config) : {};
  let options: {
    id: string;
    label?: string;
  }[] = [];

  const awsIntegrationConfig = await getConfig<AwsIntegration>(
    `o/${tenantId}/integrations/aws`
  );

  if (awsIntegrationConfig) {
    const awsAccounts = installedItems("iam-write", awsIntegrationConfig);
    options = [
      ...options,
      ...Object.entries(awsAccounts)
        .filter(([id]) => !(`aws:${id}` in existingWrite))
        .map(([id, item]) => ({
          id: `aws:${id}`,
          label: `AWS: ${item?.label ? `${item.label} (${id})` : id}`,
        })),
    ];
  }
  const gcloudConfig = await getConfig<GcloudIntegration>(
    `o/${tenantId}/integrations/gcloud`
  );

  if (gcloudConfig) {
    const projects = installedItems("iam-write", gcloudConfig);
    options = [
      ...options,
      ...Object.entries(projects)
        .filter(([id]) => !(`gcloud:${id}` in existingWrite))
        .map(([id, item]) => ({
          id: `gcloud:${id}`,
          label: `Google Cloud: ${item?.label ? `${item.label} (${id})` : id}`,
        })),
    ];
  }
  return sortBy(options, (options) => options.label ?? options.id);
};

const updateVpcEndpoints = (region: string, vpcIds: string[]) => ({
  shell: `
service_array=(ssm ssmmessages ec2 ec2messages kms logs s3 sts monitoring) 
service_type_array=(Interface Interface Interface Interface Interface Interface Gateway Interface Interface)
vpc_array=(${vpcIds.map((id) => `"${id}"`).join(" ")})
for vpc in $vpc_array; do
  echo "processing: $vpc"
  for j in "\${!service_array[@]}"; do
    aws ec2 create-vpc-endpoint --vpc-endpoint-type \${service_type_array[$j]} --vpc-id $vpc --service-name com.amazonaws.${region}.\${service_array[$j]} --region ${region}
  done
done
`,
  iac: `
locals {
  endpoints = tomap({
    s3 = {
      service = "com.amazonaws.${region}.s3",
      type    = "Gateway"
    },
    ssm = {
      service = "com.amazonaws.${region}.ssm",
      type    = "Interface"
    },
    ssmmessages = {
      service = "com.amazonaws.${region}.ssmmessages",
      type    = "Interface"
    },
    ec2 = {
      service = "com.amazonaws.${region}.ec2",
      type    = "Interface"
    },
    ec2messages = {
      service = "com.amazonaws.${region}.ec2messages",
      type    = "Interface"
    },
    logs = {
      service = "com.amazonaws.${region}.logs",
      type    = "Interface"
    },
    kms = {
      service = "com.amazonaws.${region}.kms",
      type    = "Interface"
    },
    sts = {
      service = "com.amazonaws.${region}.sts",
      type    = "Interface"
    },
    monitoring = {
      service = "com.amazonaws.${region}.monitoring",
      type    = "Interface"
    }
  })
}

variable "vpc_ids" {
  type    = list(string)
  default = [${vpcIds.map((id) => `"${id}"`).join(", ")}]
}

data "aws_subnets" "selected_vpc_subnets" {
  for_each = toset(var.vpc_ids)
  filter {
    name   = "vpc-id"
    values = [each.value]
  }
}

locals {
  vpc_list = flatten([for vpc in var.vpc_ids :
    flatten([for key, value in local.endpoints :
      { "vpc"     = vpc
        "service" = value.service
        "type"    = value.type
      }
    ])
  ])
}


resource "aws_vpc_endpoint" "ssm-endpoints" {
  for_each          = { for idx, record in local.vpc_list : idx => record }
  vpc_id            = each.value.vpc
  service_name      = each.value.service
  vpc_endpoint_type = each.value.type
  # Only Interface and GatewayLoadBalancer types require subnet IDs. There are no GatewayLoadBalancer types for SSM.
  subnet_ids = each.value.type == "Interface" ? data.aws_subnets.selected_vpc_subnets[each.value.vpc].ids : null
  # Only Interface require private_dns_enabled = true to allow services within the VPC to automatically use the endpoint.
  private_dns_enabled = each.value.type == "Interface" ? true : null
}
`,
});

const SSM_ROLE_NAME = "AWSSystemsManagerDefaultEC2InstanceManagementRole";
const SSM_INSTANCE_PROFILE = "AwsSsmInstanceProfile";

const SSM_TRUST_POLICY = {
  Version: "2012-10-17",
  Statement: [
    {
      Sid: "",
      Effect: "Allow",
      Principal: {
        Service: "ssm.amazonaws.com",
      },
      Action: "sts:AssumeRole",
    },
  ],
};

// Stable trust policy is useful for verifying the installation
const SSM_TRUST_POLICY_JSON = stringify(SSM_TRUST_POLICY, { space: 2 });
const SSM_POLICY_ARN =
  "arn:aws:iam::aws:policy/AmazonSSMManagedEC2InstanceDefaultPolicy";
const SSM_INSTANCE_ROLE = {
  shell: `aws iam create-role \\
  --role-name "${SSM_ROLE_NAME}" \\
  --assume-role-policy-document '${SSM_TRUST_POLICY_JSON}' 
aws iam attach-role-policy \\
  --policy-arn ${SSM_POLICY_ARN} \\
  --role-name ${SSM_ROLE_NAME}`,

  iac: `resource "aws_iam_role" "default_host_management_role" {
      
  name        = "${SSM_ROLE_NAME}"
  path        = "/service-role/"
  description = "AWS Systems Manager Default EC2 Instance Management Role"

  # AmazonSSMManagedEC2InstanceDefaultPolicy is an AWS-managed policy
  managed_policy_arns = ["${SSM_POLICY_ARN}"]

  assume_role_policy = <<EOT
${SSM_TRUST_POLICY_JSON}
EOT

  lifecycle {
    prevent_destroy = true
  }
}`,
};

const instanceProfileUpdate = (region: string, instanceIds: string[]) => ({
  shell: `aws iam create-instance-profile \\
  --region ${region} \\
  --instance-profile-name ${SSM_INSTANCE_PROFILE}
aws iam add-role-to-instance-profile \\
  --region ${region} \\
  --role-name ${SSM_ROLE_NAME} \\
  --instance-profile-name ${SSM_INSTANCE_PROFILE}
for ID in ${instanceIds.join(" ")};
do
  aws ec2 associate-iam-instance-profile \\
     --region ${region} \\
     --instance-id $\{ID\} \\
     --iam-instance-profile Name=${SSM_INSTANCE_PROFILE}
done`,

  iac: `
resource "aws_iam_instance_profile" "${SSM_INSTANCE_PROFILE}" {
  name = "${SSM_INSTANCE_PROFILE}"
  role = aws_iam_role.default_host_management_role.name
}

### Attach the instance profile to the instances
### See https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/instance#iam_instance_profile`,
});

const serviceSettingUpdate = (region: string, accountId: string) => ({
  shell: `aws ssm update-service-setting \\
  --region ${region} \\
  --setting-id arn:aws:ssm:${region}:${accountId}:servicesetting/ssm/managed-instance/default-ec2-instance-management-role \\
  --setting-value ${SSM_ROLE_NAME}`,

  iac: `locals {
  default_host_management_role = trimprefix("\${aws_iam_role.default_host_management_role.path}\${aws_iam_role.default_host_management_role.name}", "/")

  # Settings of the Quick Start for SSM Default Host Management
  ssm_service_settings = {
    # Default host management service setting
    "/ssm/managed-instance/default-ec2-instance-management-role" : aws_iam_role.default_host_management_role
    # Explorer service settings
    "/ssm/opsitem/ssm-patchmanager" : "Enabled"
    "/ssm/opsitem/EC2" : "Enabled"
    "/ssm/opsdata/ConfigCompliance" : "Enabled"
    "/ssm/opsdata/Association" : "Enabled"
    "/ssm/opsdata/OpsData-TrustedAdvisor" : "Enabled"
    "/ssm/opsdata/ComputeOptimizer" : "Enabled"
    "/ssm/opsdata/SupportCenterCase" : "Enabled"
    "/ssm/opsdata/ExplorerOnboarded" : "true"
  }
}

resource "aws_ssm_service_setting" "ssm_service_settings" {
  for_each = toset(
    keys(local.ssm_service_settings)
  )

  setting_id    = "arn:aws:ssm:${region}:${accountId}:servicesetting\${each.key}"
  setting_value = local.ssm_service_settings[each.key]

  lifecycle {
    precondition {
      condition     = can(local.ssm_service_settings[each.key])
      error_message = "The setting \\"\${each.key}\\" is not recognized as a valid SSM service setting for Default Host Management."
    }
  }
}

resource "aws_ssm_association" "update_ssm_agent" {

  name                = "AWS-UpdateSSMAgent"
  association_name    = "UpdateSSMAgent-do-not-delete"
  schedule_expression = "rate(14 days)"

  targets {
    key    = "InstanceIds"
    values = ["*"]
  }
}`,
});

const SSM_HEADER_COMMENT = {
  shell:
    "# AWS CLI commands to enable AWS Systems Manager Agent (SSM Agent) on your instances",
  iac: "# Terraform configuration to enable AWS Systems Manager Agent (SSM Agent) on your instances",
};

const VPC_HEADER_COMMENT = {
  shell:
    "# AWS CLI commands to enable VPC endpoints for AWS Systems Manager (SSM)",
  iac: "# Terraform configuration to enable VPC endpoints for AWS Systems Manager (SSM)",
};

const MISSING_INPUT = {
  shell: "Please provide VPC IDs to generate commands.",
  iac: "Please provide VPC IDs to generate Terraform configuration.",
};

const commandsFor = (
  accountId: string,
  region: string,
  type: "iac" | "shell",
  vpcIds?: string[],
  instanceIds?: string[]
) =>
  vpcIds?.length
    ? [
        VPC_HEADER_COMMENT[type],
        updateVpcEndpoints(region, vpcIds)[type],
        SSM_HEADER_COMMENT[type],
        SSM_INSTANCE_ROLE[type],
        instanceIds?.length
          ? instanceProfileUpdate(region, instanceIds)[type]
          : serviceSettingUpdate(region, accountId)[type],
      ].join("\n")
    : MISSING_INPUT[type];

const ssmInstructions: FrontendInstaller<
  typeof SshComponents
>["iam-write"]["instructions"] = (
  _component,
  id,
  _item,
  _field,
  state,
  setState
) => {
  const [integrationType, accountId] = id.split(":");
  const region = state.region ?? "us-west-2";
  const updateState = (key: string) => (value: any) =>
    setState({ ...state, [key]: value });
  return integrationType === "aws"
    ? {
        help: (
          <>
            <Paragraph>
              To use P0 for SSH access in AWS, start by enabling AWS Systems
              Manager in your accounts. To support SSH access requests to
              instances in a private VPC, configure VPC endpoints for AWS
              Session Manager.
            </Paragraph>
            <Paragraph>
              Provide your region, VPC IDs and instance IDs to generate commands
              for enabling VPC endpoints and configuring AWS Systems Manager for
              SSH access to instances.
            </Paragraph>
            <Paragraph>
              Regions (
              <Link
                to="https://docs.aws.amazon.com/general/latest/gr/ssm.html#ssm_region"
                target="_blank"
                rel="noopener noreferrer"
              >
                Supported regions
              </Link>
              )
            </Paragraph>
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select region"
              onChange={updateState("region")}
              defaultValue={region}
              options={VALID_AWS_SSM_REGIONS.sort().map((region) => ({
                label: region,
                value: region,
              }))}
            />
            <Paragraph>
              VPC IDs (press <Text keyboard>Enter</Text> or{" "}
              <Text keyboard>Space</Text> after each input)
            </Paragraph>
            <TagInput
              tags={state.vpcIds ?? []}
              onUpdate={updateState("vpcIds")}
              data-testid="vpcIds"
            />
            <Paragraph>
              <i>(Optional)</i> Instance IDs (press <Text keyboard>Enter</Text>{" "}
              or <Text keyboard>Space</Text> after each each input)
            </Paragraph>
            <TagInput
              tags={state.instanceIds ?? []}
              onUpdate={updateState("instanceIds")}
              data-testid="instanceIds"
            />
          </>
        ),
        commands: {
          console: (
            <Paragraph>
              <ul>
                <li>
                  <Link
                    to="https://docs.aws.amazon.com/systems-manager/latest/userguide/setup-instance-permissions.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Enable host management for AWS Systems Manager in the AWS
                    console.
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://docs.aws.amazon.com/general/latest/gr/ssm.html#ssm_region"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Supported regions
                  </Link>
                </li>
              </ul>
            </Paragraph>
          ),
          shell: [
            {
              command: commandsFor(
                accountId,
                region,
                "shell",
                state.vpcIds,
                state.instanceIds
              ),
            },
          ],
          iac: [
            {
              command: commandsFor(
                accountId,
                region,
                "iac",
                state.vpcIds,
                state.instanceIds
              ),
            },
          ],
        },
      }
    : {
        help: <Paragraph>Click Next to continue</Paragraph>,
        // TODO: skip and move to next steps for scenarios where there are no instructions
      };
};

export const sshInstaller: SshInstaller = {
  "iam-write": {
    optionProvider: listingInstalls,
    instructions: ssmInstructions,
    items: {
      groupKey: {
        errorElement: (
          <>
            Invalid AWS TAG Key. Expected a string of 1 to 128 characters (
            <Link
              target="_blank"
              to="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/Using_Tags.html#tag-restrictions"
            >
              Learn more
            </Link>
            )
          </>
        ),
      },
      isSudoEnabled: sudoSshInstaller,
    },
  },
};
