import { Modal, Segmented } from "antd";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { useRoutingRulesStore } from "store/routingRulesStore";

import { RoutingEditAlert } from "..";
import CodeEditor from "../CodeEditor";
import { RoutingRuleEditorProvider } from "../store/RoutingRuleEditorContext";
import { DEFAULT_RULE, EXAMPLE_RULE } from "../utils";
import { RuleEditor } from "./RuleEditor";

type Props = {
  isNewRule: boolean;
  handleSubmitRule: (rule: any) => Promise<void>;
};

export const EditRuleModal: React.FC<Props> = ({
  isNewRule,
  handleSubmitRule,
}) => {
  const {
    showConfigRulesModal,
    selectedRule,
    selectedYamlRule,
    setSelectedRule,
    setShowConfigRulesModal,
  } = useRoutingRulesStore();

  const [useYaml, setUseYaml] = useState(false);

  const setDefaultRule = useCallback(() => {
    setSelectedRule(DEFAULT_RULE);
  }, [setSelectedRule]);

  const setSampleExample = useCallback(() => {
    setSelectedRule(EXAMPLE_RULE);
  }, [setSelectedRule]);

  const closeModal = useCallback(
    () => setShowConfigRulesModal(false),
    [setShowConfigRulesModal]
  );

  return (
    <Modal
      open={showConfigRulesModal}
      style={{ top: 10 }}
      title={`Request Routing - ${isNewRule ? "New" : "Edit"}`}
      onCancel={closeModal}
      footer={null}
      width={1000}
      destroyOnClose
    >
      <RoutingEditAlert />
      <Segmented
        options={[
          {
            label: "Interactive Editor",
            value: "form",
          },
          {
            label: "YAML",
            value: "yaml",
          },
        ]}
        value={useYaml ? "yaml" : "form"}
        onChange={(e) => setUseYaml(e === "yaml")}
        style={{ marginBottom: 20 }}
      />
      {useYaml ? (
        <CodeEditor
          handleSubmitWorkflow={handleSubmitRule}
          rule={selectedYamlRule}
          setDefaultRule={setDefaultRule}
          setSampleExample={setSampleExample}
        />
      ) : (
        <RoutingRuleEditorProvider>
          <RuleEditor
            handleSubmitRule={handleSubmitRule}
            initialRule={
              !isNewRule && !isEmpty(selectedRule) ? selectedRule : undefined
            }
          />
        </RoutingRuleEditorProvider>
      )}
    </Modal>
  );
};
