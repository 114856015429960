import { AppContext, DevEnv } from "../../../../types/environment";
import {
  MANAGED_BY_P0,
  P0_TAG_NAME,
  PROVISION_USER_ACCESS_DOCUMENT,
} from "../constants";
import { awsRoleName, p0InstallCommands, p0InstallTerraform } from "./shared";

/** Identity policy for the P0IamManager role */
export const iamManagerPolicy = ({
  awsAccountId,
  roleName,
}: {
  awsAccountId: string;
  roleName: string;
}) => ({
  Version: "2012-10-17",
  Statement: [
    {
      Sid: "P0CanGetAndListPolicies",
      Effect: "Allow",
      Action: [
        "iam:GetPolicy",
        "iam:GetPolicyVersion",
        "iam:ListPolicyTags",
        "iam:ListPolicyVersions",
      ],
      Resource: "*",
    },
    {
      Sid: "P0CanManagePoliciesAndListResources",
      Effect: "Allow",
      Action: [
        "account:ListRegions",
        "iam:AddUserToGroup",
        "iam:AttachRolePolicy",
        "iam:AttachUserPolicy",
        "iam:CreatePolicy",
        "iam:CreatePolicyVersion",
        "iam:DeletePolicy",
        "iam:DeletePolicyVersion",
        "iam:DeleteRole",
        "iam:DeleteRolePolicy",
        "iam:DetachRolePolicy",
        "iam:DetachUserPolicy",
        "iam:GetRole",
        "iam:GetRolePolicy",
        "iam:GetSAMLProvider",
        "iam:GetUser",
        "iam:ListAccountAliases",
        "iam:ListAttachedGroupPolicies",
        "iam:ListAttachedRolePolicies",
        "iam:ListAttachedUserPolicies",
        "iam:ListGroupPolicies",
        "iam:ListGroups",
        "iam:ListGroupsForUser",
        "iam:ListPolicies",
        "iam:ListRolePolicies",
        "iam:ListRoles",
        "iam:ListUsers",
        "iam:ListUserTags",
        "iam:PutRolePolicy",
        "iam:RemoveUserFromGroup",
        "resource-explorer-2:ListIndexes",
        "resource-explorer-2:Search",
        "sagemaker:ListNotebookInstances",
      ],
      Resource: "*",
      Condition: {
        StringEquals: {
          "aws:ResourceAccount": awsAccountId,
        },
      },
    },
    {
      Sid: "P0CanManageSshAccess",
      Effect: "Allow",
      Action: [
        "ec2:DescribeTags",
        "ssm:AddTagsToResource",
        "ssm:GetDocument",
        "ssm:DescribeInstanceInformation",
        "ssm:DescribeSessions",
        "ssm:GetCommandInvocation",
        "ssm:ListCommandInvocations",
        "ssm:TerminateSession",
      ],
      Resource: "*",
      Condition: {
        StringEquals: {
          "aws:ResourceAccount": awsAccountId,
        },
      },
    },
    {
      Sid: "P0CanProvisionUserForSshAccess",
      Effect: "Allow",
      Action: "ssm:SendCommand",
      Resource: [
        `arn:aws:ec2:*:${awsAccountId}:instance/*`,
        `arn:aws:ssm:*:${awsAccountId}:document/${PROVISION_USER_ACCESS_DOCUMENT.documentName}`,
      ],
    },
    {
      Sid: "P0CanManageKubernetesAccess",
      Effect: "Allow",
      Action: [
        "eks:CreateAccessEntry",
        "eks:DeleteAccessEntry",
        "eks:DescribeAccessEntry",
        "eks:UpdateAccessEntry",
      ],
      Resource: "*",
      Condition: {
        StringEquals: {
          "aws:ResourceAccount": awsAccountId,
        },
        ArnNotEquals: {
          "eks:principalArn": `arn:aws:iam::${awsAccountId}:role/${roleName}`,
        },
      },
    },
    {
      Sid: "P0CanManageSsoAssignments",
      Effect: "Allow",
      Action: [
        "iam:GetSAMLProvider",
        "identitystore:ListUsers",
        "sso:AttachCustomerManagedPolicyReferenceToPermissionSet",
        "sso:AttachManagedPolicyToPermissionSet",
        "sso:CreateAccountAssignment",
        "sso:CreatePermissionSet",
        "sso:DeleteAccountAssignment",
        "sso:DeletePermissionSet",
        "sso:DescribeAccountAssignmentCreationStatus",
        "sso:DescribeAccountAssignmentDeletionStatus",
        "sso:DescribePermissionSet",
        "sso:DescribePermissionSetProvisioningStatus",
        "sso:GetInlinePolicyForPermissionSet",
        "sso:ListAccountAssignments",
        "sso:ListInstances",
        "sso:ListManagedPoliciesInPermissionSet",
        "sso:ListCustomerManagedPolicyReferencesInPermissionSet",
        "sso:ListPermissionSets",
        "sso:ListTagsForResource",
        "sso:ProvisionPermissionSet",
        "sso:PutInlinePolicyToPermissionSet",
      ],
      Resource: "*",
      Condition: {
        StringEquals: {
          "aws:ResourceAccount": awsAccountId,
        },
      },
    },
    {
      Sid: "P0CanCreateSsoRolesOnly",
      Effect: "Allow",
      Action: "iam:CreateRole",
      // Granting on all resources would allow an attacker that compromised P0 to
      // privilege escalate: they could create a role with an arbitrary trust boundary.
      // Prevent this by restricting created roles to only those generated via AWS SSO;
      // such roles are always restricted to a valid SSO identity. Note that forward
      // slashes are not allowed in role names, so this ARN can not be impersonated by
      // manually including this previx in the role name.
      Resource: `arn:aws:iam::${awsAccountId}:role/aws-reserved/sso.amazonaws.com/*`,
    },
    {
      Sid: "P0CanTagPoliciesAndRoles",
      Effect: "Allow",
      Action: ["iam:CreatePolicy", "iam:TagPolicy"],
      Resource: "*",
      Condition: {
        StringEquals: {
          [`aws:RequestTag/${P0_TAG_NAME}`]: MANAGED_BY_P0,
          "aws:ResourceAccount": awsAccountId,
        },
      },
    },
    {
      Sid: "P0CanNotAlterItsOwnRole",
      Effect: "Deny",
      Action: [
        "iam:AttachRole*",
        "iam:DeleteRole*",
        "iam:DetachRole*",
        "iam:PutRole*",
        "iam:TagRole",
        "iam:UntagRole",
        "iam:UpdateRole*",
      ],
      Resource: `arn:aws:iam::${awsAccountId}:role/${roleName}`,
    },
    {
      Sid: "P0CanNotCreateSshUserProvisioningDocument",
      Effect: "Deny",
      Action: ["ssm:CreateDocument"],
      Resource: `arn:aws:ssm:*:${awsAccountId}:document/${PROVISION_USER_ACCESS_DOCUMENT.documentName}`,
    },
    {
      Sid: "P0CanNotAssumeRoles",
      Effect: "Deny",
      Action: "sts:AssumeRole",
      Resource: "*",
    },
  ],
});

export const iamWriteInstallCommands = (
  context: DevEnv,
  args: {
    gcloudServiceAccountId: string;
    awsAccountId: string;
  }
) => {
  const use = "iam-write";
  const roleName = awsRoleName(context, use);
  const policy = iamManagerPolicy({ ...args, roleName });
  return p0InstallCommands({
    ...args,
    policy,
    roleName,
    use,
  });
};

export const iamWriteTerraform = (
  gcloudServiceAccountId: string,
  context: DevEnv
) => {
  const use = "iam-write";
  const roleName = awsRoleName(context, use);
  const policy = iamManagerPolicy({
    awsAccountId: "${local.aws_account_id}",
    roleName,
  });
  return p0InstallTerraform({
    gcloudServiceAccountId,
    policy,
    roleName,
    use: "iam-write",
  });
};

export const expectedIamManagerPolicy = (
  awsAccountId: string,
  context: AppContext
) =>
  iamManagerPolicy({
    awsAccountId,
    roleName: awsRoleName(context, "iam-write"),
  });
