import { GraphColumnType } from "components/GraphTable/GraphTable";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { ClipDiv } from "components/divs";
import { integrationTitles } from "shared/assessment/constants";
import { BindingNode } from "shared/types/assessment/data";

import { ConditionDisplay } from "../../cells/Condition";
import { PermissionAggregate } from "../../cells/PermissionAggregate";
import { PermissionSet } from "../../cells/PermissionSet";
import { PermissionTitle } from "../../cells/PermissionTitle";
import { PrincipalCell } from "../../cells/Principal";
import { Resource } from "../../cells/Resource";
import { RiskAggregate, riskSorter } from "../../cells/RiskAggregate";
import { ShowHideTerm } from "../../cells/ShowHide";
import { genericExport } from "../../export";
import { stringSorter } from "../../sort";
import { AssessmentColumnProps } from "../columns";

export const bindingColumns = (
  props: AssessmentColumnProps
): GraphColumnType<BindingNode>[] => [
  {
    key: "principal",
    title: "Principal",
    sorter: (left, right) =>
      stringSorter(left.data.principal, right.data.principal),
    render: (_, node) => (
      <ClipDiv>
        <PrincipalCell
          principalData={{
            ...node.data,
            label: node.data.principal,
          }}
          termPrefix="grant:"
          {...props}
        />
      </ClipDiv>
    ),
    export: genericExport((node) => node.data.principal),
  },
  {
    key: "permissionSet",
    title: integrationTitles[props.integration]["permissionSet"],
    sorter: (left, right) =>
      stringSorter(
        left.data.permissionSet ?? "",
        right.data.permissionSet ?? ""
      ),
    render: (_, { data: { permissionSet } }) => (
      <PermissionSet permissionSet={permissionSet} {...props} />
    ),
    export: genericExport((node) => node.data.permissionSet),
  },
  {
    key: "resource",
    title: "Resource",
    sorter: (left, right) =>
      stringSorter(left.data.resource, right.data.resource),
    render: (_, { data: { resource } }) => {
      if (!resource) return null;
      const resources = resource.split(",");
      return resources.length <= 2 ? (
        <>
          {resources.map((r, ix) => (
            <ClipDiv key={ix}>
              <Resource resource={r} {...props} />
            </ClipDiv>
          ))}
        </>
      ) : (
        <GraphTooltip
          title={
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {resources.map((r, ix) => (
                <div key={ix}>
                  <Resource resource={r} {...props} />
                </div>
              ))}
            </div>
          }
        >
          <ClipDiv>
            <Resource resource={resources[0]} {...props} />
            <div>and {resources.length - 1} more</div>
          </ClipDiv>
        </GraphTooltip>
      );
    },
    export: genericExport((node) => node.data.resource),
  },
  {
    key: "condition",
    title: "Condition",
    sorter: ({ data: { condition: l } }, { data: { condition: r } }) => {
      return stringSorter(
        l?.title ?? l?.expression ?? "",
        r?.title ?? r?.expression ?? ""
      );
    },
    render: (_, { data: { condition } }) =>
      condition && (
        <ClipDiv>
          <GraphTooltip
            title={
              <div style={{ maxWidth: "400px" }}>
                <ConditionDisplay condition={condition} />
                <ShowHideTerm
                  term={`condition:"${
                    condition.title ?? condition.expression
                  }"`}
                  name="conditions"
                  {...props}
                />
              </div>
            }
            width="400px"
          >
            {condition.title}
          </GraphTooltip>
        </ClipDiv>
      ),
    export: genericExport((node) => node.data.condition?.expression),
  },
  {
    key: "risk",
    title: "Risks",
    sorter: (left, right) =>
      riskSorter(left.aggregates.risks, right.aggregates.risks),
    render: (_, node) => <RiskAggregate risks={node.aggregates.risks} />,
    export: genericExport((node) => node.aggregates.risks),
  },
  {
    key: "permissions",
    title: (
      <PermissionTitle
        title={integrationTitles[props.integration]["permissions"]}
      />
    ),
    render: (_, node) => (
      <PermissionAggregate
        permissions={node.aggregates.permissions}
        {...props}
      />
    ),
    export: genericExport((node) => ({
      used: node.aggregates?.permissions?.used?.length ?? 0,
      unused: node.aggregates?.permissions?.unused?.length ?? 0,
      unknown: node.aggregates?.permissions?.unknown?.length ?? 0,
    })),
  },
];
