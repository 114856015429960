import { Typography } from "antd";
import { FrontendInstallContext } from "install/types";
import pluralize from "pluralize";
import { ReactNode } from "react";
import { installedItem } from "shared/install/installed";
import { Instructions } from "shared/install/types";
import { GCLOUD_COMPONENT_SETUP } from "shared/integrations/resources/gcloud/constants";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";
import { rootConfig } from "shared/integrations/resources/gcloud/util";

import { iamResource, installIamCommands, installIamTerraform } from "./iam";
import { SharingTemporaryLiftInstructions } from "./sharing-restriction";

const { Paragraph } = Typography;

export const gcloudAccessLogsInstructor =
  (resourceName: "organization" | "project") =>
  (
    context: FrontendInstallContext<GcloudIntegration>,
    projectId: string
  ): Instructions<ReactNode> => {
    const isOrgInstall = resourceName === "organization";
    const commandName = pluralize(resourceName);
    const targetId = isOrgInstall
      ? rootConfig(context.config).organizationId
      : projectId;
    const resource = iamResource(resourceName, context, projectId);
    // Note that we may need to lift the sharing restriction even if the installation
    // is org-level, since the Pub/Sub topic is inside a project
    return {
      help: (
        <>
          To configure access logging, follow the steps below. These will:
          <ul>
            <li>Enable access logging for all services.</li>
            <li>Grant P0 access to create a logging sink.</li>
          </ul>
          {installedItem("sharing-restriction", context.config, projectId) ? (
            <SharingTemporaryLiftInstructions
              context={context}
              id={projectId}
            />
          ) : null}
        </>
      ),
      commands: {
        shell: [
          {
            header: <>First, enable access logging for all services:</>,
            command: `gcloud ${commandName} set-iam-policy ${targetId} <( \\
  gcloud ${commandName} get-iam-policy ${targetId} --format=json | \\
  python3 -c '
import json, sys

policy = json.load(sys.stdin)
configs = policy.get("auditConfigs", [])
policy["auditConfigs"] = [
  *[c for c in configs if c["service"] != "allServices"] ,
  {
    "auditLogConfigs": [
      {"logType": "ADMIN_READ"},
      {"logType": "DATA_WRITE"},
      {"logType": "DATA_READ"},
    ],
    "service": "allServices",
  }
]
print(json.dumps(policy))
  ')`,
          },
          {
            header: <>Next, grant P0 access to create a logging sink:</>,
            command: installIamCommands(GCLOUD_COMPONENT_SETUP["access-logs"], {
              ...resource,
              ...rootConfig(context.config),
            }),
          },
        ],
        iac: [
          {
            header: (
              <Paragraph>1. Enable access logging for all services:</Paragraph>
            ),
            command: `# Enable data access logging for P0 IAM assessment usage monitoring
${
  resourceName === "organization"
    ? `resource "google_organization_iam_audit_config" "p0_audit_logging" {
  org_id = "${targetId}"`
    : `resource "google_project_iam_audit_config" "p0_${projectId}_audit_logging" {
  project = "${projectId}"`
}
  service = "allServices"
  audit_log_config {
    log_type = "ADMIN_READ"
  }
  audit_log_config {
    log_type = "DATA_READ"
  }
  audit_log_config {
    log_type = "DATA_WRITE"
  }
}
    `,
          },
          {
            header: (
              <Paragraph>
                2. Grant P0 access to create a logging sink:
              </Paragraph>
            ),
            command: installIamTerraform(
              GCLOUD_COMPONENT_SETUP["access-logs"],
              {
                ...resource,
                ...rootConfig(context.config),
              }
            ),
          },
        ],
      },
    };
  };
