import { compact } from "lodash";

import { StateError } from "../types/error";
import { NodeSearch } from "./search";
import { Node } from "./types";

const WHITESPACE = /\s/;
const EXACT = /"(.*)"/;

// Represents [type(=|:)][attribute:][!]term where brackets indicate optionality
const DSL_PATTERN = /([^"]*?[^\\][=:])?([^"]*?[^\\]:)?(!?)(.*)/;

type NodeMapping<G extends object, K extends keyof G> = (node: Node<G, K>) => {
  /** Values used to compare against `term` or `type:term` searches */
  keys?: string[];
  /** Mapping used to evaluate `type:attribute:term` searches */
  attributes?: Record<string, string[]>;
};

type DslAliases = Partial<Record<string, string>>;

export type DslMapping<G extends object> = {
  [K in keyof G]?: NodeMapping<G, K>;
};

const dealias = (aliases: DslAliases, term: string) => {
  const found = [...Object.keys(aliases)].find((k) => term.startsWith(k));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return found ? term.replace(found, aliases[found]!) : undefined;
};

// Implements the "termMatch" portion of the DSL
const predicate = <G extends object>(mapping: DslMapping<G>, term: string) => {
  const match = term.match(DSL_PATTERN);
  if (!match) {
    throw new StateError({ term }, "Could not parse search term");
  }
  const [_, typeTerm, attributeTerm, invert, keyword] = match;
  // Remove dangling ":"
  const type = typeTerm?.slice(0, typeTerm.length - 1);
  const attribute = attributeTerm?.slice(0, attributeTerm.length - 1);
  const exactMatch = keyword.match(EXACT);
  return {
    // No boost for "!keyword" searches to avoid improper removal
    keyword: invert ? "" : exactMatch ? exactMatch[1] : keyword,
    keytype: type,
    abortOnTypeMismatch: typeTerm?.at(-1) === "=",
    predicate: (node: Node<G, keyof G>) => {
      const nodeData = node.data as any;
      if (type && node.type !== type) return false;
      const nodeMapping = mapping[type as keyof G]?.(node) ?? {};
      const values = attribute
        ? compact(
            nodeMapping.attributes?.[attribute] ?? [nodeData[attribute]]
          ).map(String)
        : nodeMapping.keys ?? [node.key];
      const match = !!values?.find((v) =>
        exactMatch ? v === exactMatch[1] : v?.includes(keyword)
      );
      return match !== !!invert;
    },
  };
};

/** Parses a graph search DSL
 *
 * DSL:
 * Produces a preset that finds all binding nodes that can reach:
 *   term - A node containing term
 *   !term - FA node that does not contain term
 *   "term" - A node exactly matching term
 *   !"term" - A node that does not exactly match term
 *   type:{termMatch} - A node that matches type with term match obeying above rules
 *   type: - A node that matches type
 *   type:attribute:{termMatch} - A node that type type and whose attribute matches term-match rules
 *   {via}->{node} - A node matching {node} term, reachable via {via} term
 * Can also construct a preset that finds all binding nodes that can not reach matches,
 * using ^{...}
 *
 * For each node, term will match the node key, unless the node type appears
 * in the `mapping` parameter, in which case the node's string value will be
 * determined from the mapping value for that node's type.
 *
 * Aliases will string substitute term matches.
 *
 * Example:
 *
 *    ^permissionType:!"unused"->permission:
 *
 * finds all bindings that can not reach a used or unknown permission (this is the expression
 * for the "Unused Grants" preset) when the assessment DSL mapping is used.
 *
 * When the assessment aliases are used, the above example is also equivalent to
 *
 *    ^!unused:
 *
 */
export const parse = <G extends object>(
  mapping: DslMapping<G>,
  aliases: DslAliases
) => {
  const viaDealias = (input: string): string[] =>
    input.split("->").flatMap((s) => {
      const xform = dealias(aliases, s);
      return xform ? viaDealias(xform) : [s];
    });

  return (search: string): NodeSearch<G>[] => {
    const trimmed = search.trim();
    if (!trimmed) return [];
    const terms = search.trim().split(WHITESPACE);
    return terms.map((fullTerm) => {
      let term = fullTerm;
      const invert = term.startsWith("^");
      if (invert) term = term.slice(1);
      const [...allTerms] = viaDealias(term);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- viaDealias always returns at least one value
      const toValue = predicate(mapping, allTerms.at(-1)!);
      return {
        ...toValue,
        term: fullTerm,
        via:
          allTerms.length > 1
            ? allTerms
                .slice(0, -1)
                .map((viaTerm) => predicate(mapping, viaTerm).predicate)
            : [],
        invert,
      };
    });
  };
};
