import { ArrowRightOutlined, CheckCircleFilled } from "@ant-design/icons";
import { Button, Card, Col, Image, List, Row, Typography, message } from "antd";
import { Splash } from "components/Splash";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { installedItems } from "shared/install/installed";

import { GcloudIntegration } from "../../../../shared/integrations/resources/gcloud/types";
import { useGuardedEffect } from "../../hooks/useGuardedEffect";
import { useFirestoreDoc } from "../../providers/FirestoreProvider";
import { useOnboardingStore } from "../../store/onboardingStore";
import { Tenant } from "../Login";
import { useAuthFetch, useUser } from "../Login/hook";
import {
  StyledCardTitle,
  StyledCol,
  StyledIamAudit,
  StyledLink,
  StyledListContent,
} from "./styles";

const { Title, Paragraph, Text } = Typography;

const handleError = (err: any) => {
  message.error(err);
};

const Success: React.FC = () => {
  const { cloudProvider, setCloudProvider } = useOnboardingStore();
  const navigate = useNavigate();
  const { orgSlug } = useParams();
  const authFetch = useAuthFetch();
  const { user } = useUser();
  const tenantId = useContext(Tenant);
  const [submitting, setSubmitting] = useState(false);
  const posthog = usePostHog();

  const getData = useCallback(() => {
    if (cloudProvider === "gcp") {
      return ["Conduct an IAM assessment for your Google Cloud"];
    }

    return [
      `Grant granular least-privileged access to: ${cloudProvider?.toUpperCase()}.`,
    ];
  }, [cloudProvider]);

  const { doc: gcloudDoc } = useFirestoreDoc<GcloudIntegration>(
    `o/${tenantId}/integrations/gcloud`,
    {
      live: true,
    }
  );

  useGuardedEffect(
    async () => {
      const response = await authFetch("account", {
        method: "GET",
      });
      if (!response) return;
      const data = await response.json();
      setCloudProvider(data.cloudStack.cloudStack[0]);
    },
    handleError,
    [authFetch]
  );

  // For the first run we take the first project that is installed
  const installedProjectId = useMemo(() => {
    const auditItems = gcloudDoc
      ? installedItems("iam-assessment", gcloudDoc.data)
      : {};

    return Object.keys(auditItems)[0];
  }, [gcloudDoc]);

  const handleStartAuditClick = useCallback(async () => {
    if (installedProjectId) {
      setSubmitting(true);
      const response = await authFetch("iam-audit/assessments", {
        method: "POST",
        json: {
          assessment: {
            name: "First Assessment",
            frequency: {
              unit: "weeks",
              amount: 1,
            },
            targets: [
              {
                type: "project",
                id: installedProjectId,
                integration: "gcloud",
              },
            ],
          },
          runImmediately: true,
        },
      });
      posthog?.capture("onboarding_iam_assessment_submitted", {
        email: user?.email,
        org: orgSlug,
      });

      setSubmitting(false);
      if (response?.ok) {
        navigate(`/o/${orgSlug}/iam-assessments`);
      }
    }
  }, [authFetch, installedProjectId, navigate, orgSlug, posthog, user?.email]);

  const demoUrl = useCallback(() => {
    switch (cloudProvider) {
      case "aws":
        return "/aws-policy.gif";
      case "gcp":
        return "/gcloud-resource.gif";
      case "snowflake":
        return "/snowflake.gif";
      default:
        return "/aws-policy.gif";
    }
  }, [cloudProvider]);

  return (
    <Splash overrideColumn>
      <Col xs={22} md={16} lg={14} xl={12}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Title level={4}>
              Congratulations, you now have immediate access to:
            </Title>
            <List
              dataSource={getData()}
              renderItem={(item) => (
                <List.Item>
                  <StyledListContent>
                    <CheckCircleFilled />
                    <Paragraph>{item}</Paragraph>
                  </StyledListContent>
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <StyledCol xs={24}>
            {cloudProvider === "gcp" ? (
              <Card
                title={
                  <StyledIamAudit>
                    Start a New Assessment
                    <Button
                      type="primary"
                      onClick={handleStartAuditClick}
                      loading={submitting}
                      disabled={!installedProjectId}
                    >
                      Start
                    </Button>
                  </StyledIamAudit>
                }
                bordered={false}
              >
                <Image
                  preview={false}
                  src={"/demo_assessment.gif"}
                  alt="P0"
                  width={"100%"}
                />
                <StyledLink>
                  <Link to={`/o/${orgSlug}/integrations`}>
                    Add Additional Resources
                  </Link>
                  <ArrowRightOutlined color="blue" />
                </StyledLink>
              </Card>
            ) : (
              <Card
                title={
                  <StyledCardTitle>
                    <Title level={5}>Create your first access request:</Title>
                    <Paragraph>
                      Login to your Slack workspace and type{" "}
                      <Text keyboard>/p0 request</Text> to request access.
                    </Paragraph>
                  </StyledCardTitle>
                }
                bordered={false}
              >
                <Image
                  preview={false}
                  src={demoUrl()}
                  alt="P0"
                  height={380}
                  width={480}
                />
                <StyledLink>
                  <Link to={`/o/${orgSlug}/integrations`}>
                    Add Additional Resources
                  </Link>
                  <ArrowRightOutlined color="blue" />
                </StyledLink>
              </Card>
            )}
          </StyledCol>
        </Row>
      </Col>
    </Splash>
  );
};

export default Success;
