import { InfoCircleTwoTone } from "@ant-design/icons";
import { List } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { sortBy } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { SECONDS } from "shared/time";
import { CredentialConsumer } from "shared/types/assessment/data/base";
import { assertNever } from "utils/assert";

import { PrincipalCell, PrincipalLink } from "./Principal";

export const ConsumersList: React.FC<{
  consumers: CredentialConsumer[];
}> = ({ consumers }) => (
  <List
    style={{
      maxHeight: "280px",
      paddingRight: "15px",
      overflowY: "auto",
    }}
  >
    {sortBy(consumers, ["usageCount"]).map((c, ix) => {
      return (
        <List.Item key={ix}>
          {c.type === "ip" ? (
            <>
              {c.userAgents?.length && (
                <>
                  <GraphTooltip
                    title={
                      <div>
                        User Agents: <br />{" "}
                        {c.userAgents.slice(0, 3).join(", ")}{" "}
                        {c.userAgents.length > 3
                          ? `(and ${c.userAgents.length - 3} others)`
                          : null}
                      </div>
                    }
                  >
                    <InfoCircleTwoTone />
                  </GraphTooltip>
                  &nbsp;
                </>
              )}
              <Link
                to={`https://www.geolocation.com/en_us?ip=${c.label}`}
                target="_blank"
              >
                {c.label} ({c.organization})
              </Link>
            </>
          ) : c.type === "principal" ? (
            <>
              <PrincipalLink data={c} type="short" show="binding" />
              &nbsp;
              <PrincipalCell principalData={c} />
            </>
          ) : (
            assertNever(c)
          )}
          &nbsp; (most recently on{" "}
          {moment.unix(c.lastUsedTime / SECONDS).format("LL")})
        </List.Item>
      );
    })}
  </List>
);
