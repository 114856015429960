import { ExportOutlined } from "@ant-design/icons";
import { Space, Spin, Table } from "antd";
import { ColumnType, TableProps } from "antd/lib/table";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { SelectedAssessmentContext } from "components/Assessment/contexts/SelectedAssessmentContext";
import { Export } from "components/Export";
import { SpaceBetweenDiv } from "components/divs";
import { isEqual, omit } from "lodash";
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";

import { useGuardedEffect } from "../../hooks/useGuardedEffect";
import {
  AggregatedGraph,
  AggregatedNode,
  aggregate,
} from "../../shared/graph/aggregate";
import { interruptibleDiscover } from "../../shared/graph/discover";
import { NodePredicate, NodeSearch } from "../../shared/graph/search";
import { DirectedGraph, Reducers } from "../../shared/graph/types";
import { sleep } from "../../shared/util/sleep";
import { GraphSearch, GraphSearchProps } from "./GraphSearch";

export interface GraphTableProps<
  G extends object,
  K extends keyof G,
  A extends object,
> extends Omit<GraphSearchProps, "isSearching">,
    TableProps<AggregatedNode<G, K, A>> {
  columns: GraphColumnType<AggregatedNode<G, K, A>>[];
  from: NodePredicate<G>;
  graph: DirectedGraph<G>;
  reducers: Reducers<G, A>;
  search: NodeSearch<G>[];
  searchExtra?: React.ReactNode;
  onSearch?: (nodes: AggregatedNode<G, K, A>[]) => void;
  onSelection?: (items: AggregatedNode<G, K, A>[]) => void;
}

export type GraphColumnType<RecordType> = ColumnType<RecordType> & {
  export?: {
    toTsvCell: (record: RecordType) => string;
    toJsonObject: (record: RecordType) => any;
  };
};

// Amount of time to wait before showing a search spinner
const INDICATED_SEARCH_MS = 50;

const logError = (error: any) => console.error(error);

const TableWrapper = styled.div`
  .ant-table.ant-table-small {
    font-size: 13px;
    tr > td {
      padding: 6px 6px;
    }
  }
  .ant-pagination {
    font-size: 13px;
  }
  .ant-pagination-options .ant-select {
    font-size: 13px;
  }
`;

export const GraphTable = <
  G extends object,
  K extends keyof G,
  A extends object,
>({
  columns,
  controls,
  from,
  frozen,
  graph,
  onSelection,
  onSearch,
  reducers,
  search,
  searchExtra,
  showOptions,
  ...tableProps
}: GraphTableProps<G, K, A>): ReactElement => {
  const { assessment } = useContext(SelectedAssessmentContext);
  const { scopeKey } = useContext(ScopeContext);

  const [searched, setSearched] = useState<AggregatedGraph<G, A>>();

  // Keep track of selected rows, as only selected rows will be exported
  const [selected, setSelected] = useState<AggregatedNode<G, K, A>[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  // Used to render a search in-progress indicator in the search bar;
  // note that we can't render the spinner in the table, as it would
  // clear table state (filtering, pagination, etc.)
  const [isSearching, setIsSearching] = useState(false);
  // Ensure that table display matches graph data structure by hiding
  // table when search has not yet updated
  const [renderShow, setRenderShow] = useState<string>();

  const aggregated = useMemo(() => {
    const before = performance.now();
    const result = aggregate(graph, reducers);
    // TODO: send to analytics
    // eslint-disable-next-line no-console
    console.log(
      "Time to aggregate graph",
      (performance.now() - before).toFixed(1),
      "ms"
    );
    return result;
  }, [reducers, graph]);

  useGuardedEffect(
    async (cancellation) => {
      if (!aggregated) return;
      // Clear selection on search / show update to prevent filtered rows from staying in
      // selection; this is less complicated than re-filtering the selection
      setSelected([]);
      setSelectedKeys([]);
      // onSelection?.([], []);
      let isFinished = false;
      // Debounces "isSearching" indicator, so it only spins if search takes longer than
      // INDICATED_SEARCH_MS
      void sleep(INDICATED_SEARCH_MS)
        .then(() => {
          if (!isFinished) cancellation.guard(setIsSearching)(true);
        })
        .catch(console.error);
      // Allow time for controls to re-render before we halt render loop
      await sleep(10);
      const beforeSearch = performance.now();
      const result = await interruptibleDiscover(aggregated, from, search);
      // antd recursively flattens objects; it can take ant many seconds to flatten
      // just one page of top-level nodes; prevent this by removing the children altogether
      const noChildren = result.nodes.map((n) =>
        omit(n, "children")
      ) as AggregatedNode<G, K, A>[];
      // TODO: send to analytics
      // eslint-disable-next-line no-console
      console.log(
        "Time to search graph",
        (performance.now() - beforeSearch).toFixed(1),
        "ms"
      );
      isFinished = true;
      setIsSearching(false);
      if (cancellation.isCancelled) return;
      if (isEqual(noChildren, searched?.nodes)) return;
      setSearched({ nodes: noChildren });
      onSearch?.(noChildren);
      setRenderShow(controls.show);
    },
    logError,
    [aggregated, from, search, controls.show]
  );

  const exportableColumns = useMemo(
    () =>
      columns.filter((col) => col.key && col.export) as Required<
        Pick<GraphColumnType<AggregatedNode<G, keyof G, A>>, "export" | "key">
      >[],
    [columns]
  );

  const registerSelection = useCallback(
    (keys: React.Key[], values: AggregatedNode<G, K, A>[]) => {
      setSelectedKeys(keys);
      setSelected(values);
      onSelection?.(values);
    },
    [onSelection]
  );

  const toTsv = useMemo(
    () => async (data: AggregatedNode<G, keyof G, A>[]) => {
      const header = exportableColumns.map((col) => col.key);
      const rows = data.map((row) =>
        exportableColumns
          .map((col) => col.export.toTsvCell(row) || "")
          .join("\t")
      );
      return [header.join("\t"), ...rows].join("\n");
    },
    [exportableColumns]
  );

  const toJson = useMemo(
    () => async (data: AggregatedNode<G, keyof G, A>[]) =>
      JSON.stringify(
        data.map((row) =>
          Object.fromEntries(
            exportableColumns.map((col) => [
              col.key,
              col.export.toJsonObject(row),
            ])
          )
        ),
        undefined,
        2
      ),
    [exportableColumns]
  );

  return (
    <div data-testid="graph-table">
      {!searched ? (
        <div style={{ height: "430px" }}>
          <Spin />
        </div>
      ) : (
        <Space
          direction="vertical"
          style={{ width: "1000px", overflowX: "auto" }}
        >
          {/* <Space direction="horizontal" style={{ width: "100%" }}> */}
          <SpaceBetweenDiv style={{ alignItems: "center" }}>
            <GraphSearch
              controls={controls}
              frozen={frozen}
              isSearching={isSearching}
              showOptions={showOptions}
            />
            <Export
              data={selected.length ? selected : searched.nodes}
              filename={[assessment.doc?.data.name, scopeKey].join(" ")}
              primary={{
                label: "to TSV",
                buttonType: "default",
                icon: <ExportOutlined />,
                blob: toTsv,
                extension: "tsv",
              }}
              options={{
                json: {
                  label: "Export to JSON",
                  blob: toJson,
                  extension: "json",
                },
              }}
            />
            {/* </Space> */}
          </SpaceBetweenDiv>
          {searchExtra}
          {controls.show !== renderShow ? (
            <Spin />
          ) : (
            <TableWrapper>
              <Table
                dataSource={searched.nodes as AggregatedNode<G, K, A>[]}
                columns={columns}
                size="small"
                expandable={{ showExpandColumn: false }}
                data-testid="graph-table-inner-table"
                rowSelection={{
                  onChange: registerSelection,
                  selectedRowKeys: selectedKeys,
                  type: "checkbox",
                }}
                {...{ pagination: { hideOnSinglePage: true } }}
                {...tableProps}
              />
            </TableWrapper>
          )}
        </Space>
      )}
    </div>
  );
};
