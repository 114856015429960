import { ReactNode } from "react";

import { PermissionType } from "../../shared/types/permission";
import { AwsIconUrl } from "./Aws/Aws";
import { EntraIdIconUrl } from "./AzureAD/AzureAd";
import { GcloudIconUrl } from "./Gcloud/Gcloud";
import { GoogleWorkspaceIcon } from "./GoogleWorkspace/GoogleWorkspace";
import { K8sIconUrl } from "./Kubernetes/K8s";
import { Logo } from "./Logo";
import { OktaIconUrl } from "./Okta/Okta";
import { PagerdutyIconUrl } from "./Pagerduty/Pagerduty";
import { PostgresIconUrl } from "./Postgres";
import { SnowflakeIconUrl } from "./Snowflake/Snowflake";
import { SshIconUrl } from "./Ssh/Ssh";

export const PermissionIntegrationLogo: Record<PermissionType, ReactNode> = {
  aws: <Logo logo={AwsIconUrl} title="AWS" />,
  "azure-ad": <Logo logo={EntraIdIconUrl} title="Entra ID" />,
  gcloud: <Logo logo={GcloudIconUrl} title="Google Cloud" />,
  k8s: <Logo logo={K8sIconUrl} title="Kubernetes" />,
  snowflake: <Logo logo={SnowflakeIconUrl} title="Snowflake" />,
  okta: <Logo logo={OktaIconUrl} title="Okta" />,
  pg: <Logo logo={PostgresIconUrl} title="PostgreSQL" />,
  ssh: <Logo logo={SshIconUrl} title="SSH" />,
  workspace: <Logo logo={GoogleWorkspaceIcon} title="Google Workspace" />,
};

export const AllIntegrationLogos = {
  ...PermissionIntegrationLogo,
  workspace: <Logo logo={GoogleWorkspaceIcon} title="Google Workspace" />,
  pagerduty: <Logo logo={PagerdutyIconUrl} title="PagerDuty" />,
};
