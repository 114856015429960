import {
  ApiFilled,
  DatabaseFilled,
  FilterFilled,
  LinkOutlined,
  LogoutOutlined,
  MailFilled,
  ProfileFilled,
  SettingFilled,
} from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import { Image, Layout, Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import CreateAccountPage from "components/CreateAccount/v2/CreateAccountPage";
import { OidcRedirect } from "components/Login/pkce";
import { RequestRoutes } from "components/Requests/Routes";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ResourcesProvider } from "providers/ResourcesProvider";
import React, { useContext } from "react";
import { useParams } from "react-router";
import {
  Link,
  NavLink,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import styled from "styled-components";

import { useIsPrint } from "../../hooks/useIsPrint";
import { useScreen } from "../../hooks/useScreen";
import { PolicyRoutes } from "../../policies/Routes";
import { getNavLinkClassName } from "../../utils/activeNavLinkClassName";
import { AssessmentMenu, AssessmentRoutes } from "../Assessment/Routes";
import { OAuth2Redirect } from "../Integrations/OAuth2";
import { IntegrationRoutes } from "../Integrations/Routes";
import {
  AccountSelect,
  AuthzGuard,
  DeviceAuth,
  EmailCallbackHandler,
  Login,
  UserAuthz,
  UserRole,
} from "../Login";
import { useUser } from "../Login/hook";
import { snowflakeRestStateRoutes } from "../RestState/Routes";
import RoutingRules from "../Routing";
import { SandboxAlert, addSandboxMenu, sandboxRoutes } from "../Sandbox/Routes";
import { Settings } from "../Settings/Settings";
import { OnboardWorkflows } from "../Welcome/OnboardWorkflows";
import Success from "../Welcome/Success";
import { Welcome } from "../Welcome/Welcome";
import "./App.less";
import { Footer } from "./Footer";
import { LoggedInUserNavItem } from "./LoggedInUserNavItem";
import { OnboardingRedirect } from "./OnboardingRedirect";
import { Page } from "./Page";
import { AppRoutes } from "./routeConstants";

const StyledLogoImage = styled(Image)`
  margin-top: 10px;
`;

const StyledSmallLogoImage = styled(Image)`
  margin-top: 10px;
  margin-left: -20px;
`;

const { Sider, Content } = Layout;

const P0NavIcon: React.FC = () => {
  const { isDesktop: isDesktopOrLaptop } = useScreen();

  return (
    <Icon
      component={() =>
        isDesktopOrLaptop ? (
          <StyledLogoImage
            preview={false}
            src={"/p0-logomark.svg"}
            alt="P0"
            width={160}
          />
        ) : (
          <StyledSmallLogoImage
            preview={false}
            src={"/p0 dark logomark.png"}
            alt="P0"
            width={40}
            height={40}
          />
        )
      }
    />
  );
};

const topItems = (orgSlug: string | undefined) => [
  {
    key: "p0",
    title: "",
    label: (
      <Link
        reloadDocument
        to={`/o/${orgSlug}/requests`}
        style={{ display: "flex", alignItems: "center" }}
      >
        <P0NavIcon />
      </Link>
    ),
  },
  {
    key: "divider",
    type: "divider",
    className: "nav-divider logo-divider",
  },
];

const SidebarItems = (
  userAuthz: Set<UserRole>,
  flags: Record<string, any>,
  signOut: () => Promise<void>,
  isSandbox?: boolean
) => {
  const items: ItemType[] = [];
  items.push({
    key: AppRoutes.Requests,
    icon: <MailFilled />,
    label: (
      <NavLink to={AppRoutes.Requests} className={getNavLinkClassName}>
        {flags.showEvidenceStore ? "Activity" : "Access Requests"}
      </NavLink>
    ),
  });
  items.push({
    key: AppRoutes.RequestHistory,
    icon: <ProfileFilled />,
    label: (
      <NavLink to={AppRoutes.RequestHistory} className={getNavLinkClassName}>
        Request History
      </NavLink>
    ),
  });
  if (
    userAuthz.has("iamViewer") ||
    userAuthz.has("iamOwner") ||
    userAuthz.has("owner")
  ) {
    items.push(AssessmentMenu);
  }
  if (isSandbox) {
    addSandboxMenu(items, userAuthz.has("owner"));
  }
  if (userAuthz.has("owner") || userAuthz.has("restStateManager")) {
    if (flags.snowflakeRestStateManagement) {
      items.push({
        key: AppRoutes.RestState,
        icon: <DatabaseFilled />,
        label: (
          <NavLink
            to={`${AppRoutes.RestState}/snowflake`}
            className={getNavLinkClassName}
          >
            Rest State
          </NavLink>
        ),
      });
    }
  }

  if (userAuthz.has("owner") || userAuthz.has("approver")) {
    items.push(
      {
        key: AppRoutes.Routing,
        icon: <FilterFilled />,
        label: (
          <NavLink to={AppRoutes.Routing} className={getNavLinkClassName}>
            Routing
          </NavLink>
        ),
      },
      {
        key: AppRoutes.Integrations,
        icon: <ApiFilled />,
        label: (
          <NavLink to={AppRoutes.Integrations} className={getNavLinkClassName}>
            Integrations
          </NavLink>
        ),
      }
    );
  }
  if (userAuthz.has("owner")) {
    items.push({
      key: AppRoutes.Settings,
      icon: <SettingFilled />,
      label: (
        <NavLink to={AppRoutes.Settings} className={getNavLinkClassName}>
          Settings
        </NavLink>
      ),
    });
  }
  items.push({
    key: "logout",
    icon: <LogoutOutlined />,
    label: <div>Logout</div>,
    onClick: signOut,
  });
  items.push({
    key: "divider",
    type: "divider",
    className: "nav-divider",
  });
  items.push({
    key: "docs",
    icon: <LinkOutlined />,
    label: (
      <Link to="https://docs.p0.dev" target="_blank" rel="noreferrer">
        Documentation
      </Link>
    ),
  });
  return items;
};

const AppSider: React.FC<object> = () => {
  const userAuthz = useContext(UserAuthz);
  const {
    iamAssessment,
    snowflakeRestStateManagement,
    requestRouting,
    showEvidenceStore,
  } = useFlags();
  const isPrint = useIsPrint();
  const { tenant, signOut } = useUser();
  const { orgSlug } = useParams();

  // Note that style is hard-coded on the Sider element, so we can't control
  // it with CSS. Instead, just delete the sider for printing.
  return !isPrint ? (
    <Sider breakpoint="lg">
      <Menu
        className="p0-nav"
        mode="inline"
        theme="dark"
        selectable={false}
        items={topItems(orgSlug)}
      />
      <Menu
        className="p0-nav tall"
        theme="dark"
        mode="inline"
        items={SidebarItems(
          userAuthz,
          {
            iamAssessment,
            snowflakeRestStateManagement,
            requestRouting,
            showEvidenceStore,
          },
          signOut,
          tenant.state === "found" && tenant.isSandbox
        )}
      />
      <LoggedInUserNavItem />
    </Sider>
  ) : null;
};

const AppLayout: React.FC<object> = () => {
  const { tenant } = useUser();
  return (
    <Layout>
      <AppSider />
      <Layout className="site-layout">
        <Content className="site-layout-background">
          {tenant.state === "found" && tenant.isSandbox && <SandboxAlert />}
          <Outlet />
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export const App: React.FC = () => {
  const flags = useFlags();

  return (
    <Routes>
      {PolicyRoutes}
      <Route path="/integration/auth/_redirect" element={<OAuth2Redirect />} />
      <Route path="/oidc/auth/_redirect" element={<OidcRedirect />} />
      {/* Allow signups is an environment-level flag. Does not make sense to set it per-tenant since this endpoint is public and outside the scope of a logged-in user */}
      {flags.allowSignups && (
        <Route path="/create-account" element={<CreateAccountPage />} />
      )}
      <Route path="/login/email-callback" element={<EmailCallbackHandler />} />
      <Route
        path="/o/:orgSlug/authorize/cli"
        element={
          <Login>
            <DeviceAuth />
          </Login>
        }
      />
      {flags.onboardingFlowV1 && (
        <Route
          path="/o/:orgSlug/welcome"
          element={
            <Page title="Welcome">
              <Login>
                <Outlet />
              </Login>
            </Page>
          }
        >
          <Route index element={<Welcome />} />
          <Route path="onboarding/:component?" element={<OnboardWorkflows />} />
          <Route path="onboarding/success" element={<Success />} />
        </Route>
      )}
      <Route
        path="/o/:orgSlug/"
        element={
          <Login>
            <OnboardingRedirect>
              <AppLayout />
            </OnboardingRedirect>
          </Login>
        }
      >
        <Route
          index
          element={
            <Navigate
              to={
                flags.auditIndexPage
                  ? AppRoutes.IamAssessment
                  : AppRoutes.RequestHistory
              }
            />
          }
        />
        {RequestRoutes()}
        <Route
          element={
            <AuthzGuard requirement={["iamViewer", "iamOwner", "owner"]} />
          }
        >
          {AssessmentRoutes}
        </Route>

        {sandboxRoutes()}

        <Route element={<AuthzGuard requirement={["owner"]} />}>
          <Route
            path={AppRoutes.Settings}
            element={
              <Page title="Settings">
                <Settings />
              </Page>
            }
          />
        </Route>
        <Route element={<AuthzGuard requirement={["approver", "owner"]} />}>
          <Route
            path={AppRoutes.Routing}
            element={
              <Page title="Routing">
                <ResourcesProvider>
                  <RoutingRules />
                </ResourcesProvider>
              </Page>
            }
          />
          {IntegrationRoutes()}
        </Route>
        <Route
          element={<AuthzGuard requirement={["owner", "restStateManager"]} />}
        >
          {flags.snowflakeRestStateManagement && snowflakeRestStateRoutes()}
        </Route>
        {/* replace so back button works */}
        {/* <Route path="*" element={<Navigate to="" replace />} /> */}
      </Route>
      <Route
        path="*"
        element={
          <Layout>
            <AccountSelect />
          </Layout>
        }
      />
    </Routes>
  );
};
