import { Radio } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";
import { colors } from "styles/variables";

type RadioItem<T> = {
  value: T;
  display: ReactNode;
};

type Props<T extends string> = {
  value: T | undefined;
  onChange: (value: T) => void;
  items: RadioItem<T>[];
};

export const StateRadioGroup = <T extends string>({
  value,
  onChange,
  items,
}: Props<T>) => (
  <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
    <StyledRuleEditorTypeContainer>
      {items.map((item) => (
        <StyledRuleEditorTypeSelectBoxButton
          key={item.value}
          type="button"
          onClick={() => onChange(item.value)}
          className={value === item.value ? "selected" : ""}
        >
          <Radio value={item.value} />
          {item.display}
        </StyledRuleEditorTypeSelectBoxButton>
      ))}
    </StyledRuleEditorTypeContainer>
  </Radio.Group>
);

const StyledRuleEditorTypeContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(1, max-content);
  grid-gap: 4px;
`;

const StyledRuleEditorTypeSelectBoxButton = styled.button`
  background: transparent;
  border: 2px solid ${colors.neutral["15"]};
  border-radius: 4px;
  color: ${colors.neutral["60"]};
  cursor: pointer;
  display: grid;
  gap: 16px;
  grid-template-columns: max-content 1fr;
  padding: 16px 24px;
  text-align: unset;
  transition: border-color 0.25s ease-in-out;

  &:hover {
    border-color: ${colors.primary["50"]};
  }

  &.selected {
    border-color: ${colors.primary["50"]};
  }

  .ant-typography,
  span {
    color: ${colors.neutral["60"]};
    font-size: 14px;
  }
`;
