import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons";
import { Button, Form, Input, Select, Typography } from "antd";
import { FormInstance } from "antd/es/form";
import * as React from "react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PgIntegrationType } from "shared/integrations/resources/postgres/types";

import { PgComponentConfig } from "../../../../../shared/integrations/resources/postgres/types";
import { ErrorDisplay } from "../../Error";
import { useAuthFetch } from "../../Login/hook";
import { NotificationType } from "./index";
import InstallCommands from "./installCommands";
import { StyledButtonContent, StyledWorkflowContent } from "./styles";

const { Paragraph } = Typography;
const { Option } = Select;

type Props = {
  openNotificationWithIcon: (
    type: NotificationType,
    {
      message,
      description,
    }: {
      message?: string;
      description?: string;
    }
  ) => void;
  config: Partial<PgComponentConfig> | undefined;
  serviceAccountEmail: string;
  projectIds: string[] | undefined;
  isNewConfig?: boolean;
  setIsNewConfig: (value: boolean) => void;
};

const Workflows: React.FC<Props> = ({
  openNotificationWithIcon,
  config,
  serviceAccountEmail,
  projectIds,
  isNewConfig,
  setIsNewConfig,
}) => {
  const [isFetch, setIsFetching] = useState(false);
  const [error, setError] = useState<string>();
  const [currentState, setCurrentState] = useState<
    "config-form" | "install-commands"
  >("config-form");
  const [formData, setFormData] = useState(config);
  const formRef = React.useRef<FormInstance>(null);
  const navigate = useNavigate();

  const authFetch = useAuthFetch(setError, setIsFetching);

  const createOrUpdateIntegration = useCallback(
    async (data: Omit<PgComponentConfig, "state">) => {
      const response = await authFetch("integrations/pg/config", {
        method: isNewConfig ? "POST" : "PATCH",
        json: data,
      });
      if (response) {
        setCurrentState("install-commands");
      }
    },
    [authFetch, isNewConfig]
  );

  const verifyInstallation = useCallback(
    async (data: Partial<PgComponentConfig> | undefined) => {
      if (data) {
        const response = await authFetch("integrations/pg/config/verify", {
          method: "POST",
          json: {
            serviceAccountEmail,
            ...data,
          },
        });
        if (response) {
          openNotificationWithIcon("success", {
            message: "Integration Installed",
            description:
              "PostgreSQL integration has been installed successfully.",
          });
          navigate("./..");
        }
      }
    },
    [authFetch, navigate, openNotificationWithIcon, serviceAccountEmail]
  );

  const handleModalSubmit = () => {
    switch (currentState) {
      case "config-form":
        formRef?.current?.validateFields().then((values) => {
          setFormData(values);
          createOrUpdateIntegration(values);
        });
        break;
      case "install-commands":
        verifyInstallation(formData);
        break;
      default:
        break;
    }
  };

  const goBack = () => {
    setIsNewConfig(false);
    setCurrentState("config-form");
  };

  const cancel = () => {
    navigate("./..");
  };

  const ConfigurationForm = ({
    config,
  }: {
    config?: Partial<PgComponentConfig>;
  }) => {
    return (
      <>
        <Paragraph>
          Please provide the necessary details for PostgreSQL integration.
        </Paragraph>
        <br />
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="control-ref"
          labelAlign="left"
          ref={formRef}
        >
          <Form.Item
            label="Integration Type"
            name="integrationType"
            initialValue={config?.integrationType}
            rules={[
              {
                required: true,
                message: "Please select your integration type!",
              },
            ]}
          >
            <Select<PgIntegrationType> placeholder="Please select your integration type">
              <Option value="cloudSQL">CloudSQL</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Project ID"
            name="projectId"
            initialValue={config?.projectId}
            rules={[
              {
                required: true,
                message:
                  "Please select your project ID! If you don't have one, please create a Google Cloud integration first.",
              },
            ]}
          >
            <Select placeholder="Please select a installed Google Cloud project">
              {projectIds?.map((projectId) => (
                <Option key={projectId} value={projectId}>
                  {projectId}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Instance Connection Name"
            initialValue={config?.instanceName}
            name="instanceName"
            rules={[
              {
                required: true,
                message: "Please input your instance connection name!",
              },
            ]}
          >
            <Input placeholder="An instance with public IP enabled" />
          </Form.Item>
          <Form.Item
            label="Database"
            name="databaseName"
            initialValue={config?.databaseName}
            rules={[
              {
                required: true,
                message: "Please input your database name!",
              },
            ]}
          >
            <Input placeholder="PostgreSQL database name" />
          </Form.Item>
          <Paragraph italic>
            To add another database, make a new PostgreSQL integration.
          </Paragraph>
        </Form>
      </>
    );
  };

  return (
    <StyledWorkflowContent>
      {error && (
        <ErrorDisplay title="Error installing PostgreSQL" error={error} />
      )}
      {currentState === "config-form" && (
        <ConfigurationForm config={formData} />
      )}
      {currentState === "install-commands" && (
        <>
          <InstallCommands
            serviceAccountEmail={serviceAccountEmail}
            instanceName={formData?.instanceName}
            projectId={formData?.projectId}
          />
        </>
      )}
      <StyledButtonContent>
        <Button
          icon={<CaretLeftFilled />}
          onClick={currentState === "config-form" ? cancel : goBack}
        >
          {currentState === "config-form"
            ? "Cancel"
            : "Return to previous step"}
        </Button>
        <Button
          icon={<CaretRightFilled />}
          type="primary"
          loading={isFetch}
          onClick={handleModalSubmit}
        >
          {currentState === "config-form" ? "Go to next step" : "Verify"}
        </Button>
      </StyledButtonContent>
    </StyledWorkflowContent>
  );
};

export default Workflows;
