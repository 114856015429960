import { CopyTwoTone } from "@ant-design/icons";
import { Button, Input, Tooltip } from "antd";
import copy from "copy-to-clipboard";
import { useCallback, useState } from "react";

export const CommandDisplay: React.FC<{
  commands: string;
  minRows?: number;
  maxRows?: number;
  hideCopy?: boolean;
}> = ({ commands, hideCopy, minRows, maxRows }) => {
  const onCopy = useCallback(() => copy(commands), [commands]);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopyClick = () => {
    onCopy();
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 1000);
  };

  return (
    <div
      style={{
        position: "relative",
        marginBottom: "0.8em",
        fontFamily: 'Monaco, Consolas, Menlo, "Courier New", monospace',
      }}
    >
      {!hideCopy && (
        <Tooltip
          title="Copied"
          trigger="click"
          color={"blue"}
          open={tooltipVisible}
        >
          <Button
            style={{
              position: "absolute",
              right: "1.5em", // room for scroll bar
              top: "0.5em",
              zIndex: 1,
            }}
            icon={<CopyTwoTone />}
            onClick={handleCopyClick}
          />
        </Tooltip>
      )}
      <Input.TextArea
        value={commands?.trim() ?? ""} // ensure commands is not undefined
        autoSize={{ minRows: minRows ?? 0, maxRows: maxRows ?? 40 }}
        style={{ background: "rgba(150, 150, 150, 0.1)", fontSize: 12 }}
        readOnly
      />
    </div>
  );
};
