import { Typography } from "antd";
import Link from "antd/lib/typography/Link";
import { installedItems } from "shared/install/installed";

import { AwsInstaller } from "./types";

const resourceExplorerCommands = {
  enableAllRegions: `export REGIONS_ALL=$( \\
    aws account list-regions --output text \\
    --query 'Regions[?(RegionOptStatus!=\`DISABLED\` && RegionOptStatus!=\`DISABLING\`)].RegionName' \\
)
for region in $REGIONS_ALL; do 
 index=$(aws resource-explorer-2 get-index --region $region --query '[Arn,State]' --output text) 
 if [[ $? != 0 ]]; then
   echo "Creating index in $region"
   aws resource-explorer-2 create-index --region $region
   echo "Index created in $region"
 else
   props=(\`echo $\{index\}\`);
   if [[ $\{props[1]\} == "DELETED" ]]; then
      echo "Creating index in $region"
      aws resource-explorer-2 create-index --region $region
      echo "Index created in $region"
   else
      echo "Index already exists in $region"
   fi
 fi 
done 
`,
  promoteAggregator: `export AGGREGATOR_ARN=$(aws resource-explorer-2 list-indexes --query 'Indexes[?Type==\`AGGREGATOR\`].Arn' --output text)  
  if [[ -z $AGGREGATOR_ARN ]]; then
     index=$(aws resource-explorer-2 get-index --region us-west-2  --query '[Arn,State]' --output text) 
     if( [[ $? != 0 ]] ); then
        echo "make sure us-west-2 index is created"
        aws resource-explorer-2 create-index --region us-west-2
        index=$(aws resource-explorer-2 get-index --region us-west-2  --query '[Arn,State]' --output text) 
     fi
     props=(\`echo $\{index\}\`);
     while [[ $props[1] == "CREATING" ]]; do
        echo "Waiting for us-west-2 index to be created"
        sleep 5
        index=$(aws resource-explorer-2 get-index --region us-west-2 --query '[Arn,State]' --output text) 
        props=(\`echo $\{index\}\`);
     done
     aws resource-explorer-2 update-index-type --arn $\{props[0]\} --type AGGREGATOR --region us-west-2 
     if [[ $? != 0 ]]; then
        echo "Failed to promote index to aggregator, if there is Service Quota Exception, please try after 24 hours or request a quota increase"
     else
        echo "Index promoted to aggregator"
     fi
  fi
`,
  createDefaultView: `export AGGREGATOR_REGION=$(aws resource-explorer-2 list-indexes \\
    --query 'Indexes[?Type==\`AGGREGATOR\`].Region' \\
    --output text \\
)
if [[ $AGGREGATOR_REGION == "None" ]]; then
  echo "Aggregator index does not exist, please create aggregator index"
else
  DEFAULT_VIEW=$(aws resource-explorer-2 get-default-view --region $AGGREGATOR_REGION --query 'ViewArn' --output text)
  if [[ $DEFAULT_VIEW != "None" ]]; then
    echo "Default view already exists"
  else
    echo "Creating default view"
    ARN=$(aws resource-explorer-2 create-view  --view-name all-resources-p0 --included-properties Name=tags --region $AGGREGATOR_REGION --query "View.ViewArn" --output text)        
    if [[ $? != 0 ]]; then
        echo "Failed to create default view"
        echo "Checking if view already exists in aggregator region"
        ARN=$(aws resource-explorer-2 list-views --query 'Views[?contains(@, \`\/all-resources-p0\/\`)]' --region $AGGREGATOR_REGION --output text)
    else
        echo "created $ARN"
        ARN=$(aws resource-explorer-2 get-view --view-arn $ARN --region $AGGREGATOR_REGION --query "View.ViewArn" --output text)
    fi
    if [[ $? != 0 ]]; then
        echo "Failed to create default view"
    else
        aws resource-explorer-2 associate-default-view --view-arn $ARN --region $AGGREGATOR_REGION
        if [[ $? != 0 ]]; then
            echo "Failed to associate default view"
        else
            echo "Default view associated for region"
        fi
    fi
    
  fi       
fi
`,
};

const inventoryInstructions: AwsInstaller["inventory"]["instructions"] = (
  _context,
  id
) => ({
  help: (
    <>
      <Typography.Paragraph>
        P0 Security relies on the Resource Explorer API to list resources in
        AWS. The following script:
        <ul>
          <li>
            Enables Resource Explorer in those AWS regions where it is not
            already enabled
          </li>
          <li>
            Promotes the index in us-west-2 to an aggregator index, unless
            another aggregator index exists
          </li>
        </ul>
      </Typography.Paragraph>
      <Typography.Paragraph>
        To configure resource listing on this account, run these commands in{" "}
        <Link
          href={`https://${id}.signin.aws.amazon.com/console`}
          target="_blank"
          rel="noopener"
        >
          AWS Cloud Shell
        </Link>
        :
      </Typography.Paragraph>
    </>
  ),
  commands: {
    shell: Object.values(resourceExplorerCommands).map((command) => ({
      command,
    })),
  },
});

const inventoryOptionProvider: AwsInstaller["inventory"]["optionProvider"] =
  async ({ config }) => {
    const existingWrite = installedItems("iam-write", config);
    const existingInventory = installedItems("inventory", config);
    return Object.entries(existingWrite)
      .filter(([id]) => !(id in existingInventory))
      .map(([id, item]) => ({ id, label: item?.label }));
  };

export const awsInventoryInstaller: AwsInstaller["inventory"] = {
  instructions: inventoryInstructions,
  optionProvider: inventoryOptionProvider,
};
