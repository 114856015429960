import { Button, List, Modal, Spin, Tag, Typography } from "antd";
import { AppRoutes } from "components/App/routeConstants";
import { Tenant } from "components/Login";
import { AuthzButton } from "components/common/AuthzButton";
import { ConstantWidthDiv } from "components/divs";
import { doc, writeBatch } from "firebase/firestore";
import { capitalize } from "lodash";
import { DB } from "providers/FirestoreProvider";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { colors } from "styles/variables";

import {
  FindingsContext,
  MonitorWithFindings,
} from "../contexts/FindingsContext";
import { EditMonitorForm } from "./MonitorAdmin";
import { MonitorSeverity } from "./monitor/MonitorSeverity";

export const MonitorList: React.FC<{
  actionTypes: string[];
  showFindings?: boolean;
  customOnly?: boolean;
  showArchived?: boolean;
  filterFindings?: boolean;
}> = ({
  actionTypes,
  showFindings = true,
  customOnly = false,
  showArchived = false,
  filterFindings = false,
}) => {
  const { orgSlug, assessmentId } = useParams();
  const tenantId = useContext(Tenant);
  const navigate = useNavigate();
  const { prioritized, findingParams, archivedMonitors, loading } =
    useContext(FindingsContext);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [modalMonitor, setModalMonitor] = useState<MonitorWithFindings>();
  const [archiveModalType, setArchiveModalType] = useState<
    "archive" | "unarchive"
  >("archive");

  const filteredMonitors = useMemo(() => {
    const output = showArchived ? archivedMonitors : prioritized;
    if (customOnly) {
      return output.filter((m) => m.isCustom);
    }
    if (filterFindings) {
      return output.filter((m) => m.scopedFindings.length > 0);
    }
    return output;
  }, [customOnly, prioritized, showArchived, archivedMonitors, filterFindings]);

  const monitorPath = useCallback(
    (item: MonitorWithFindings) =>
      `/o/${orgSlug}/${AppRoutes.IamAssessment}/${assessmentId}/monitors/${item.monitorId}?${findingParams}`,
    [assessmentId, findingParams, orgSlug]
  );

  const actionCallbacks = useMemo(
    () => ({
      view: (item: MonitorWithFindings) => navigate(monitorPath(item)),
      edit: (item: MonitorWithFindings) => {
        setModalMonitor(item);
        setEditModalOpen(true);
      },
      archive: (item: MonitorWithFindings) => {
        setModalMonitor(item);
        setArchiveModalType("archive");
        setArchiveModalOpen(true);
      },
      unarchive: (item: MonitorWithFindings) => {
        setModalMonitor(item);
        setArchiveModalType("unarchive");
        setArchiveModalOpen(true);
      },
    }),
    [navigate, monitorPath]
  );

  const actions = useMemo(
    () => (item: MonitorWithFindings) =>
      actionTypes.map((actionType) => (
        <AuthzButton
          roles={actionType === "view" ? "all" : ["owner", "iamOwner"]}
          key={actionType}
          type={actionType === "archive" ? "text" : "link"}
          size="small"
          onClick={() =>
            actionCallbacks[actionType as "archive" | "edit" | "view"](item)
          }
        >
          {capitalize(actionType)}
        </AuthzButton>
      )),
    [actionCallbacks, actionTypes]
  );

  const archiveMonitor = useCallback(
    async (monitor: MonitorWithFindings, unarchive = false) => {
      setArchiveLoading(true);
      const batch = writeBatch(DB);
      batch.update(
        doc(
          DB,
          `o/${tenantId}/iam-assessments/${assessmentId}/monitors`,
          monitor.monitorId
        ),
        { archived: !unarchive }
      );
      monitor.scopedFindings.forEach((finding) => {
        batch.update(
          doc(
            DB,
            `o/${tenantId}/iam-assessments/${assessmentId}/findings`,
            finding.id
          ),
          { archived: !unarchive }
        );
      });
      await batch.commit();
      setArchiveLoading(false);
      setArchiveModalOpen(false);
    },
    [assessmentId, tenantId]
  );

  return loading ? (
    <Spin />
  ) : (
    <>
      <List
        dataSource={filteredMonitors}
        renderItem={(item) => {
          return (
            <List.Item actions={actions(item)}>
              <List.Item.Meta
                title={
                  <Link
                    to={monitorPath(item)}
                    style={{ color: colors.primary["50"] }}
                  >
                    {item.label}
                  </Link>
                }
                description={item.description}
              />
              <ConstantWidthDiv width="10em">
                <MonitorSeverity monitor={item} />
              </ConstantWidthDiv>
              {showFindings && (
                <ConstantWidthDiv width="3em">
                  <Tag>{item.scopedFindings.length}</Tag>
                </ConstantWidthDiv>
              )}
            </List.Item>
          );
        }}
      />
      {actionTypes.includes("edit") && (
        <Modal
          open={editModalOpen}
          footer={false}
          onCancel={() => setEditModalOpen(false)}
        >
          <Typography.Title level={4}>Edit Monitor</Typography.Title>
          <EditMonitorForm
            monitor={modalMonitor}
            show={modalMonitor?.show ?? "binding"}
            onSubmitForm={() => setEditModalOpen(false)}
          />
        </Modal>
      )}
      {(actionTypes.includes("archive") ||
        actionTypes.includes("unarchive")) && (
        <Modal
          open={archiveModalOpen}
          footer={false}
          onCancel={() => setArchiveModalOpen(false)}
        >
          <Typography.Title level={4}>Are you sure?</Typography.Title>
          {archiveModalType === "archive" ? (
            <Typography.Paragraph>
              This disables this monitor and remove it from future assessments.
              You can unarchive this at a later date.
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph>
              This will re-enable this monitor and add it to future assessments.
            </Typography.Paragraph>
          )}
          <Typography.Paragraph code>
            {modalMonitor?.label}
          </Typography.Paragraph>
          <Button
            type="primary"
            danger={archiveModalType === "archive"}
            loading={archiveLoading}
            onClick={() =>
              archiveMonitor(
                modalMonitor as MonitorWithFindings,
                archiveModalType === "unarchive"
              )
            }
          >
            {archiveModalType === "archive" ? "Archive" : "Re-activate"}
          </Button>
          <Button type="text" onClick={() => setArchiveModalOpen(false)}>
            Cancel
          </Button>
        </Modal>
      )}
    </>
  );
};
