/** Called by a function listener to indicate that it is no longer listening
 *
 * Functions should gracefully terminate execution after this is called.
 */
export type Unsubscribe = () => void;

/** Called by a function to notify its listener(s) that an error has occurred */
export type ErrorHandler = (error: any) => void;

export type Shutdownable = { shutdown?: () => Promise<void> };

/** A function that can be integrated into {@link Service}
 *
 * For logging purposes, this handler should be a named (not anonymous) function.
 *
 * @param onError: This function should execute this callback when a non-recoverable error is encountered
 * @param isTestDone: For testing use only, should be called once the handler has run a single time
 * @param args: Any arguments that should be passed to this function
 */
export type ServiceHandler<Args extends any[]> = (
  onError: ErrorHandler,
  onDone?: () => void
) => (...args: Args) => Unsubscribe;

/** Represents an access-request workflow configuration in Firestore */
export type Workflow = {
  /** If true, allows approvers to self-approve requests */
  allowOneParty?: boolean;
  requireReason?: boolean;
};

export type AuthenticatedP0User = {
  uid: string;
  email: string;
  isAnonymous: false;
  provider?: string;
  groups?: string[];
};

export type P0User =
  | AuthenticatedP0User
  | {
      isAnonymous: true;
    };

export type ApiUser = {
  uid: string;
  name: string;
};

export const isAuthenticatedP0User = (
  user: P0User | undefined
): user is AuthenticatedP0User => !!user && !user.isAnonymous;

export const assertNever = (value: never) => {
  throw Object.assign(new Error("Got unexpected value of type never"), {
    value,
  });
};
