import { Typography } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import moment from "moment";
import { ReactNode } from "react";
import { Principal, PrincipalType } from "shared/types/assessment/data";

export const authnHelp: Record<PrincipalType, ReactNode> = {
  "iam-group": "IAM Groups do not have authentication credentials",
  "iam-user": "Authentication information is not yet available",
  "federated-identity": "Authentication information is not yet available",
  domain: "Domains do not have authentication credentials.",
  group: "Groups do not have authentication credentials.",
  "logged-in":
    "This is an arbitrary principal, and does not refer to a user with a unique set of authentication credentials.",
  public: "Public Internet users are not authenticated.",
  "service-account":
    "Authentication information is only available for service accounts managed within this Google Cloud project.",
  unknown: "Authentication time is unknown.",
  user: "Authentication information is unknown for this user. Install the directory integration corresponding to this user account to see its authentication details.",
};

export const LastAuthenticated: React.FC<{
  principal: Principal;
  last: number;
}> = ({ last, principal }) => (
  <>
    {isNaN(last) ? (
      <GraphTooltip title={authnHelp[principal?.principalType ?? "unknown"]}>
        &ndash;
      </GraphTooltip>
    ) : last > 0 ? (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{moment(last).format("LL")}</div>
        <div>
          <Typography.Text type="secondary" style={{ fontSize: "small" }}>
            {moment(last).fromNow()}
          </Typography.Text>
        </div>
      </div>
    ) : principal.principalType === "user" ? (
      "Never"
    ) : (
      "Not within last 40 days"
    )}
  </>
);
