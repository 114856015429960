import { CaretLeftFilled, ForwardFilled } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Segmented,
  Skeleton,
  Space,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Link from "antd/lib/typography/Link";
import yaml from "js-yaml";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { toClientId } from "../../../shared/integrations/resources/kubernetes/id-util";
import {
  K8sOptionData,
  KubernetesComponentConfig,
} from "../../../shared/integrations/resources/kubernetes/types";
import { getEnvironment } from "../../../utils/environment";
import { ErrorDisplay } from "../../Error";
import { TagInput } from "../../TagInput";
import { CommandDisplay } from "../CommandDisplay";
import { SubmitFooter, stateError } from "./K8s";
import {
  CloudProvider,
  CloudProviders,
  P0_PROXY_DEPLOYMENT,
  P0_PROXY_VOLUME_CLAIM,
} from "./constants";

const envToTunnelHost = (environment: ReturnType<typeof getEnvironment>) => {
  return environment.apiUrl.substring(8); // remove "https://" from beginning of string
};

const CLOUD_TO_OPTION_DATA: {
  [index in CloudProvider]: K8sOptionData;
} = {
  aws: {
    label: "EKS (AWS)",
    isDisabled: (awsAccountId) => !awsAccountId,
    disabledTooltip:
      "EKS requires an AWS account as the user provisioning method. Please select one in the previous step.",
  },
  azure: {
    label: "AKS (Azure)",
    isDisabled: (awsAccountId) => !!awsAccountId,
    disabledTooltip:
      'AKS does not support an AWS account as the user provisioning method. Please select a different option as "User provisioning" in the previous step.',
  },
  gcloud: {
    label: "GKE (Google Cloud)",
    isDisabled: (awsAccountId) => !!awsAccountId,
    disabledTooltip:
      'GKE does not support an AWS account as the user provisioning method. Please select a different option as "User provisioning" in the previous step.',
  },
};

/**
 * If the option is disabled, shows a tooltip.
 */
const segmentOption = (props: {
  cloudProvider: CloudProvider;
  awsAccountId?: string;
}) => {
  const { cloudProvider, awsAccountId } = props;
  const { label, isDisabled, disabledTooltip } =
    CLOUD_TO_OPTION_DATA[cloudProvider];
  return {
    label: isDisabled(awsAccountId) ? (
      <Tooltip title={disabledTooltip}>{label}</Tooltip>
    ) : (
      label
    ),
    value: cloudProvider,
    disabled: isDisabled(awsAccountId),
  };
};

const k8sCommand = (
  environment: ReturnType<typeof getEnvironment>,
  config: Partial<KubernetesComponentConfig>,
  slug: string,
  cloudProvider: CloudProvider
) => {
  const { clusterId, clusterServer, clusterCertificate } = config;
  if (!(clusterId && clusterServer && clusterCertificate)) {
    return undefined;
  }
  const clientId = toClientId({
    org: { slug },
    clusterId,
  });
  const tunnelHost = envToTunnelHost(environment);
  return `kubectl apply -f - <<EOF
apiVersion: v1
kind: Namespace
metadata:
  name: p0-security
---
  
${yaml.dump(P0_PROXY_VOLUME_CLAIM(cloudProvider), {
  flowLevel: 3,
})}
---

${yaml.dump(
  P0_PROXY_DEPLOYMENT(clientId, clusterServer, clusterCertificate, tunnelHost),
  {
    flowLevel: 3,
  }
)}
EOF`;
};

export const vmCommand = (
  environment: ReturnType<typeof getEnvironment>,
  slug: string,
  cluster: Partial<KubernetesComponentConfig>
) => {
  const { clusterId, clusterServer, clusterCertificate } = cluster;
  if (!(clusterId && clusterServer && clusterCertificate)) {
    return undefined;
  }
  const clientId = toClientId({
    org: { slug },
    clusterId,
  });
  const tunnelHost = envToTunnelHost(environment);
  return `git clone https://github.com/p0-security/braekhus.git &&
cd braekhus &&
echo "${clusterCertificate}" | base64 --decode > ca.pem &&
yarn install &&
yarn build &&
NODE_EXTRA_CA_CERTS=ca.pem nohup yarn start:prod:client --targetUrl "${clusterServer}" --clientId "${clientId}" --tunnelHost ${tunnelHost} --tunnelPort 443 > client.log 2>&1 &`;
};

const JwkForm: React.FC<{
  config: Partial<KubernetesComponentConfig>;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ config, onNext, onBack }) => (
  <Form
    labelCol={{ span: 0 }}
    wrapperCol={{ span: 24 }}
    onFinish={onNext}
    labelAlign="right"
  >
    <Form.Item
      name="publicJwk"
      initialValue={(config as any)?.publicJwk}
      rules={[
        {
          required: true,
          message: "Enter the public JWK",
        },
      ]}
    >
      <TextArea autoSize={{ minRows: 6, maxRows: 15 }} />
    </Form.Item>
    <SubmitFooter onBack={onBack} />
  </Form>
);

const K8sTab: React.FC<{
  environment: ReturnType<typeof getEnvironment>;
  orgSlug: string;
  config: Partial<KubernetesComponentConfig>;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ environment, orgSlug, config, onNext, onBack }) => {
  const { awsAccountId } = config;

  const [cloudProvider, setCloudProvider] = useState<CloudProvider>(
    awsAccountId ? "aws" : "azure"
  );

  const onChange = useCallback(
    (value: number | string) => {
      setCloudProvider(value as CloudProvider);
    },
    [setCloudProvider]
  );

  const options = useMemo(() => {
    return CloudProviders.map((cloudProvider) =>
      segmentOption({ cloudProvider, awsAccountId })
    );
  }, [awsAccountId]);

  return (
    <>
      Deploy the braekhus proxy on Kubernetes. Choose the cloud provider where
      your Kubernetes cluster is hosted.
      <Divider>
        <Segmented
          options={options}
          onChange={onChange}
          defaultValue={cloudProvider}
        />
      </Divider>
      <K8sInstructions
        environment={environment}
        orgSlug={orgSlug}
        config={config}
        cloudProvider={cloudProvider}
        onNext={onNext}
        onBack={onBack}
      />
    </>
  );
};

const K8sErrorDisplay: React.FC<{
  title?: string;
  error: string;
  onBack: () => void;
}> = ({ title, error, onBack }) => (
  <>
    <ErrorDisplay title={title} error={error} />
    <SubmitFooter onBack={onBack} hiddenNext={true} />
  </>
);

const K8sProxyDisplay: React.FC<{
  command: string;
  config: Partial<KubernetesComponentConfig>;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ command, config, onNext, onBack }) => (
  <>
    Run the following kubectl command to deploy the braekhus proxy:
    <CommandDisplay commands={command} />
    Access the pod with kubectl and retrieve the contents of the jwk.public.json
    file
    <CommandDisplay
      commands={`kubectl exec -it deploy/p0-braekhus-proxy -n p0-security -c braekhus -- cat /p0-files/jwk.public.json`}
      minRows={2}
    />
    Finally, copy the contents of jwk.public.json below:
    <JwkForm config={config} onNext={onNext} onBack={onBack} />
  </>
);

const AwsK8sCommandDisplay: React.FC<{
  region: string;
  clusterName: string;
  config: Partial<KubernetesComponentConfig>;
  command: string;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ region, clusterName, config, command, onNext, onBack }) => (
  <>
    <CommandDisplay
      commands={`ADDON=$(aws eks list-addons --region=${region} --cluster-name ${clusterName} | jq '.addons | select(index("aws-ebs-csi-driver"))')
if [ -z "$ADDON" ]
then
eksctl utils associate-iam-oidc-provider --region=${region} --cluster=${clusterName} --approve && \
eksctl create iamserviceaccount \
--name ebs-csi-controller-sa \
--namespace kube-system \
--region ${region} \
--cluster ${clusterName} \
--attach-policy-arn arn:aws:iam::aws:policy/service-role/AmazonEBSCSIDriverPolicy \
--approve \
--role-only \
--role-name AmazonEKS_EBS_CSI_DriverRole && \
eksctl create addon --name aws-ebs-csi-driver --region ${region} --cluster ${clusterName} --service-account-role-arn arn:aws:iam::$(aws sts get-caller-identity --query Account --output text):role/AmazonEKS_EBS_CSI_DriverRole --force
fi`}
    />
    <Divider />
    <K8sProxyDisplay
      command={command}
      config={config}
      onNext={onNext}
      onBack={onBack}
    />
  </>
);

const AwsK8sInstructions: React.FC<{
  config: Partial<KubernetesComponentConfig>;
  command: string;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ config, command, onNext, onBack }) => {
  const [region, setRegion] = useState<string>();
  const [clusterName, setClusterName] = useState<string>();

  const handleRegionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegion(e.target.value);
  };

  const handleClusterNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClusterName(e.target.value);
  };

  return (
    <>
      <p>
        Provide the region and cluster name below for commands that enable the
        EBS CSI driver. See&nbsp;
        <Link
          href={"https://docs.aws.amazon.com/eks/latest/userguide/ebs-csi.html"}
        >
          Amazon EBS CSI driver
        </Link>
        &nbsp;documentation for more information.
      </p>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="region" label="Region">
            <Input
              placeholder="Example: us-central-1"
              value={region}
              onChange={handleRegionChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="cluster" label="Cluster Name">
            <Input
              placeholder="Example: my-cluster"
              value={clusterName}
              onChange={handleClusterNameChange}
            />
          </Form.Item>
        </Col>
      </Row>
      {/*These commands were taken from https://stackoverflow.com/a/75758116/4021145. There are different ways to enable the driver, which we could support in the future. See https://github.com/kubernetes-sigs/aws-ebs-csi-driver/blob/master/docs/install.md 
            The commands are idempotent (even the individual commands inside the if-check) */}
      {region && clusterName ? (
        <AwsK8sCommandDisplay
          region={region}
          clusterName={clusterName}
          config={config}
          command={command}
          onNext={onNext}
          onBack={onBack}
        />
      ) : (
        <>
          <Skeleton title={true} paragraph={true} />
          <SubmitFooter onBack={onBack} hiddenNext={true} />
        </>
      )}
    </>
  );
};

const K8sInstructions: React.FC<{
  environment: ReturnType<typeof getEnvironment>;
  orgSlug: string;
  config: Partial<KubernetesComponentConfig>;
  cloudProvider: CloudProvider;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ environment, orgSlug, config, cloudProvider, onNext, onBack }) => {
  const command = useMemo(
    () => k8sCommand(environment, config, orgSlug, cloudProvider),
    [environment, orgSlug, config, cloudProvider]
  );

  return !command ? (
    <K8sErrorDisplay
      title="Error"
      error={stateError("Incomplete inputs for command")}
      onBack={onBack}
    />
  ) : (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        {/* AWS is special because it doesn't ship with a storage plugin that is enabled by default.
        We must ensure that the EBS CSI plugin is available before applying the k8s manifest */}
        {cloudProvider === "aws" ? (
          <AwsK8sInstructions
            config={config}
            command={command}
            onNext={onNext}
            onBack={onBack}
          />
        ) : (
          <K8sProxyDisplay
            command={command}
            config={config}
            onNext={onNext}
            onBack={onBack}
          />
        )}
      </Space>
    </>
  );
};

const VmInstructions: React.FC<{
  environment: ReturnType<typeof getEnvironment>;
  orgSlug: string;
  config: Partial<KubernetesComponentConfig>;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ environment, orgSlug, config, onNext, onBack }) => {
  const command = useMemo(
    () => vmCommand(environment, orgSlug, config),
    [environment, orgSlug, config]
  );

  return !command ? (
    <ErrorDisplay
      title="Error"
      error={stateError("Incomplete inputs for command")}
    />
  ) : (
    <>
      <Space direction="vertical">
        Build from source and run the Kubernetes reverse proxy client from a
        location that can reach the Kubernetes cluster you are installing. Pass
        the certificate of the Kubernetes API server as the NODE_EXTRA_CA_CERTS
        environment variable.
        <CommandDisplay commands={command} />
        Then enter the contents of the jwk.public.json file here:
      </Space>
      <JwkForm config={config} onNext={onNext} onBack={onBack} />
    </>
  );
};

const FargateInstructions: React.FC<{
  environment: ReturnType<typeof getEnvironment>;
  slug: string;
  config: Partial<KubernetesComponentConfig>;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ environment, slug, config, onNext, onBack }) => {
  const [error, setError] = useState<string>();
  const [subnet, setSubnet] = useState<string>();
  const [securityGroups, setSecurityGroups] = useState<string[]>([]);
  const tunnelHost = envToTunnelHost(environment);
  const onSubnetChange = useMemo(
    () => (evt: React.ChangeEvent<HTMLInputElement>) => {
      const vpc = evt.target.value.trim();
      setSubnet(vpc);
    },
    [setSubnet]
  );

  const valid = useMemo(() => {
    const { awsAccountId, clusterId, clusterServer, clusterCertificate } =
      config;
    return (
      awsAccountId &&
      clusterId &&
      clusterServer &&
      clusterCertificate &&
      subnet &&
      securityGroups.length > 0
    );
  }, [config, subnet, securityGroups]);

  const awsAccountIdExists = useMemo(() => {
    const { awsAccountId } = config;
    return !!awsAccountId;
  }, [config]);

  useEffect(() => {
    if (!valid && !awsAccountIdExists) {
      setError(
        "Fargate requires an AWS account as the user provisioning method. Please select one in the previous step."
      );
    } else {
      setError(undefined);
    }
  }, [valid, awsAccountIdExists]);

  return (
    <>
      {!!error && <ErrorDisplay title="Error" error={error} />}
      <Space direction="vertical" style={{ width: "100%" }}>
        <p>Run a Fargate task in the same VPC as the Kubernetes cluster.</p>
        <Row>
          <Col span={4}>Subnet ID</Col>
          <Col span={20}>
            <Input
              placeholder="Any one subnet ID from the VPC of the Kubernetes cluster"
              onChange={onSubnetChange}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4}>Security group IDs</Col>
          <Col span={20}>
            <TagInput
              tags={securityGroups}
              // See https://cloud.google.com/resource-manager/reference/rest/v1/projects
              tagPattern={/[\w\-]/}
              onUpdate={setSecurityGroups}
              key={"security-groups-input"}
              placeholder="Space-separated security groups to apply to this Fargate task"
            />
          </Col>
        </Row>
        <p />
      </Space>
      {valid ? (
        <Space direction="vertical" style={{ width: "100%" }}>
          Creates a dedicated ECS cluster for running the Fargate task
          <CommandDisplay
            commands={
              "aws ecs create-cluster --cluster-name p0-security-proxy-cluster"
            }
            minRows={2}
          />
          Creates a Task execution role for the Fargate task
          <CommandDisplay
            commands={`aws iam create-role --role-name p0EcsTaskExecutionRole --assume-role-policy-document '{"Version":"2012-10-17","Statement":[{"Sid":"","Effect":"Allow","Principal":{"Service":"ecs-tasks.amazonaws.com"},"Action":"sts:AssumeRole"}]}'
aws iam attach-role-policy --role-name p0EcsTaskExecutionRole --policy-arn arn:aws:iam::aws:policy/service-role/AmazonECSTaskExecutionRolePolicy`}
          />
          Create a Task role for the Fargate task that allows ECS Exec to access
          the containers
          <CommandDisplay
            commands={`aws iam create-role --role-name p0EcsTaskRole --assume-role-policy-document '{"Version":"2012-10-17","Statement":[{"Effect":"Allow","Principal":{"Service":["ecs-tasks.amazonaws.com"]},"Action":"sts:AssumeRole"}]}'
aws iam put-role-policy \\
--role-name p0EcsTaskRole \\
--policy-name p0EcsTaskRolePolicy \\
--policy-document '{"Version":"2012-10-17","Statement":[{"Effect":"Allow","Action":["ssmmessages:CreateControlChannel","ssmmessages:CreateDataChannel","ssmmessages:OpenControlChannel","ssmmessages:OpenDataChannel"],"Resource":"*"}]}'`}
          />
          Registers the task definition
          <CommandDisplay
            maxRows={150}
            commands={`aws ecs register-task-definition --cli-input-json "$(cat <<JSON
{
  "family": "p0-security-proxy",
  "networkMode": "awsvpc",
  "executionRoleArn": "arn:aws:iam::${
    config.awsAccountId
  }:role/p0EcsTaskExecutionRole",
  "taskRoleArn": "arn:aws:iam::${config.awsAccountId}:role/p0EcsTaskRole",
  "volumes": [
      {
          "name": "p0-volume",
          "host": {}
      }
  ],
  "containerDefinitions": [
      {
          "name": "p0-security-proxy-app",
          "image": "p0security/braekhus:latest",
          "essential": true,
          "dependsOn": [
              {
                  "condition": "COMPLETE",
                  "containerName": "p0-certs-setup"
              }
          ],
          "environment": [
              {
                  "name": "NODE_EXTRA_CA_CERTS",
                  "value": "/p0-files/ca.pem"
              }
          ],
          "workingDirectory": "/usr/src/app",
          "entryPoint": [
              "yarn"
          ],
          "command": [
              "start:prod:client",
              "--targetUrl",
              "${config.clusterServer}",
              "--clientId",
              "${toClientId({
                org: { slug },
                clusterId: config.clusterId as string,
              })}",
              "--jwkPath",
              "/p0-files",
              "--tunnelHost",
              "${tunnelHost}",
              "--tunnelPort",
              "443"
          ],
          "mountPoints": [
              {
                  "containerPath": "/p0-files",
                  "sourceVolume": "p0-volume"
              }
          ],
          "logConfiguration": {
              "logDriver": "awslogs",
              "options": {
                  "awslogs-create-group": "true",
                  "awslogs-group": "p0-security-proxy-app",
                  "awslogs-region": "us-west-2",
                  "awslogs-stream-prefix": "p0-proxy-app"
              }
          }
      },
      {
          "name": "p0-certs-setup",
          "image": "bash",
          "essential": false,
          "entryPoint": ["bash"],
          "command": [
              "-c",
              "echo ${
                config.clusterCertificate
              } | base64 -d | tee /p0-files/ca.pem"
          ],
          "mountPoints": [
              {
                  "containerPath": "/p0-files",
                  "sourceVolume": "p0-volume"
              }
          ],
          "logConfiguration": {
              "logDriver": "awslogs",
              "options": {
                  "awslogs-create-group": "true",
                  "awslogs-group": "p0-certs-setup",
                  "awslogs-region": "us-west-2",
                  "awslogs-stream-prefix": "p0-setup"
              }
          }
      }
  ],
  "requiresCompatibilities": [
      "FARGATE"
  ],
  "cpu": "1024",
  "memory": "2048",
}
JSON
)"`}
          />
          Creates the Fargate service
          {/* TODO: the revision number of task definition may be greater than one if customer re-runs this installation script. Currently it's hard-coded at 1 with `p0-security-proxy:1` */}
          {/* TODO: public IP is enabled to allow outbound connections. A better way would be a NAT gateway that only allows outbound, no inbound. See "Step 4" in https://docs.aws.amazon.com/AmazonECS/latest/developerguide/ECS_AWSCLI_Fargate.html */}
          <CommandDisplay
            commands={`aws ecs create-service --cluster p0-security-proxy-cluster --service-name p0-security-proxy-service --task-definition p0-security-proxy:1 --desired-count 1 --launch-type "FARGATE" --enable-execute-command --network-configuration "awsvpcConfiguration={subnets=[${subnet}],securityGroups=[${securityGroups.join(
              ","
            )}],assignPublicIp=ENABLED}"`}
          />
          Access the task with ECS Exec and retrieve the contents of the
          jwk.public.json file
          <CommandDisplay
            commands={`TASK_ARN=$(aws ecs list-tasks \\
  --cluster p0-security-proxy-cluster \\
  --service-name p0-security-proxy-service \\
  | jq -r .taskArns[0])
TASK_ID=\${TASK_ARN##*/}
aws ecs execute-command  \\
  --region us-west-2 \\
  --cluster p0-security-proxy-cluster  \\
  --task \${TASK_ID} \\
  --container p0-security-proxy-app \\
  --command '/bin/bash -c "cat /p0-files/jwk.public.json"' \\
  --interactive`}
          />
          Finally, copy the contents of jwk.public.json below:
          <JwkForm config={config} onNext={onNext} onBack={onBack} />
        </Space>
      ) : (
        <>
          <Skeleton active={false} loading={true}>
            <p />
            <p />
            <p />
            <p />
          </Skeleton>
          <Button icon={<CaretLeftFilled />} onClick={onBack} htmlType="button">
            Return to previous step
          </Button>
        </>
      )}
    </>
  );
};

export const ClusterConfigState: React.FC<{
  environment: ReturnType<typeof getEnvironment>;
  orgSlug: string;
  config: Partial<KubernetesComponentConfig>;
  onNext: (values: any) => void;
  onBack: () => void;
}> = ({ environment, orgSlug, config, onNext, onBack }) => {
  const items: TabsProps["items"] = [
    {
      key: "k8s",
      label: "Kubernetes",
      children: (
        <K8sTab
          environment={environment}
          orgSlug={orgSlug}
          config={config}
          onNext={onNext}
          onBack={onBack}
        />
      ),
    },
    {
      key: "vms",
      label: "VMs",
      children: (
        <VmInstructions
          environment={environment}
          orgSlug={orgSlug}
          config={config}
          onNext={onNext}
          onBack={onBack}
        />
      ),
    },
    // TODO: rename this tab to "Serverless Containers" or just "Serverless" once we support Azure Container Apps and/or Google Cloud Run
    {
      key: "serverless",
      label: "AWS Fargate",
      children: (
        <FargateInstructions
          environment={environment}
          slug={orgSlug}
          config={config}
          onNext={onNext}
          onBack={onBack}
        />
      ),
    },
  ];

  return (
    <>
      <Alert
        type="info"
        message="If this cluster has public Kubernetes API access then skip this step"
        action={
          <Button
            icon={<ForwardFilled />}
            size="small"
            type="primary"
            onClick={onNext}
          >
            Skip
          </Button>
        }
      />
      <Tabs defaultActiveKey="1" items={items} />
    </>
  );
};
