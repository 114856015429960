import { PaginatedListDisplay } from "components/common/PaginatedList";
import React, { ReactNode } from "react";

const ACTIONS_PAGE_SIZE = 5;

export const ActionListDisplay: React.FC<{
  actions: ReactNode[][] | string[];
}> = ({ actions }) => (
  <PaginatedListDisplay
    items={actions}
    pageSize={ACTIONS_PAGE_SIZE}
    renderer={(action: string) => (action === "*" ? "* (All Actions)" : action)}
  />
);
