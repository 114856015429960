import { Typography } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { ClipDiv } from "components/divs";
import { useContext } from "react";
import { policyName } from "shared/integrations/resources/aws/util";
import { roleName } from "shared/integrations/resources/gcloud/util";
import { AssessmentScope } from "shared/types/assessment";
import { assertNever } from "utils/assert";

import { HasAddTerm, ShowHideTerm } from "./ShowHide";

const awsLabel = (policyStatement: string | undefined) => {
  if (!policyStatement) return null;
  const [policy, statement] = policyStatement.split(":");
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {policy}
      {statement && (
        <Typography.Text type="secondary">
          {isNaN(+statement) ? statement : `Statement ${statement}`}
        </Typography.Text>
      )}
    </div>
  );
};

export const permissionSetName = (
  integration: AssessmentScope["integration"],
  permissionSet: string | undefined
) =>
  permissionSet
    ? integration === "gcloud"
      ? roleName(permissionSet)
      : integration === "aws"
      ? awsLabel(policyName(permissionSet))
      : assertNever(integration)
    : undefined;

export const PermissionSet: React.FC<
  { permissionSet?: string } & HasAddTerm
> = ({ permissionSet, ...props }) => {
  const { integration } = useContext(ScopeContext);
  if (!integration) return null;
  const name = permissionSetName(integration, permissionSet);
  return name ? (
    <ClipDiv>
      <GraphTooltip
        width="fit-content"
        title={
          <>
            {name}
            <ShowHideTerm
              term={`role:"${permissionSet}"`}
              name="roles"
              {...props}
            />
          </>
        }
      >
        {name}
      </GraphTooltip>
    </ClipDiv>
  ) : null;
};
