import { WarningTwoTone } from "@ant-design/icons";
import { Select, Typography } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { maxBy, sortBy } from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { ALL_SCOPE_SENTINEL } from "shared/assessment/constants";
import { ItemAssessmentScope, toKey } from "shared/types/assessment";

import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";
import { targetLogo } from "./TargetLogo";
import { scopeLabel } from "./Targets";

const { Text } = Typography;

/** Ranks scopes by a prioritization score
 *
 * E.g., the number of findings for this scope
 */
export type ScopeScoring = (scope: ItemAssessmentScope) => number;

export type ScopeSelectProps = {
  includeAll?: boolean;
  scoring?: ScopeScoring;
};

export const ScopeSelect: React.FC<ScopeSelectProps> = ({
  includeAll,
  scoring,
}) => {
  const { integration, scopeKey, setScopeKey, validScopeKeys } =
    useContext(ScopeContext);
  const { last } = useContext(SelectedAssessmentContext);
  const navigate = useNavigate();

  const onSelect = useCallback(
    (value: string) => {
      setScopeKey(value);
    },
    [setScopeKey]
  );

  const options = useMemo(() => {
    // On older job documents scope could be an object
    const available = last.doc?.data.scope ?? [];
    // Sort in reverse so highest score is first
    const scopes = scoring ? sortBy(available, (s) => -scoring(s)) : available;
    let options = scopes.map((s) => ({
      value: toKey(s),
      label: (
        <div>
          {scoring ? (
            <Text type="secondary">{String(scoring(s))}:&nbsp;</Text>
          ) : null}
          {targetLogo(s.integration)}
          &nbsp;{s.id}
          {s.testIndex !== undefined && `:${s.testIndex}`}
        </div>
      ),
    }));
    if (includeAll) {
      options = [
        { value: ALL_SCOPE_SENTINEL, label: <div>All targets</div> },
        ...options,
      ];
    }
    return options;
  }, [last.doc, scoring, includeAll]);

  // If no scope is selected, or the "all" scope is selected, but this UI does not support
  // the "all" scope, redirect to the most highly scored scope, or the first scope if there
  // is no scoring.
  useEffect(() => {
    // Do not change selected scope if it is currently valid
    if (
      !last.doc?.data.scope?.length ||
      includeAll ||
      (scopeKey !== ALL_SCOPE_SENTINEL && validScopeKeys.has(scopeKey))
    )
      return;
    const next = scoring
      ? // At least one element guaranteed by above guard
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        maxBy(last.doc.data.scope, scoring)!
      : last.doc.data.scope[0];
    // Dirty hack:
    // `setScopeKey` does not use the correct path when this effect is triggered on navigation.
    // Therefore, manually use `navigate` to update scope
    const search = new URLSearchParams(window.location.search);
    search.set("scope", toKey(next));
    navigate(`.?${search}`, { replace: true });
  }, [includeAll, last.doc, scopeKey, navigate, validScopeKeys, scoring]);

  const isNotInstalled = useMemo(
    () =>
      !!last.doc?.data.scope.find(
        (s) => toKey(s) === scopeKey && s.installState === "NOT_INSTALLED"
      ),
    [last.doc, scopeKey]
  );

  return options.length ? (
    <div>
      <Select
        value={
          // If scope=all but all is not a valid scope, default to first available concrete scope
          !includeAll && scopeKey === ALL_SCOPE_SENTINEL
            ? options[0].value
            : scopeKey
        }
        onChange={onSelect}
        options={options}
        style={{ alignSelf: "flex-start", minWidth: "16em" }}
      />
      {integration && isNotInstalled && (
        <GraphTooltip
          title={`P0 could not find a valid installation for this target. This ${scopeLabel[integration].project} was not assessed.`}
        >
          &nbsp; <WarningTwoTone twoToneColor="#ffe58f" />
        </GraphTooltip>
      )}
    </div>
  ) : null;
};
