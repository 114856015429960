import { ColumnType } from "antd/lib/table";
import { CatalogContext } from "components/Catalog/context";
import { compact } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { IamShowOptions } from "shared/assessment/constants";
import { assessmentParse } from "shared/assessment/issues/presets";
import { Node } from "shared/graph/types";
import {
  AnyAggregates,
  AnyNode,
  AssessmentNodes,
  TargetNodeType,
} from "shared/types/assessment/data";

import {
  GraphColumnType,
  GraphTable,
  GraphTableProps,
} from "../../GraphTable/GraphTable";
import { ScopeContext } from "../contexts/ScopeContext";
import { GraphProcessingStep } from "./GraphStep";
import { nodeDataFromShow } from "./node";

type Controls = { where: string; show: TargetNodeType };

export const AssessmentGraph: React.FC<
  Pick<
    GraphTableProps<AssessmentNodes, TargetNodeType, AnyAggregates>,
    "frozen" | "graph" | "onSearch" | "onSelection" | "searchExtra"
  > & {
    controls: Controls;
    onControls: (controls: Controls) => void;
    extraColumns?: ColumnType<Node<any, any>>[];
    step: GraphProcessingStep;
  }
> = ({
  extraColumns,
  controls,
  frozen,
  graph: inputGraph,
  onControls,
  onSelection,
  onSearch,
  searchExtra,
  step,
}) => {
  const catalog = useContext(CatalogContext);
  const { integration } = useContext(ScopeContext);

  const search = useMemo(() => {
    const where = compact([frozen?.terms, controls.where]).join(" ");
    // TODO: Fix TypeScript clobber; not sure why it can't figure this out
    return assessmentParse(where);
  }, [controls, frozen]);

  const onChangeTerms = useCallback(
    (terms: string) => onControls({ ...controls, where: terms }),
    [controls, onControls]
  );

  const onChangeShow = useCallback(
    (show: string) =>
      onControls({
        ...controls,
        show: show as TargetNodeType,
      }),
    [controls, onControls]
  );

  const onAddTerm = useCallback(
    (term: string) => {
      return onChangeTerms(
        compact([...controls.where.split(" "), term]).join(" ")
      );
    },
    [controls.where, onChangeTerms]
  );

  const data = useMemo(() => {
    const data = nodeDataFromShow[controls.show];
    const graph = { nodes: inputGraph.nodes.filter(data.predicate) };
    return { ...data, graph, from: data.predicate };
  }, [controls.show, inputGraph]);

  const columns = useMemo(() => {
    if (!integration) return [];
    const columnProps = {
      onAddTerm,
      integration,
      terms: controls.where,
      show: controls.show,
      catalog,
    };
    return [
      ...(extraColumns ?? []),
      ...data.columns(columnProps),
    ] as GraphColumnType<AnyNode>[];
  }, [
    onAddTerm,
    integration,
    controls.where,
    controls.show,
    catalog,
    extraColumns,
    data,
  ]);

  return step === "done" ? (
    <GraphTable<AssessmentNodes, TargetNodeType, AnyAggregates>
      {...data}
      columns={columns}
      controls={{
        ...controls,
        terms: controls.where,
        onChangeTerms,
        onChangeShow,
      }}
      frozen={frozen}
      onSelection={onSelection}
      onSearch={onSearch}
      showOptions={IamShowOptions}
      search={search}
      searchExtra={searchExtra}
    />
  ) : (
    <GraphProcessingStep step={step} />
  );
};
