import { compact } from "lodash";

import { eventYieldingFor } from "../util/sleep";
import {
  NodePredicate,
  NodeSearch,
  TRUE_SEARCH,
  paths,
  reachable,
} from "./search";
import { ConnectedNode, DirectedGraph, GraphOf, Path } from "./types";

/** Creates a node search that returns 0-hop paths for a "from" predicated */
const init = <D extends DirectedGraph<any>>(
  from: NodePredicate<GraphOf<D>>
) => ({ ...TRUE_SEARCH, predicate: from });

/** Returns all nodes that match zero or more search terms */
export const discover = <D extends DirectedGraph<any>>(
  graph: D,
  from: NodePredicate<GraphOf<D>>,
  search: NodeSearch<GraphOf<D>>[]
): D => {
  let subgraph = reachable(graph, from, init(from));
  for (const s of search) {
    subgraph = reachable(subgraph, from, s);
  }
  return subgraph;
};

export const interruptibleDiscover = async <D extends DirectedGraph<any>>(
  graph: D,
  from: NodePredicate<GraphOf<D>>,
  search: NodeSearch<GraphOf<D>>[]
): Promise<D> => {
  let subgraph = reachable(graph, from, init(from));
  await eventYieldingFor(
    search,
    (s) => {
      subgraph = reachable(subgraph, from, s);
    },
    { maxOccupancyMs: 100 }
  );
  return subgraph;
};

export type DiscoverMatch<D extends DirectedGraph<any>> = {
  node: ConnectedNode<GraphOf<D>, keyof GraphOf<D>>;
  matches: {
    query: string;
    paths: Path<GraphOf<D>>[];
  }[];
};

/** Returns all paths that match zero or more search terms
 *
 * Results are grouped by individual term
 */
export const discoverPaths = <D extends DirectedGraph<any>>(
  graph: D,
  from: NodePredicate<GraphOf<D>>,
  search: NodeSearch<GraphOf<D>>[]
): DiscoverMatch<D>[] => {
  const { nodes } = reachable(graph, from, init(from));
  let matches: DiscoverMatch<D>[] = nodes.map((node) => ({
    node,
    matches: [],
  }));
  for (const s of search) {
    matches = compact(
      matches.map((m): DiscoverMatch<D> | undefined => {
        const [sub] = paths({ nodes: [m.node] } as D, from, s);
        return sub
          ? {
              node: m.node,
              matches: [...m.matches, { query: s.term, paths: sub.paths }],
            }
          : undefined;
      })
    );
  }
  return matches;
};
