import { Typography } from "antd";
import * as React from "react";

import { PgServiceAccountExtension } from "../../../../../shared/integrations/resources/postgres/utils";
import { CommandDisplay } from "../CommandDisplay";
import { getCloudSqlInstanceName } from "./utils";

type Props = {
  serviceAccountEmail: string;
  projectId: string | undefined;
  instanceName: string | undefined;
};

const InstallCommands: React.FC<Props> = ({
  serviceAccountEmail,
  projectId,
  instanceName,
}) => {
  const formattedServiceAccountEmail = serviceAccountEmail.split(
    PgServiceAccountExtension.CLOUD_SQL
  )[0];
  const cloudSqlInstanceName = getCloudSqlInstanceName(instanceName ?? "");
  const gcloudAccessCommand = `

# Create a custom role that allows P0 to create and delete users in your Cloud SQL instance

gcloud iam roles create p0CloudSQLUserManager \\
--project=${projectId} \\
--title="P0 Cloud SQL User Manager" \\
--description="Allows P0 to create and delete users in your Cloud SQL instance" \\
--permissions=cloudsql.users.create,cloudsql.users.delete  
  
# Assigns the permissions that P0 needs to access your cloudSQL instance

gcloud projects add-iam-policy-binding ${projectId} \\
--member="serviceAccount:${serviceAccountEmail}" \\
--role="roles/cloudsql.client" --condition=None

gcloud projects add-iam-policy-binding ${projectId} \\
--member="serviceAccount:${serviceAccountEmail}" \\
--role="roles/cloudsql.instanceUser" --condition=None 

gcloud projects add-iam-policy-binding ${projectId} \\
--member="serviceAccount:${serviceAccountEmail}" \\
--role="roles/cloudsql.viewer" --condition=None 

gcloud projects add-iam-policy-binding ${projectId} \\
--member="serviceAccount:${serviceAccountEmail}" \\
--role="projects/${projectId}/roles/p0CloudSQLUserManager" --condition=None  

# Add the service account as a Cloud SQL instance user:

gcloud sql users create ${formattedServiceAccountEmail} \\
--instance="${cloudSqlInstanceName}" \\
--type=cloud_iam_service_account

`;

  const p0AccessRevokeCommand = `
CREATE OR REPLACE FUNCTION p0GrantOrRevokeUserAccess(
    user_name TEXT,
    role_name TEXT,
    action_type TEXT
)
    RETURNS void AS
$$
DECLARE
    role_exists BOOLEAN;
BEGIN
    -- Validate input
    IF action_type NOT IN ('grant', 'revoke') THEN
        RAISE EXCEPTION 'Invalid action type: %', action_type;
    END IF;

    -- Prevent service account from granting itself access
    IF user_name = '${formattedServiceAccountEmail}' THEN
        RAISE EXCEPTION 'Service account cannot grant or revoke access to itself';
    END IF;

    -- Check if role_name exists in the system
    SELECT EXISTS(SELECT 1 FROM pg_roles WHERE rolname = role_name) INTO role_exists;

    IF NOT role_exists THEN
        RAISE EXCEPTION 'Role does not exist: %', role_name;
    END IF;

    -- Execute action
    IF action_type = 'grant' THEN
        EXECUTE format('GRANT %I TO %I', role_name, user_name);
        RAISE NOTICE 'Granted % access to user %', role_name, user_name;
    ELSE
        EXECUTE format('REVOKE %I FROM %I', role_name, user_name);
        RAISE NOTICE 'Revoked % access from user %', role_name, user_name;
    END IF;
END;
$$ LANGUAGE plpgsql SECURITY DEFINER;


CREATE OR REPLACE FUNCTION p0ManageRoleWithGrants(
    action_type text,
    role_name text,
    permission_type text DEFAULT NULL,
    table_name text DEFAULT NULL,
    schema_name text DEFAULT NULL
)
    RETURNS void
    LANGUAGE plpgsql
AS
$$
DECLARE
    role_exists boolean;
BEGIN
    IF NOT starts_with(role_name, 'P0') THEN
        RAISE EXCEPTION 'Invalid role specified';
    END IF;
    
    -- Check if the role exists
    SELECT EXISTS(SELECT 1 FROM pg_roles WHERE rolname = role_name) INTO role_exists;

    IF action_type = 'create' THEN
        -- Check if the role already exists
        IF NOT role_exists THEN
            -- Create the role if it does not exist
            EXECUTE format('CREATE ROLE "%s"', role_name);
        END IF;

        -- Grant USAGE on the schema
        IF schema_name IS NOT NULL THEN
            EXECUTE format('GRANT USAGE ON SCHEMA %I TO "%s"', schema_name, role_name);
        END IF;

        -- Grant permissions on the table
        IF permission_type IS NOT NULL AND table_name IS NOT NULL AND schema_name IS NOT NULL THEN
            EXECUTE 'GRANT ' || permission_type || ' ON ' || format('%I.%I', schema_name, table_name) || ' TO ' ||
                    format('"%s"', role_name);
        END IF;
    ELSIF action_type = 'delete' THEN
        IF role_exists THEN
            -- Revoke specific privileges from the role on the table
            IF schema_name IS NOT NULL THEN
                EXECUTE format('REVOKE ALL PRIVILEGES ON ALL TABLES IN SCHEMA %I FROM "%s"', schema_name, role_name);
            END IF;

            -- Revoke USAGE on the schema
            IF schema_name IS NOT NULL THEN
                EXECUTE format('REVOKE USAGE ON SCHEMA %I FROM "%s"', schema_name, role_name);
            END IF;

            -- Delete the role with case sensitivity
            BEGIN
                EXECUTE format('DROP ROLE IF EXISTS "%s"', role_name);
            EXCEPTION WHEN dependent_objects_still_exist THEN
                -- Do nothing, a subsequent call will remove remaining objects
            END;
        END IF;
    ELSE
        RAISE EXCEPTION 'Invalid action specified';
    END IF;
END;
$$ SECURITY DEFINER;
    `;

  const grantExecuteCommand = `
  -- Provide the service account with execute permissions for the two newly created functions.
GRANT EXECUTE ON FUNCTION p0GrantOrRevokeUserAccess(user_name TEXT, role_name TEXT, action_type TEXT) \ 
TO "${formattedServiceAccountEmail}";

GRANT EXECUTE ON FUNCTION p0ManageRoleWithGrants(action_type TEXT, role_name TEXT, permission_type TEXT, table_name TEXT, schema_name TEXT) \ 
TO "${formattedServiceAccountEmail}";
`;

  return (
    <div>
      <Typography.Paragraph>
        <strong>Step 1: </strong>Run the following commands to authorize the P0
        service account and provide the necessary permissions to establish a
        connection with the Cloud SQL instance:
      </Typography.Paragraph>
      <Typography.Paragraph>
        If you don&apos;t have the
        <span className="ant-typography">
          <code>gcloud</code>
        </span>
        CLI configured, you can also use
        <a
          className="ant-typography"
          href="https://shell.cloud.google.com"
          rel="noreferrer"
          target="_blank"
          style={{ marginLeft: "5px" }}
        >
          Google Cloud Shell.
        </a>
      </Typography.Paragraph>
      <CommandDisplay commands={gcloudAccessCommand} minRows={3} maxRows={50} />
      <Typography.Paragraph>
        <strong>Step 2:</strong> Create the following command in your Cloud SQL
        instance to allow P0 to grant or revoke access to users.
      </Typography.Paragraph>
      <ul>
        <li>
          <Typography.Paragraph>
            Ensure you connect to the Cloud SQL instance as an authorized user
            with these required permissions:
          </Typography.Paragraph>
        </li>
        <ul>
          <li>Permission to grant access (GRANT) to other users.</li>
          <li>
            Permission to create functions (CREATE FUNCTION) within the
            database.
          </li>
        </ul>
      </ul>

      <CommandDisplay
        commands={p0AccessRevokeCommand}
        minRows={3}
        maxRows={130}
      />
      <Typography.Paragraph>
        <strong>Step 3: </strong>
        Grant the P0 service account execute access to the newly created
        function:
      </Typography.Paragraph>
      <CommandDisplay commands={grantExecuteCommand} minRows={3} maxRows={30} />
    </div>
  );
};

export default InstallCommands;
