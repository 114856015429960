import Link from "antd/lib/typography/Link";
import {
  iamWriteInstallCommands,
  iamWriteTerraform,
} from "shared/integrations/resources/aws/policy/iam-write";

import { AwsInstaller } from "../types";
import { loginInstaller } from "./login";

const iamWriteInstructions: AwsInstaller["iam-write"]["instructions"] = (
  context,
  id
) => ({
  help: (
    <>
      To install P0 on this account, provision P0&apos;s access using{" "}
      <Link
        href={`https://${id}.signin.aws.amazon.com/console`}
        target="_blank"
        rel="noopener"
      >
        AWS Cloud Shell
      </Link>{" "}
      or Terraform:
    </>
  ),
  commands: {
    shell: [
      {
        command: iamWriteInstallCommands(context, {
          gcloudServiceAccountId: context.config.base[id]?.serviceAccountId,
          awsAccountId: id,
        }),
      },
    ],
    iac: [
      {
        command: iamWriteTerraform(
          context.config.base[id]?.serviceAccountId,
          context
        ),
      },
    ],
  },
});

export const awsIamWriteInstaller: AwsInstaller["iam-write"] = {
  instructions: iamWriteInstructions,
  items: {
    login: loginInstaller,
  },
};
