import { ConfigOf } from "../../../install/types";
import { PermissionSpec, UnstagedPermission } from "../../../types/permission";
import { AwsResourceGenerated } from "../aws/accesses";
import { SessionManagerGenerated } from "../aws/types";
import { GcloudResourceParent } from "../gcloud/types";
import { SshComponents } from "./components";

export const AWS_TAG_KEY_REGEX = /^[a-zA-Z0-9+-=.,_:@]{0,128}$/;

export type ProvisionUserAccessParameters = {
  UserName: string[];
  Action: string[];
  RequestId: string[];
  Sudo?: string[];
  PublicKey?: string[];
};

export type SshSpec = SshAll | SshGroupSpec | SshSessionSpec;
export type SshSessionSpec = SshAwsSessionSpec | SshGcloudSessionSpec;
export type SshGcloudSessionSpec = PermissionSpec<
  "ssh",
  {
    type: "session";
    spec: GcloudNodeSpec;
  },
  // TODO @ENG-2258: perform ssm grants without the aws permissioner - share the ssh object between aws and gcp
  GcloudResourceParent["generated"]
>;

export type SshAwsSessionSpec = PermissionSpec<
  "ssh",
  {
    type: "session";
    spec: AwsSshResourceSpec;
  },
  AwsResourceGenerated & SessionManagerGenerated
>;

export type SshGroupSpec = PermissionSpec<
  "ssh",
  {
    type: "group";
    spec: {
      // TODO @ENG-2178: Support GCP group requests
      type: typeof AWS_PROVIDER;
      sudo?: boolean;
      accountId?: string;
      accountAlias?: string; // account may not have an alias
      key: string;
      name: string;
    };
  },
  object
>;

export type SshAll = PermissionSpec<
  "ssh",
  {
    type: "all";
    sudo: boolean;
  },
  object
>;

export type AwsSshResourceSpec = {
  type: typeof AWS_PROVIDER;
  accountId: string;
  instanceId?: string;
  instanceName?: string;
  accountAlias?: string; // account may not have an alias
  region: string;
  sudo?: boolean;
  publicKey?: string;
  // Legacy from pre-lifecycle AWS permission model; it's copied here
  awsResourcePermission: {
    permission: {
      type: "resource";
      accountId: string;
      policies: string[];
      service: string;
      object?: string;
      draftTemporaryPolicy?: any;
      resource: {
        type: "arn";
        arn: string;
        resourceTag?: {
          key: string;
          name: string;
        };
      };
      userName: string;
      basePermissionSetArn?: string;
      basePermissionSetName?: string;
    };
  };
};

export type GcloudNodeSpec = {
  type: typeof GCLOUD_PROVIDER;
  fullName: string; // format: //compute.googleapis.com/projects/{projectId}/zones/{zone}/instances/{instanceName}
  projectId: string;
  zone: string;
  instanceName: string;
  sudo?: boolean;
  publicKey: string;
  gcloudResourcePermission: UnstagedPermission<GcloudResourceParent>;
};

export const isGcloudSshPermission = (
  data: SshSessionSpec
): data is SshGcloudSessionSpec =>
  data.permission.spec.type === GCLOUD_PROVIDER;

export const isAwsSshSessionPermission = (
  data: SshSessionSpec
): data is SshAwsSessionSpec => data.permission.spec.type === AWS_PROVIDER;

type BaseSshSessionCommandArgs = {
  destination: string;
  "public-key": string;
  sudo?: boolean;
};

export type AwsSshSessionCommandArgs = {
  provider?: typeof AWS_PROVIDER;
  account?: string;
  region?: string;
  group?: string;
} & BaseSshSessionCommandArgs;

export type GcpSshSessionCommandArgs = {
  provider?: typeof GCLOUD_PROVIDER;
  project?: string;
} & BaseSshSessionCommandArgs;

export type UnknownSshSessionCommandArgs = {
  provider: undefined;
} & BaseSshSessionCommandArgs;

export type SshSessionCommandArgs =
  | AwsSshSessionCommandArgs
  | GcpSshSessionCommandArgs
  | UnknownSshSessionCommandArgs;

export type SshGroupRequestCommandArgs = {
  // TODO: Add support for 'gcp' provider
  account?: string;
  name?: string;
  destination?: string;
  sudo?: boolean;
};

export type SshAllCommandArgs = {
  account?: string;
  sudo?: boolean;
};

export type SshComponents = typeof SshComponents;

export type SshUse = keyof SshComponents;

export type SshIntegration = ConfigOf<typeof SshComponents>;

export const AWS_PROVIDER = "aws";
export const GCLOUD_PROVIDER = "gcloud";

export const SshProviders = [AWS_PROVIDER, GCLOUD_PROVIDER] as const;
export type SshProviderKey = (typeof SshProviders)[number];
