import { Typography } from "antd";
import { AuthFetch } from "components/Login/hook";
import { FrontendInstallContext } from "install/types";
import { pickBy } from "lodash";
import { useCallback, useMemo } from "react";
import { SINGLETON_ITEM } from "shared/install/constants";
import { ConfigOf, InstallSpec } from "shared/install/types";

import { configPath } from "./Config";
import { ConfigForm } from "./ConfigForm";
import { InstallProps } from "./Install";

export type RootProps<T extends InstallSpec> = InstallProps<T> & {
  authFetch: AuthFetch;
  context: FrontendInstallContext<ConfigOf<T>>;
  disabled?: boolean;
  isFetching: boolean;
};

export const Root = <T extends InstallSpec>(props: RootProps<T>) => {
  const { authFetch, context, components, isFetching } = props;

  const component = components.root;

  const submit = useCallback(
    async (config: ConfigOf<T>["root"]) => {
      await authFetch(
        configPath({
          integration: props.integration,
          componentKey: undefined,
          id: undefined,
        }),
        {
          method: "POST",
          json: { root: { [SINGLETON_ITEM]: config } },
        }
      );
    },
    [authFetch, props.integration]
  );

  const schema = useMemo(
    () => pickBy(component.schema, (s: any) => s.type !== "generated"),
    [component.schema]
  );

  return (
    <div>
      {!props.disabled && (
        <Typography.Paragraph>
          To begin, configure your integration, then click &quot;Next&quot;:
        </Typography.Paragraph>
      )}
      <ConfigForm
        config={props.context.config?.root?.[SINGLETON_ITEM] ?? {}}
        context={context}
        disabled={props.disabled}
        id={SINGLETON_ITEM}
        installer={props.installer.root as any}
        isFetching={isFetching}
        schema={schema}
        setConfig={submit}
      />
    </div>
  );
};
