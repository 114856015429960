import { WarningTwoTone } from "@ant-design/icons";
import { Alert, Spin, Tabs } from "antd";
import yaml from "js-yaml";
import stringify from "json-stable-stringify";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";
import { RoutingRule } from "shared/types/workflow/types";
import styled from "styled-components";

import { useGuardedEffect } from "../../hooks/useGuardedEffect";
import { useRoutingRulesStore } from "../../store/routingRulesStore";
import { Heading } from "../Heading";
import ContactSupport from "../Integrations/components/ContactSupport";
import { useAuthFetch, useHasRole } from "../Login/hook";
import { useSubmitWorkflow } from "./useSubmitWorkflow";
import { DEFAULT_DOCUMENT } from "./utils";
import { RulesView } from "./views/RulesView";
import TableView from "./views/TableView";

const StyledRoutingRulesContainer = styled.div`
  .capitalize {
    text-transform: capitalize;
  }
`;

export const RoutingEditAlert: React.FC = () => (
  <Alert
    type="warning"
    message="Changes to routing rules do not revoke existing access. They only apply to future requests."
    style={{ marginBottom: "20px" }}
    icon={<WarningTwoTone twoToneColor="#faad14" />}
    showIcon
  />
);

const RoutingRulesContainer: React.FC = () => {
  const flags = useFlags();
  const {
    setJsonRules,
    setIsLoading,
    setErrors,
    jsonRules,
    isLoading,
    setYamlRules,
  } = useRoutingRulesStore();

  const setSingleError = useCallback(
    (error: string | undefined) => setErrors(error ? [error] : []),
    [setErrors]
  );

  const authFetch = useAuthFetch(setSingleError);
  const submitWorkflow = useSubmitWorkflow();

  const canSubmit = useHasRole("owner");

  useGuardedEffect(
    async (cancellation) => {
      try {
        setIsLoading(true);
        const response = await authFetch("workflow/latest", {
          method: "GET",
          onNotOk: (response) => {
            if (response.status === 404) {
              cancellation.guard(setYamlRules)(DEFAULT_DOCUMENT);
              return;
            }
          },
        });
        if (!response) return;
        const {
          workflow: { rules },
        } = await response.json();
        cancellation.guard(setJsonRules)(rules);
        cancellation.guard(setYamlRules)(yaml.dump(rules, { flowLevel: 4 }));
      } finally {
        cancellation.guard(setIsLoading)(false);
      }
    },
    setSingleError,
    [authFetch]
  );

  const handleSubmitWorkflow = useCallback(
    async (value: string) => {
      if (!flags.requestRouting) {
        return;
      }

      try {
        await submitWorkflow({
          jsonData: { workflow: { rules: yaml.load(value) as RoutingRule[] } },
          shouldNavigate: true,
        });
      } catch (err) {
        setErrors([String(err)]);
      }
    },
    [flags.requestRouting, setErrors, submitWorkflow]
  );

  const handleDelete = useCallback(
    async (record: RoutingRule) => {
      const updatedRules = jsonRules.filter(
        (rule) => stringify(rule) !== stringify(record)
      );
      await submitWorkflow({
        jsonData: { workflow: { rules: updatedRules } },
        shouldNavigate: false,
      });
    },
    [jsonRules, submitWorkflow]
  );

  const tabItems = [
    {
      key: "1",
      label: "Table View",
      children: <TableView canSubmit={canSubmit} handleDelete={handleDelete} />,
    },
    {
      key: "2",
      label: "YAML View",
      children: (
        <RulesView
          canSubmit={canSubmit}
          handleSubmitWorkflow={handleSubmitWorkflow}
        />
      ),
    },
  ];

  return (
    <StyledRoutingRulesContainer>
      <Heading title="Request Routing" />

      {isLoading ? (
        <Spin />
      ) : (
        <>
          {!flags.requestRouting && (
            <Alert
              message={
                <>
                  Custom routing is not available in your current plan. Please
                  <ContactSupport /> to enable. You can still configure basic
                  routing using the settings page.
                </>
              }
              type="warning"
              icon={<WarningTwoTone twoToneColor="#faad14" />}
              showIcon
            />
          )}
          <Tabs defaultActiveKey="1" items={tabItems} />
        </>
      )}
    </StyledRoutingRulesContainer>
  );
};

export default RoutingRulesContainer;
