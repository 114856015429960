import { camelCase, capitalize, compact } from "lodash";

import { DslMapping, parse } from "../../graph/dsl";
import { NodePredicate } from "../../graph/search";
import { Node, isNode } from "../../graph/types";
import {
  MemberKey,
  ResourcePrefix,
} from "../../integrations/resources/gcloud/asset";
import { DAYS } from "../../time";
import { ItemAssessmentScope } from "../../types/assessment";
import {
  AssessmentNodes,
  Binding,
  BindingNode,
} from "../../types/assessment/data";
import { CredentialNode } from "../../types/assessment/data/credential";
import {
  FixOptions,
  Monitor,
  SavedMonitor,
} from "../../types/assessment/monitor";

export const STALE_CREDENTIAL_MILLIS = 90 * DAYS;

/** Converts special node types to values */
export const AssessmentMap: DslMapping<AssessmentNodes> = {
  permissionType: (node: Node<AssessmentNodes, "permissionType">) => ({
    keys: [node.data.permissionType],
  }),
  principal: (node: Node<AssessmentNodes, "principal">) => ({
    attributes: {
      type: [
        node.data.principalType,
        ...(node.data.principalType === "service-account" ? ["role"] : []),
        ...(node.data.isProviderManaged ? ["service-agent"] : []),
      ],
      status: [node.data.disabled ? "disabled" : "active"],
    },
  }),
  risk: (node: Node<AssessmentNodes, "risk">) => ({
    keys: compact([node.key, node.data.score]),
  }),
  binding: (node: Node<AssessmentNodes, "binding">) => ({
    keys: [],
    attributes: {
      status: [node.data.disabled ? "disabled" : "enabled"],
    },
  }),
  authentication: (node: Node<AssessmentNodes, "authentication">) => ({
    keys: [node.data.type],
    attributes: {
      last40: [
        node.data.lastAuthnTime > Date.now() - 40 * DAYS ? "used" : "unused",
      ],
      last90: [
        node.data.lastAuthnTime > Date.now() - 90 * DAYS ? "used" : "unused",
      ],
    },
  }),
  credential: (node: Node<AssessmentNodes, "credential">) => ({
    keys: [node.data.type],
    attributes: {
      enabledKey: [
        node.data.type === "key" && node.data.status === "enabled"
          ? "true"
          : "false",
      ],
      stale90: [
        node.data.createdTime &&
        node.data.createdTime < Date.now() - STALE_CREDENTIAL_MILLIS
          ? "true"
          : "false",
      ],
    },
  }),
};

const AssessmentAliases = {
  /** An unused permission is a permission reachable via an unused permissionType node */
  "unused:": 'permissionType:"unused"->permission:',
  /** A not-unused (viz, used or unknown) permission is a permission reachable via
   *  a used or unknown permissionType node
   */
  "!unused:": 'permissionType:!"unused"->permission:',
  "used:": 'permissionType:"used"->permission:',
  "!used:": 'permissionType:!"used"->permission:',
  "unknown:": 'permissionType:"unknown"->permission:',
  "!unknown:": 'permissionType:!"unknown"->permission:',
  "grant:": "binding:",
  "grant=": "binding=",
  "role:": "grant:permissionSet:",
  "role=": "grant=permissionSet:",
  "login:": "authentication:last90:",
};

export const principalPredicate: NodePredicate<AssessmentNodes> = (n) =>
  isNode("principal")(n) && n.data.principalType !== "unknown";

export const bindingPredicate: NodePredicate<AssessmentNodes> =
  isNode("binding");

export const resourcePredicate: NodePredicate<AssessmentNodes> =
  isNode("resource");

export const assessmentParse = parse(AssessmentMap, AssessmentAliases);

/** Extracts project and principal identifier for project grants */
const projectGrantFixData = (data: BindingNode["data"]) => {
  const { resource, principalType } = data;
  if (!resource.startsWith(ResourcePrefix.project)) return undefined;
  const project = resource.split("/").at(4);
  if (!project) return undefined;
  const memberKey = MemberKey[principalType];
  if (!memberKey) return undefined;
  return { project, memberKey };
};

// see https://cloud.google.com/sdk/gcloud/reference/projects/add-iam-policy-binding
const argFromCondition = (condition: BindingNode["data"]["condition"]) =>
  condition
    ? `^:^title=${condition.title}:expression=${condition.expression}${
        condition.description ? `:description=${condition.description}` : ""
      }`
    : "None";

const unusedGrantFixGenerator = {
  gcp: {
    description: "To fix, run these commands in Google Cloud Shell:",
    type: "shell" as const,
    generate: ({ data }: BindingNode) => {
      const { condition, principal, permissionSet } = data;
      const projectData = projectGrantFixData(data);
      if (!projectData) return undefined;
      const { project, memberKey } = projectData;
      const conditionArg = argFromCondition(condition);
      return `gcloud projects remove-iam-policy-binding ${project} \\
  --member=${memberKey}:${principal} \\
  --role=${permissionSet} \\
  --condition=${conditionArg}`;
    },
  },
};

// WARNING: This is very hacky
const partialGrantFixGenerator = {
  gcp: {
    description:
      "Remove excess unused privileges by running these commands in Google Cloud Shell:",
    type: "shell" as const,
    generate: ({ aggregates, data }: BindingNode, { scope }: FixOptions) => {
      const { condition, principal, permissionSet } = data;
      const projectData = projectGrantFixData(data);
      if (!projectData) return undefined;
      const { project, memberKey } = projectData;
      const { unknown, used } = aggregates.permissions;
      const conditionArg = argFromCondition(condition);
      const perms = [...unknown, ...used].map((p) => p.key);
      // resourcemanager permissions can't be granted on a project
      // really we should use queryGrantableRoles
      const validPerms = perms.filter((p) => !p.startsWith("resourcemanager"));
      const shortPrincipal = capitalize(camelCase(principal.split("@")[0]));
      const oldRole = permissionSet.split("/").at(-1) ?? "";
      const shortOldRole = capitalize(camelCase(oldRole));
      // We have 32 characters total (64 bytes)
      const roleId = `Lp4${capitalize(
        shortPrincipal.slice(0, 16)
      )}${shortOldRole.slice(0, 12)}`;
      return `gcloud iam roles create ${roleId} \\
  --project=${scope.id} \\
  --stage="GA" \\
  --title="Least Privilege ${data.principal} ${oldRole}" \\
  --description="Least-privilege role for ${
    data.principal
  }. It replaces the previous over-privileged grant to ${
        data.permissionSet
      }, and was determined via a P0 IAM assessment." \\
  --permissions=${validPerms.join(",")}
gcloud projects add-iam-policy-binding ${project} \\
  --member=${memberKey}:${principal} \\
  --role=projects/${project}/roles/${roleId} \\
  --condition='${conditionArg}'
gcloud projects remove-iam-policy-binding ${project} \\
  --member=${memberKey}:${principal} \\
  --role=${permissionSet} \\
  --condition='${conditionArg}'`;
    },
  },
};

const gcloudPublicAccessFix = (data: Binding) => {
  // IAM conditions are not allowed on public principals - we don't have to add the --condition flag to the gcloud command
  const { resource } = data;
  const [, , type, name] = resource.split("/");
  switch (type) {
    // https://cloud.google.com/sdk/gcloud/reference/storage/buckets/add-iam-policy-binding
    case "storage.googleapis.com":
      return {
        command: "storage buckets",
        resource: `gs://${name}`,
      };
    default:
      return {
        error: `P0 does not support public access remediation of ${type} resources.`,
      };
  }
};

const publicAccessFixGenerator = (status: "disabled" | "enabled") => ({
  gcp: {
    description: `${
      status === "disabled" ? "Re-enable" : "Disable"
    } public access by running these commands in Google Cloud Shell:`,
    type: "shell" as const,
    generate: (
      { data }: BindingNode,
      { scope }: { scope: ItemAssessmentScope }
    ) => {
      const { command, resource, error } = gcloudPublicAccessFix(data);
      if (error) return `# ${error}`;
      return `gcloud ${command} ${
        status === "disabled" ? "add" : "remove"
      }-iam-policy-binding '${resource}'\\
  --member='allUsers' \\
  --role='${data.permissionSet}' \\
  --project=${scope.id}`;
    },
  },
});

const credentialFixGenerator = (status: "disabled" | "enabled") => ({
  gcp: {
    description: `${
      status === "disabled" ? "Re-enable" : "Disable"
    } these service-account keys by running these commands in Google Cloud Shell:`,
    type: "shell" as const,
    generate: (
      node: CredentialNode,
      { scope }: { scope: ItemAssessmentScope }
    ) => {
      // Note that credential key here is full key resource locator, not just key id
      const [account, _, key] = node.key.split("/").slice(6);
      return `gcloud iam service-accounts keys ${
        status === "disabled" ? "enable" : "disable"
      } \\
  '${key}' \\
  --iam-account='${account}' \\
  --project=${scope.id}`;
    },
  },
});

const accountFixGenerator = (status: "disabled" | "enabled") => ({
  gcp: {
    description: `${
      status === "disabled" ? "Re-enable" : "Disable"
    } these service accounts by running these commands in Google Cloud Shell.`,
    type: "shell" as const,
    generate: (node: Node<AssessmentNodes, "principal">) =>
      `gcloud iam service-accounts ${
        status === "disabled" ? "enable" : "disable"
      } '${node.key}'`,
  },
  aws: {
    description: `${
      status === "disabled" ? "Re-enable" : "Disable"
    } these IAM roles by running these commands in AWS Cloud Shell.`,
    type: "shell" as const,
    generate: (node: Node<AssessmentNodes, "principal">) =>
      `aws iam ${
        status === "disabled" ? "detach-role-policy" : "attach-role-policy"
      } --role-name ${
        node.data.label
      } --policy-arn arn:aws:iam::aws:policy/AWSDenyAll`,
  },
});

export const PresetMonitors = Object.freeze({
  "Unused Grants": Monitor({
    show: "binding",
    // nodes that cannot reach a used or unknown permission
    search: assessmentParse(
      'unused: ^!unused: principal:type:!"service-agent" principal:status:"active"'
    ),
    label: "Unused grant",
    priority: "HIGH",
    cta: "No privileges given by this grant have been used in the last 90 days. Consider removing this grant.",
    remediation: (action: string) =>
      `No privileges given by this grant have been used in the last 90 days. P0 has automatically ${action} to remove this grant.`,
    description:
      "Grants for which no privileges have been used in the last 90 days.",
    vcsRemediateSupported: true,
    fix: unusedGrantFixGenerator,
  }),
  "Unused User Accounts": Monitor({
    show: "principal",
    search: assessmentParse(
      'principal=type:"user"->authentication:last90:"unused" ^authentication:last90:"used"'
    ),
    label: "Unused user account",
    priority: "HIGH",
    cta: "This user has not logged in within the last 90 days. Consider removing this user from your directory.",
    remediation: (action: string) =>
      `This user has not logged in within the last 90 days. P0 has automatically ${action} to remove this user.`,
    description: "Users that have not logged in within the last 90 days.",
  }),
  "Unused Service Accounts": Monitor({
    scopes: ["gcloud"],
    show: "principal",
    search: assessmentParse(
      'principal=type:"service-account"->authentication:last40:"unused" ^authentication:last40:"used" principal=status:"active"'
    ),
    label: "Unused service account",
    priority: "HIGH",
    cta: "This service account has not performed any actions within the last 40 days. Consider disabling this service account.",
    remediation: (action: string) =>
      `This service account has not performed any actions within the last 40 days. P0 has automatically ${action} to disable this service account.`,
    description: "Service accounts that have been inactive for 40 days.",
    fix: accountFixGenerator("enabled"),
    revert: accountFixGenerator("disabled"),
  }),
  "Unused Service-Account Keys": Monitor<AssessmentNodes, "credential">({
    scopes: ["gcloud"],
    show: "credential",
    search: assessmentParse(
      'credential:enabledKey:"true"->authentication:last40:"unused"->principal=type:"service-account" principal=status:"active"'
    ),
    label: "Unused service-account key",
    priority: "HIGH",
    cta: "This service-account key has have not been used in the last 40 days. Consider removing this key.",
    remediation: (action: string) =>
      `This service-account key has not been used in the last 40 days. P0 has automatically ${action} to disable this key.`,
    description:
      "Service-account keys that have not been used in the last 40 days.",
    fix: credentialFixGenerator("enabled"),
    revert: credentialFixGenerator("disabled"),
  }),
  "Unused Roles": Monitor({
    scopes: ["aws"],
    show: "principal",
    search: assessmentParse(
      'principal=type:"role" ^authentication:lastAuthnTime: principal=status:"active"'
    ),
    label: "Unused IAM roles",
    priority: "HIGH",
    cta: "This role has not been accessed in the last 400 days. Consider removing this role.",
    remediation: (action: string) =>
      `This role has not been accessed in the last 400 days. P0 has automatically ${action} to remove this role.`,
    description: "Roles that have not been used in the last 400 days.",
    fix: accountFixGenerator("enabled"),
    revert: accountFixGenerator("disabled"),
  }),
  "Stale Service-Account Key": Monitor({
    scopes: ["gcloud"],
    show: "credential",
    search: assessmentParse(
      'credential:stale90:"true"->principal=type:"service-account"'
    ),
    label: "Stale service-account key",
    priority: "HIGH",
    cta: "This service-account key has not been rotated in the last 90 days. Google recommends rotating keys every 90 days.",
    description:
      "Service-account keys that have not been rotated in the last 90 days.",
    management: {
      description:
        "P0 will automatically rotate your service account keys every 90 days",
      inputPrompt:
        "Please enter the Secret Manager resource name for the secret containing keys for this service account",
    },
  }),
  "Disabled MFA": Monitor({
    show: "principal",
    search: assessmentParse('principal=mfa:"disabled"'),
    label: "Disabled multi-factor authentication",
    priority: "HIGH",
    cta: "This user does not have multi-factor authentication enabled. Consider enforcing MFA for this user.",
    description: "Users with disabled multi-factor authentication.",
  }),
  "Excess Privileges": Monitor({
    show: "binding",
    // nodes that can reach an unused permission AND can reach a used or unknown permission
    search: assessmentParse('unused: !unused: principal:type:!"service-agent"'),
    label: "Excess privileges",
    priority: "MEDIUM",
    cta: "Some of this grant's privileges have not been used in the last 90 days. Consider replacing this grant with a least-privileged role.",
    description:
      "Grants with some used and some unused privileges over the last 90 days.",
    fix: partialGrantFixGenerator,
    management: {
      description:
        "P0 will automatically resize your grants to be least-privileged based on current usage every 90 days.",
    },
  }),
  "Privileged Access": Monitor({
    show: "binding",
    search: assessmentParse('risk:"CRITICAL" principal:type:!"service-agent"'),
    label: "Privileged access",
    priority: "MEDIUM",
    cta: "This grant allows access with critical risks. Consider replacing this grant with a low-sensitivity grant, and using just-in-time access for ephemeral access.",
    description: "Grants with any privileges that allow sensitive access.",
  }),
  "Unused Privileged Access": Monitor({
    show: "binding",
    search: assessmentParse(
      'unused:->risk:"CRITICAL" principal:type:!"service-agent"'
    ),
    label: "Unused privileged access",
    priority: "CRITICAL",
    cta: "This grant conveys privileges that allow critical risks, and these privileges have not been used in the last 90 days. Consider replacing this grant with a role that does not include these privileges.",
    description: "Grants with unused privileges that allow sensitive access.",
    fix: partialGrantFixGenerator,
    management: {
      description:
        "P0 will automatically remove unused risky privileges every 90 days.",
    },
  }),
  "Public Access": Monitor({
    show: "binding",
    search: assessmentParse(
      'resource: principal:type:"public" principal:type:!"service-agent"'
    ),
    label: "Public access",
    priority: "CRITICAL",
    cta: "This grant allows everyone on the internet access. Validate that this is intentional, and remove this grant if it is not.",
    description: "Grants that allow resource access to anyone on the Internet.",
    fix: publicAccessFixGenerator("enabled"),
    revert: publicAccessFixGenerator("disabled"),
  }),
});

export const convertSavedMonitor = (savedMonitor: SavedMonitor): Monitor => {
  const { searchTerm, show, ...rest } = savedMonitor;

  return {
    cta: savedMonitor.description ?? "",
    search: assessmentParse(searchTerm),
    ...rest,
    show,
  };
};
