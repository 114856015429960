import {
  CaretRightFilled,
  CloseCircleTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import { Button, Space, Steps, Typography } from "antd";
import { ErrorDisplay } from "components/Error";
import { TagInput } from "components/TagInput";
import { useZeroState } from "hooks/useZeroState";
import pluralize from "pluralize";
import { ReactElement, ReactNode, useMemo } from "react";

import { join } from "../../../utils/join";
import { ZeroStateProps } from "./Component";
import { ConfigStep } from "./ConfigSteps";

const DelimitedInstalls: React.FC<{ identifiers: ReactNode[] }> = ({
  identifiers: items,
}) => (
  <>
    {join(
      items.map((p, ix) => (
        <Typography.Text code key={`item=${ix}`}>
          {p}
        </Typography.Text>
      )),
      (ix) => (
        <span key={ix}>{", "}</span>
      )
    )}
  </>
);

/** Shown after successful installation */
const InstallBanner = <C extends object>({
  label,
  items,
  render,
}: {
  label: string;
  items: C[];
  render?: (item: C) => ReactNode;
}) => {
  return (
    <div>
      Installed on {pluralize(label, items.length)}:{" "}
      <DelimitedInstalls identifiers={items.map(render ?? String)} />
    </div>
  );
};

export const InstallStateHeader = <C extends object>({
  label,
  installed,
  render,
  state,
  step,
  steps,
}: {
  label: string;
  installed: C[];
  inProgressIds: string[];
  render?: (item: C) => ReactNode;
  state?: string;
  step: number;
  steps: Pick<ConfigStep, "label">[];
  error?: string;
}): ReactElement => {
  const stepItems = useMemo(
    () => steps.map((s) => ({ title: s.label })),
    [steps]
  );
  return (
    <Space direction="vertical" style={{ width: "100%" }} size={20}>
      {installed.length > 0 && (
        <InstallBanner label={label} items={installed} render={render} />
      )}
      {state !== "installed" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div style={{ whiteSpace: "nowrap" }}>
            Installing {pluralize(label)}
          </div>
          <Steps current={step} items={stepItems} size="small" />
        </div>
      )}
    </Space>
  );
};

export const InstallMore: React.FC<{ label: string; onClick: () => void }> = ({
  label,
  onClick,
}) => (
  <Button icon={<EditTwoTone />} type="link" onClick={onClick}>
    Edit {pluralize(label)}
  </Button>
);

export const ZeroStatePlaceHolder: React.FC<
  React.PropsWithChildren<{
    banner: string | undefined;
    installed: string[];
    cancelInstall: () => void;
    label: string;
    isAdd: string | undefined;
    isSubtract: string | undefined;
    onSubmit: () => void;
  }>
> = ({
  banner,
  installed,
  cancelInstall,
  label,
  isAdd,
  isSubtract,
  onSubmit,
  children,
}) => {
  return (
    <>
      {banner && <ErrorDisplay error={banner} />}
      {children}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {installed.length ? (
          <Button icon={<CloseCircleTwoTone />} onClick={cancelInstall}>
            Cancel {label} edit
          </Button>
        ) : (
          <div />
        )}
        <Button
          icon={<CaretRightFilled />}
          type={"primary"}
          disabled={!isAdd && !isSubtract}
          onClick={onSubmit}
        >
          {isSubtract && !isAdd
            ? `Uninstall ${pluralize(label)}`
            : "Get setup commands"}
        </Button>
      </div>
    </>
  );
};

export type ItemPickerProps = {
  label: string;
  validator: RegExp;
};

/** Allows a user to choose component items to install */
export const InstallItemPicker: React.FC<ZeroStateProps<ItemPickerProps>> = ({
  cancelInstall,
  installed,
  label,
  selected,
  startInstall,
  startRemove,
  validator,
}) => {
  const { ids, banner, isAdd, isSubtract, setIds, onSubmit } = useZeroState(
    installed,
    selected,
    startInstall,
    startRemove
  );

  return (
    <ZeroStatePlaceHolder
      {...{
        banner,
        installed,
        cancelInstall,
        label,
        isAdd,
        isSubtract,
        onSubmit,
      }}
    >
      <p>Select the {pluralize(label)} you&apos;d like to use with P0:</p>
      <div style={{ marginBottom: 8 }}>
        <TagInput
          tags={ids}
          tagPattern={validator}
          onUpdate={setIds}
          key={"integration-id-input"}
          placeholder={`Enter ${label} ID and press 'Enter'...`}
        />
      </div>
    </ZeroStatePlaceHolder>
  );
};
