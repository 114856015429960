import { Alert, Card, Descriptions, Spin, Timeline, Typography } from "antd";
import { formatDistance } from "date-fns";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import { RequestStatus } from "shared/types/request-status";
import styled from "styled-components";

import {
  useFirestoreCollection,
  useFirestoreDoc,
} from "../../../providers/FirestoreProvider";
import { PermissionRequest } from "../../../shared/types/permission";
import { PermissionIntegrationLogo } from "../../Integrations/IntegrationLogo";
import SimpleTableView from "../../Routing/views/SimpleTableView";
import { FormattedDateTime } from "../../common/FormattedDateTime";
import { TenantAwareLink } from "../../common/TenantAwareLink";
import {
  COLOR_MAP,
  extractApproverName,
  getRequestEvents,
  progressMessages,
  requestDescription,
  statusDescriptions,
} from "../requestUtils";
import { RequestNotFound } from "./RequestNotFound";
import { RequestStatusTag } from "./RequestStatusTag";
import { conversationLink } from "./Requests";

const LowerCard = styled(Card)`
  margin-top: 16px;
`;

const Span = styled("span")`
  margin-left: 8px;
`;

const notificationColor = "lightgrey";
const TIMELINE_COLOR_MAP: Record<RequestStatus, string> = {
  APPROVED_NOTIFIED: notificationColor,
  APPROVED: COLOR_MAP.Approved,
  CLEANED_UP: COLOR_MAP.Expired,
  CLEANUP_SUBMITTED: COLOR_MAP.Expiring,
  CLEANUP_ERRORED: COLOR_MAP.Errored,
  DENIED_NOTIFIED: notificationColor,
  DENIED: COLOR_MAP.Denied,
  DONE_NOTIFIED: notificationColor,
  DONE: COLOR_MAP.Granted,
  ERRORED_ERRORED: COLOR_MAP.Errored,
  ERRORED_NOTIFIED: notificationColor,
  ERRORED: COLOR_MAP.Errored,
  EXPIRED_NOTIFIED: notificationColor,
  EXPIRED: COLOR_MAP.Expired,
  EXPIRY_SUBMITTED: COLOR_MAP.Expiring,
  NEW: COLOR_MAP["Request Created"],
  PENDING_APPROVAL: COLOR_MAP["Pending Approval"],
  PENDING_APPROVAL_ESCALATED: COLOR_MAP["Pending Approval"],
  REVOKE_SUBMITTED: COLOR_MAP.Relinquishing,
  REVOKED_NOTIFIED: notificationColor,
  REVOKED: COLOR_MAP.Relinquished,
  STAGED: COLOR_MAP.Staged,
  TRANSLATED: COLOR_MAP["N/A"], // unused
};

const RequestDescriptionDiv = (props: {
  request: PermissionRequest;
  status?: RequestStatus; // optionally, render a description for a past status
}) => {
  const displayStatus = props.status ?? props.request.status;
  return (
    <div>
      {displayStatus === "PENDING_APPROVAL" ? (
        <>
          Sent request for approval to{" "}
          {props.request.notifications?.slack?.approvalConversationUrl ? (
            <a
              href={props.request.notifications?.slack?.approvalConversationUrl}
            >
              approvers
            </a>
          ) : (
            "approvers"
          )}
        </>
      ) : (
        statusDescriptions[displayStatus](props.request)
      )}
    </div>
  );
};

const formatPrincipalType = {
  group: "Group",
  user: "User",
  "service-account": "Service Account",
} as const;

export const RequestLogs: React.FC<{ requestId: string; tenantId: string }> = ({
  tenantId,
  requestId,
}) => {
  const { orgSlug } = useParams();
  const partialDocs = useFirestoreCollection<PermissionRequest>(
    `o/${tenantId}/permission-requests/${requestId}/logs`,
    { live: true }
  );
  const { doc, loading } = useFirestoreDoc<PermissionRequest>(
    `o/${tenantId}/permission-requests/${requestId}`,
    { live: true }
  );
  const request = useMemo(() => doc?.data, [doc]);

  const routingRulesSnapshot = request?.routingRulesSnapshot ?? [];

  const { partialStatusDocs, completed } = useMemo(() => {
    if (!partialDocs) return { partialStatusDocs: [], isFinished: false };
    const { events, isComplete } = getRequestEvents(
      doc?.data,
      partialDocs ?? []
    );
    return { partialStatusDocs: events, completed: isComplete };
  }, [partialDocs, doc]);

  return (
    <>
      {request ? (
        <>
          <Card
            title={
              <>
                {PermissionIntegrationLogo[request.type]}
                <Span>Request Info</Span>
              </>
            }
          >
            <Descriptions layout="vertical" bordered>
              <Descriptions.Item label="Description">
                {requestDescription(request)}
                {request.notifications?.slack?.approvalConversationUrl &&
                  conversationLink(
                    request.notifications.slack.approvalConversationUrl
                  )}
              </Descriptions.Item>
              <Descriptions.Item label="Principal Type">
                {"principal-type" in request.permission
                  ? formatPrincipalType[request.permission["principal-type"]]
                  : "User"}
              </Descriptions.Item>
              <Descriptions.Item label="Principal">
                {request.principal}
              </Descriptions.Item>
              <Descriptions.Item label="Requestor">
                {request.requestor}
              </Descriptions.Item>
              <Descriptions.Item label="Approver">
                {extractApproverName(request)}
              </Descriptions.Item>
              <Descriptions.Item label="Requested At">
                <FormattedDateTime timestamp={request.requestedTimestamp} />
              </Descriptions.Item>
              <Descriptions.Item label="Granted At">
                {request.grantTimestamp ? (
                  <FormattedDateTime timestamp={request.grantTimestamp} />
                ) : (
                  `Not Available`
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Access Duration">
                {request.grantTimestamp && request.revokedTimestamp
                  ? formatDistance(
                      request.revokedTimestamp,
                      request.grantTimestamp
                    )
                  : `Not Available`}
              </Descriptions.Item>
              <Descriptions.Item label="Most Recent Action">
                <RequestDescriptionDiv request={request} />
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <RequestStatusTag status={request.status} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <LowerCard title="History">
            <Timeline
              pending={!completed ? progressMessages[request.status] : null}
              reverse={true}
            >
              {partialStatusDocs &&
                partialStatusDocs.map((partialRequestDoc) => {
                  return (
                    <Timeline.Item
                      color={
                        TIMELINE_COLOR_MAP[partialRequestDoc.initialStatus]
                      }
                      key={partialRequestDoc.initialStatus}
                    >
                      <FormattedDateTime
                        timestamp={
                          partialRequestDoc.updates?.lastUpdatedTimestamp ?? 0
                        }
                      />
                      <RequestDescriptionDiv
                        request={request}
                        status={partialRequestDoc.initialStatus}
                      />
                    </Timeline.Item>
                  );
                })}
            </Timeline>
          </LowerCard>
          <LowerCard title="Routing rules snapshot">
            <Typography.Paragraph>
              This is a snapshot of the routing rules that existed at the time
              the request was created <em>and</em> which applied to the
              requestor or the resource. The routing rules configuration may
              have changed since this request was created; the current
              configuration can be found at{" "}
              <TenantAwareLink to="routing">Routing</TenantAwareLink>.
            </Typography.Paragraph>
            {routingRulesSnapshot.length === 0 ? (
              <Alert
                message="Routing rules snapshot not available"
                description="This request is old and did not capture a routing rules snapshot."
                type="warning"
                showIcon
              />
            ) : (
              <SimpleTableView jsonRules={routingRulesSnapshot} />
            )}
          </LowerCard>
        </>
      ) : loading ? (
        <Spin />
      ) : (
        <RequestNotFound orgSlug={orgSlug} />
      )}
    </>
  );
};
